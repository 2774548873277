import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import moment from 'moment';

import { isLogged,
            getUserByEmail } from '../../actions/admin-users.js';
import { getProposals,
            editProposal,
            getCoachees,
            proposalToRate,
            saveProposalRate } from '../../actions/app.js';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import { Modal, Button, Space, Switch } from 'antd';
import { EditOutlined, 
            UploadOutlined,
            EyeOutlined,
            CheckOutlined,
            CloseOutlined,
            DeleteOutlined,
            ExclamationCircleOutlined } from '@ant-design/icons';

import { Rate } from 'antd';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class CoacheeEvaluation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            coachee_id : null
        };
    }

    componentDidMount() {
        const { t, i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 2){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        let proposal_id = typeof this.props.match.params.proposal_id != 'undefined' ? this.props.match.params.proposal_id : null;

        Promise.resolve(this.props.proposalToRate(proposal_id, this.props.user._id))
        .then((response)=> {
            if(this.props.coachToRate){
             

                this.setState({
                    isLoaded : true
                });
            }
        });
    }

    _saveEvaluation(value) {
        const { t } = this.props;

        let proposal_id = typeof this.props.match.params.proposal_id != 'undefined' ? this.props.match.params.proposal_id : null;
        let coachee_id = this.props.user._id;
        
        Promise.resolve(this.props.saveProposalRate(proposal_id, this.props.user._id, value))
        .then((response)=> {
            if(this.props.dataError == false){
                Modal.info({
                    title: t('Evaluación guardada'),
                    content: t('Muchas gracias, hemos guardado tu evaluación')
                });

                this.setState({
                    isLoaded : true
                });
            }else{
                Modal.error({
                    title: t('Ha ocurrido un error'),
                    content: 'Error: ' + this.props.dataErrorMessage.toString()
                });
            }
        });
    }

    _renderList() {
        const { t } = this.props;
        
        let coach = this.props.coachToRate;
        let proposal_id = typeof this.props.match.params.proposal_id != 'undefined' ? this.props.match.params.proposal_id : null;

        return (
            <React.Fragment>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={"col-md-4 offset-md-4 coachee__box-container"}>
                            <div className={"coachee__box"}>

                                <div className={"coachee__box-content"}>
                                    <div className={"coachee__image"}>
                                        <img
                                        src={coach.picture}
                                        alt={`coachee - ${coach.first_name}`}/>
                                    </div>
                                    
                                    <p>{coach.first_name}</p>

                                    <div className={'text-center'}>
                                        <Rate onChange={this._saveEvaluation.bind(this)} allowHalf defaultValue={2.5} />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <p className={'text-center mt-4'}>
                            {t('Gracias por ayudarnos a servirte mejor!')}
                        </p>
                    </div>
                </div>
                                
            </React.Fragment>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return(
            <div className={"split-container"}>
                <Menu history={this.props.history} />
                <div className={"container coachee"}>
                    <div className={"row"}>
                        <div className={"col-12 pt-3"}>
                            <header className={"managementPanel__content-header"}>
                                <div>
                                    <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                    <p className={"managementPanel__title"}>{t('Evaluar a tu Coach')}</p>
                                </div>
                            </header>

                            <section>
                                <p className={'text-center'}>
                                    {t('¿Cuán útil o efectiva ha sido esta sesión en el  desarrollo de mi programa de coaching?')}
                                </p>

                                <p className={'text-center'}>
                                    {t('Selecciona tu puntuación en la escala creciente de 1 a 5 estrellas')}.
                                </p>

                                { 
                                    this.state.isLoaded ? 
                                        this._renderList()
                                    :
                                        this._renderLoader()

                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coachToRate: storeApp.coachToRate,
        dataError: storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getCoachees: () => dispatch(getCoachees()),
        editProposal: (proposal_id) => dispatch(editProposal(proposal_id)),
        getUserByEmail: (email) => dispatch(getUserByEmail(email)),
        proposalToRate: (proposal_id, coachee_id) => dispatch(proposalToRate(proposal_id, coachee_id)),
        saveProposalRate: (proposal_id, coachee_id, value) => dispatch(saveProposalRate(proposal_id, coachee_id, value))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoacheeEvaluation));
