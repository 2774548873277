import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { inputFieldChanged,
            doLogin,
            doRecover,
            isLogged } from '../actions/admin-users.js';

import InputText from '../components/input';
import TestimonialsMMP from '../components/testimonials-mmp';

import { withTranslation } from 'react-i18next';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true,
            showForm : true,
            showRecover: false,
            showUpdate: false
        };
    }

    componentDidMount() {
        const { t, i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser === true){
                this.props.history.push('/')
            }
        });

    }

    _showForm() {
        this.setState({ 'showForm' : true, 'showRecover' : false })
    }

    _showRecover() {
        this.setState({ 'showForm' : false, 'showRecover' : true })
    }

    _saveData(){
        Promise.resolve(this.props.doLogin(this.props.email, this.props.password))
        .then((response)=> {
            if(this.props.isLoggedUser === true){
                if(this.props.redirectTo != null && this.props.redirectTo != '/login'){
                    this.props.history.push(this.props.redirectTo);
                }else{
                    this.props.history.push('/');
                }
            }
        });
    }

    _saveDataRecover(){
        this.props.doRecover(this.props.email);
    }

    _renderLoginForm() {
        const { t } = this.props;
        return (
            <React.Fragment>

                <form name="login-form" method="post">
                    <div className={'row'}>
                        <div className={'col-12 text-left mb-2'}>
                            <p className={'bold dark-gray'}>{t('Introduce tus datos')}</p>
                        </div>

                        <div className={'col-12'}>
                            <InputText
                                id={'email'}
                                name={'email'}
                                placeholder={t('Email')}
                                classes={'w-100'}
                                label={t('Email')}
                                value={this.props.email}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.email}  
                                />

                            <InputText
                                id={'password'}
                                name={'password'}
                                type={'password'}
                                placeholder={t('Contraseña')}
                                classes={'w-100'}
                                label={t('Contraseña')}
                                value={this.props.password}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.password} 
                                />
                        </div>

                        { typeof this.props.dataErrorMessage.login !== 'undefined' 
                            && this.props.dataErrorMessage.login !== ''
                            && this.props.dataErrorMessage.login.length ?

                            <div className="col-12 mt-4">
                                <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.login }</p>
                            </div>

                        : null }

                        <div className={'col-12 text-right mt-4'}>
                            <a className={'mt-4'} onClick={ this._showRecover.bind(this) }>
                                <span className={'button-text'}>{t('¿Has olvidado tu contraseña?')}</span>
                            </a>
                        </div>

                        <div className={'col-12'}>
                            <a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
                                <span className={'circle'} aria-hidden="true">
                                    <span className={'icon arrow'}></span>
                                </span>
                                <span className={'button-text'}>{t('Entrar')}</span>
                            </a>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }

    _renderRecoverForm() {
        const { t } = this.props;
        return (
            <React.Fragment>

                <form name="login-form" method="post">
                    <div className={'row'}>
                        <div className={'col-12 text-left mb-2'}>
                            <p className={'bold dark-gray'}>{t('Introduce tu email')}</p>
                        </div>

                        <div className={'col-12'}>
                            <InputText
                                id={'email'}
                                name={'email'}
                                placeholder={t('Email')}
                                classes={'w-100'}
                                label={t('Email')}
                                value={this.props.email}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.email}  
                                />
                        </div>

                        { typeof this.props.dataErrorMessage.recover !== 'undefined' 
                            && this.props.dataErrorMessage.recover !== ''
                            && this.props.dataErrorMessage.recover.length ?

                            <div className="col-12 mt-4">
                                <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.recover }</p>
                            </div>

                        : null }

                        { typeof this.props.successMessage !== 'undefined' 
                            && this.props.successMessage !== '' ?

                            <div className="col-12 mt-4">
                                <p className={'text-success fs-09 mt-1'}>{ this.props.successMessage }</p>
                            </div>

                        : null }

                        <div className={'col-12 text-right mt-4'}>
                            <a className={'mt-4'} onClick={ this._showForm.bind(this) }>
                                <span className={'button-text'}>{t('Identificarte')}</span>
                            </a>
                        </div>

                        <div className={'col-12'}>
                            <a className={'btn-submit mt-4'} onClick={this._saveDataRecover.bind(this)}>
                                <span className={'circle'} aria-hidden="true">
                                    <span className={'icon arrow'}></span>
                                </span>
                                <span className={'button-text'}>{t('Enviar')}</span>
                            </a>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }

    render () {
        const { t } = this.props;
        return (
            <React.Fragment>
            
                <TestimonialsMMP />
            
                <div className={'container'}>
                    <div className={'d-flex flex-column justify-content-center'} style={{height: '100vh'}}>
                        <div className={'row'}>
                            <div className={'col-12 col-md-8 offset-md-4 align-self-center'}>

                                <div className={'row mt-5 mb-5'}>
                                  <div className={'col-12 text-left'}>
                                    <h1>{t('ACCEDE A')} <br /> EXEKUTIVE COACHING</h1>
                                  </div>
                                </div>

                                { 
                                    this.state.showForm ?
                                        this._renderLoginForm()
                                    : null
                                }
                                {   
                                    this.state.showRecover ?
                                        this._renderRecoverForm()
                                    : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user : storeAdminUsers.user,
        email : storeAdminUsers.email,
        password : storeAdminUsers.password,
        dataError : storeAdminUsers.dataError,
        dataErrorMessage: storeAdminUsers.dataErrorMessage,
        redirectTo: storeAdminUsers.redirectTo,
        successMessage : storeAdminUsers.successMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        doLogin: (email, password) => dispatch(doLogin(email, password)),
        doRecover: (email) => dispatch(doRecover(email)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login));