import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import Header from '../components/header';
import InputText from '../components/input';
import TestimonialsMMP from '../components/testimonials-mmp';

import {inputFieldChanged, 
		saveMyData} from '../actions/app.js';

import { withTranslation } from 'react-i18next';

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    _saveData(){
    	const {fullname, email} = this.props;

    	Promise.resolve(this.props.saveMyData(fullname, email))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.props.history.push('/MMP/mi-programa');
	        }
	    });
    }

    render() {
    	const { t, i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang && i18n.language != lang){ 
            i18n.changeLanguage(lang)
        }

        return (
       		<React.Fragment>
			    <TestimonialsMMP changeLanguage={true} />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<div className={'row mt-5 mb-5'}>
			      				<div className={'col-12 text-left'}>
			      					<h1 className={'orange'}>{t('MI MEJOR PROPUESTA')}</h1>
			      				</div>
			      			</div>

			      			<div className={'row'}>
			      				<div className={'col-12 text-left mb-2'}>
			      					<p className={'bold'}>{t('Bienvenido')},<br /> {t('para poder crear tu mejor propuesta necesitamos un par de datos que nos ayuden a ubicarte')}.</p>
			      				</div>

			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>
			      						<h2 className={'dark-gray mb-4'}>{t('Datos personales')}</h2>

			      						<InputText 
		        							id={'fullname'}
		        							name={'fullname'}
		        							placeholder={t('Nombre completo')}
		        							classes={'w-100'}
		        							label={t('Nombre completo')}
		        							value={this.props.fullname}
		        							onChange={this.props.inputFieldChanged.bind(this)}
		        							error={this.props.dataErrorMessage.fullname} />

		        						<InputText 
		        							id={'email'}
		        							name={'email'}
		        							placeholder={t('Mail corporativo')}
		        							classes={'w-100'}
		        							label={t('Mail corporativo')}
		        							value={this.props.email}
		        							onChange={this.props.inputFieldChanged.bind(this)}
		        							error={this.props.dataErrorMessage.email} />
			      					</div>
			      				</div>

			      				<div className={'col-12 text-right'}>
			      					<a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Empezar')}</span></a>
			      				</div>
			      			</div>

		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
        loggedUser : storeApp.loggedUser,
        fullname : storeApp.fullname,
        email : storeApp.email,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        saveMyData: (fullname, email) => dispatch(saveMyData(fullname, email))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home));
