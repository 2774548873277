import React from 'react';

import logo from '../assets/img/logo-black.svg'

import { withTranslation } from 'react-i18next';

class NotFound extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
    	const { t } = this.props;

	    return (
	      	<React.Fragment>
						<div className={'container vh100 watermark-bottom-right'}>
	      			<div className={'row'}>
	      				<div className={'col-12 mt-5'}>
			      			<img src={logo} />
		        		</div>
								<div className={'col-12 mt-5 d-flex flex-column align-items-center text-center'}>
									<p className={'fs-140 mb-0 dark-gray bold'}>404</p>
									<p className={'fs-14 dark-gray bold'}>WOOPS...</p>
									<p className={'fs-12 mb-5'}>{t('Lo siento, no podemos encontrar la página que estás buscando')}...</p>
									<a className={'btn-submit'} onClick={this.props.history.goBack}>
										<span className={'circle'} aria-hidden="true">
											<span className={'icon arrow'}></span>
										</span>
										<span className={'button-text'}>{t('Volver')}</span>
									</a>
								</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
	 }

}

export default withTranslation()(NotFound);