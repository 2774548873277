import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router
} from "react-router-dom";
import { Provider } from 'react-redux'

import App from './App';
import * as serviceWorker from './serviceWorker';

import configureStore from './store/store';

import {I18nextProvider} from "react-i18next";
import i18next from './i18n';

const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
	  	<Router basename={'/'}>
	  		<I18nextProvider i18n={i18next}>
	  			<Suspense fallback="">
	    			<App />
	    		</Suspense>
	    	</I18nextProvider>
	  	</Router>
	 </Provider>,
  	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
