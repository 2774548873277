import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import moment from 'moment';

import { types } from '../../constants/constants';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import Score from '../../components/score'

import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import ExcelIcon from '../../assets/img/file-icons/doc-excel.svg';
import WordIcon from '../../assets/img/file-icons/doc-word.svg';
import DownloadIcon from "../../assets/img/icon-download.svg";

import { isLogged,
            getUser } from '../../actions/admin-users.js';

import { inputFieldChanged,
            getCoaches,
            selectCoach } from '../../actions/app.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';


import { ExclamationCircleOutlined,
            CalendarOutlined } from '@ant-design/icons';

import { Modal, Button, Space, Avatar } from 'antd';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class CoacheeDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            proposal : [],
            hasCoach : true,
            selectedCoach : [],
            link_calendario : '',
            coacheeTools : [],
        };
    }

    componentDidMount(){

        Promise.resolve(this.props.isLogged())
        .then((response)=> {

            if(this.props.isLoggedUser && this.props.user.role.code === 2){
        
                this._getDataFromAPI();

            } else {

                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')

            }

        });

    }

    _getDataFromAPI(){ 
        const { t, i18n } = this.props
        const current_lang = i18n.language

        // Obtener toda la info del usuario
        Promise.resolve(this.props.getUser(this.props.user._id))
        .then((response)=> {
            if(this.props.dataError === false){
                if(this.props.user.proposals){
                    this.props.user.proposals.map((proposal, key)=>{
                        if(proposal.closed == false){
                            this.setState({ 'proposal' : proposal });

                            if(typeof proposal.coach == 'undefined' || proposal.coach == ''){
                                this.setState({ 
                                    'hasCoach' : false
                                });
                            }else{
                                this.setState({
                                    'hasCoach' : true,
                                    'coacheeTools' : proposal.tools,
                                    'selectedCoach' : proposal.coach 
                                });
                            }
                        }
                        
                    })
                }

                Promise.resolve(this.props.getCoaches(current_lang))
                .then((response)=> {
                    if(this.props.coaches){
                        let dataSource = [];
                        let Promises = [];

                        this.props.coaches.map((coach, key) => {
                            let num = key + 1;
                            let _id, nombre, email, image, city, country, active, numValoraciones, score;

                            if(
                                typeof coach.acf.email != 'undefined' && 
                                coach.acf.email != '' && 
                                JSON.stringify(coach.categories).indexOf(this.state.proposal.coachCategory) != -1)
                            {
                                
                                var formBody = [];
                
                                var details={ 
                                    'email' : coach.acf.email
                                };

                                for (var property in details) {
                                      var encodedKey = encodeURIComponent(property);
                                      var encodedValue = encodeURIComponent(details[property]);
                                      formBody.push(encodedKey + "=" + encodedValue);
                                }

                                var status = null;

                                Promises.push(fetch(types.API_URL + 'user/get-by-email', {
                                    method: 'post',
                                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                                    body: formBody.join("&"),
                                })
                                .then(function(response) {
                                    status = response.status; // Get HTTP status code
                                    return response.json();
                                })
                                .then((responseJson, response) => {
                                    if(status === 200){ // If success
                                        _id = responseJson.user._id;
                                        nombre = responseJson.user.first_name;
                                        email = responseJson.user.email;
                                        image = responseJson.user.picture;
                                        city = responseJson.user.city;
                                        country = responseJson.user.country;
                                        active = responseJson.user.active;
                                        numValoraciones = responseJson.numValoraciones;
                                        score = parseInt(responseJson.numValoraciones) == 0 ? 0 : (parseInt(responseJson.totalValoraciones)/parseInt(responseJson.numValoraciones));
                                    }else{
                                        active = false;
                                    }

                                    const { t, i18n } = this.props
                                    const current_lang = i18n.language

                                    let coachLink = current_lang.indexOf('en') != -1 ? coach.link.replace("/blog/", "/en/blog/") : coach.link;

                                    var coachItem = {
                                        _id : _id,
                                        num : num,
                                        nombre : nombre,
                                        email : email,
                                        image : image,
                                        city : city,
                                        country : country,
                                        active : active,
                                        link : coachLink,
                                        link_calendario : coach.acf.link_calendario,
                                        numValoraciones : numValoraciones,
                                        score : score
                                    };
                                    
                                    if(JSON.stringify(responseJson.user._id).indexOf(this.state.selectedCoach) != -1){ 
                                        this.setState({ link_calendario : coach.acf.link_calendario }) 
                                    }

                                    if(coachItem.active)
                                        dataSource.push(coachItem);
                                })
                                .catch((error) => {
                                    alert(error);
                                }));
                            }
                        })

                        Promise.all(Promises).then(values => { 
                            dataSource.sort(function(a,b)
                            {
                                if(a.num < b.num) return -1;
                                if(a.num > b.num) return 1;
                                return 0;
                            });

                            this.setState({
                                dataSource : dataSource,
                                isLoaded : true
                            });


                        }).catch(reason => { 
                              console.log(reason)
                        }); 
                    }
                });
            }
        })
    }

    _selectCoach(proposal_id, coach_id, coachee_id){
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres seleccionar a este coach?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Una vez seleccionado será tu coach asignado y empezará tu programa'),
            okText: t('Seleccionar'),
            cancelText: t('Cancelar'),
            onOk : () => {

                Promise.resolve(this.props.selectCoach(proposal_id, coach_id, coachee_id))
                .then((response)=> {
                    this.setState({ 'hasCoach' : true });
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    _renderSeleccion() {
        const { t } = this.props;

        const coaches = this.state.dataSource;

        return(
            <React.Fragment>

                <header className={"managementPanel__content-header"}>
                    <div>
                        <h1 className={"managementPanel__title"}>
                          EXEKUTIVE COACHING:
                        </h1>
                        <p className={"managementPanel__title"}>
                          {t('Selecciona tu coach')}
                        </p>
                    </div>
                    
                    <div className={"coachee__download-instructions"}>
                        {/*<p>¿Tienes alguna duda?</p>
                        <a href="" className={"orange"}>
                            Descarga las instrucciones
                        </a>*/}
                    </div>
                </header>
                
                <section className={"coachee__content-body"}>
                    <p>
                        {t('Tenemos')}{" "}
                        <span className={"orange"}>
                            { coaches.length } {t('coaches disponibles para ti')}
                        </span>
                    </p>
                    
                    <ul className={"row"}>
                        {coaches.map((coach, key) => (

                            <React.Fragment>
                            <li onClick={ this._selectCoach.bind(this, this.state.proposal.proposal._id, coach._id, this.props.user._id) } className={"col-md-4 coachee__box-container"} key={key}>
                                <div className={"coachee__box"}>

                                    {
                                        coach.link_calendario != '' && (

                                            <a href={ coach.link_calendario } target="_blank" className={"coachee__box-calendar"} onClick={ (e) => { e.stopPropagation()} }>
                                                <CalendarOutlined style={{ fontSize: '1.8em', color: '#C6C6C6' }} />
                                            </a>
                                        )

                                    }

                                    <div className={"coachee__box-content"}>
                                        <div className={"coachee__image"}>
                                            <img
                                            src={coach.image}
                                            alt={`coachee - ${coach.nombre}`}/>
                                        </div>
                                        
                                        <p>{coach.nombre}</p>

                                        <a href={coach.link} className={'btn-submit m-0 fs-08'} target="_blank" onClick={ (e) => { e.stopPropagation()} }>
                                          <span className={'circle'} aria-hidden="true">
                                              <span className={'icon arrow'}></span>
                                          </span>
                                          <span className={'button-text'}>{t('Ver perfil')}</span></a>
                                    </div>
                                    
                                    <div className={"coachee__box-footer"}>
                                        <div>
                                            <p>{t('Valoraciones')}</p>
                                            <p className={"orange"}>{coach.numValoraciones}</p>
                                        </div>

                                        <div>
                                            <p>{t('Puntuación')}</p>
                                        
                                            <Score value={coach.score} />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            </React.Fragment>
                        ))}
                    </ul>
                </section>

            </React.Fragment>
        );
    }

    _renderArchivos() {
        const { t } = this.props;

        var dataSource = [];

        this.state.coacheeTools.map((coacheeTool, key) => {
            dataSource.push(
                {
                    key: key,
                    nombre: coacheeTool.tool.name,
                    tipo: coacheeTool.tool.type,
                    fecha: moment(coacheeTool.tool.date).format('DD-MM-YYYY'),
                    id: coacheeTool._id,
                    url: coacheeTool.tool.url
                }
            );
        })

        
        const columns = [
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
                className: "bold",
            },
            {
                title: t('Tipo'),
                dataIndex: "tipo",
                key: "tipo",
                align: "center",
                render: (text, record) => <img src={PDFIcon} />,
            },
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
            },
            {
                title: "",
                key: "acciones",
                width: "115px",
                align: "center",
                render: (text, record) => (
                    <a href={record.url}>
                        <img src={DownloadIcon} />
                    </a>
                ),
            },
        ] 

        return(
            <React.Fragment>
                
                <header className={"managementPanel__content-header"}>                            
                    <div>
            
                        <h1 className={"managementPanel__title"}>
                            EXEKUTIVE COACHING:
                        </h1>
        
                        <p className={"managementPanel__title"}>{t('Panel de gestión')}</p>
                    </div>

                    <div className={"coachee__download-instructions"}>
                        
                        {/*<p>¿Tienes alguna duda?</p>
            
                        <a href="" className={"orange"}>
                              Descarga las instrucciones
                        </a>*/}
                        <a href={ this.state.link_calendario } className={"orange"}>
                            {t('Ver calendly de mi Coach')}
                        </a>
                    </div>
                </header>
                
                <section>
                    <p>
                        {t('Tenemos')}{" "}
                        <span className={"orange"}>{ dataSource.length } {t('archivos disponibles')}</span>
                    </p>
      
                    <CustomTable columns={columns} dataSource={dataSource} t={t} />
                </section>

            </React.Fragment>
        )   
    }

    render() {
        

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu />
                    
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12 pt-5"}>
                                
                                { 
                                    this.state.isLoaded ? 
                                        this.state.hasCoach ?
                                            this._renderArchivos()
                                        :
                                            this._renderSeleccion()
                                    :
                                        this._renderLoader()
                                }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage,
        coaches: storeApp.coaches,
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        isLogged: () => dispatch(isLogged()),
        getUser: (id) => dispatch(getUser(id)),
        getCoaches: (lang) => dispatch(getCoaches(lang)),
        selectCoach: (proposal_id, coach_id, coachee_id) => dispatch(selectCoach(proposal_id, coach_id, coachee_id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoacheeDashboard));