
import React from "react"

const Exit = (props) => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          style={{ fill: props.active ? "#FF866B" : "#C6C6C6" }}
          fillRule="nonzero"
        >
          <path
            d="M20.0167345,11.825 L12.1,11.825 L12.1,10.175 L20.0101726,10.175 L19.2547461,9.36131864 L20.4639553,8.23868189 L23.0262484,10.998567 L20.4653843,13.7710548 L19.2533171,12.6515044 L20.0167345,11.825 Z M15.7418701,14.3 L17.3918701,14.3 L17.3918701,22.047937 L2.17999268,22.047937 L2.17999268,-0.0106079102 L17.3918701,-0.0106079102 L17.3918701,8.56112061 L15.7418701,8.56112061 L15.7418701,1.63939209 L3.82999268,1.63939209 L3.82999268,20.397937 L15.7418701,20.397937 L15.7418701,14.3 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Exit;
