import { createMemoryHistory } from 'history';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import appReducer, { $$initialState as $$appState } from '../reducers/app';
import adminUsersReducer, { $$initialState as $$adminUsersState } from '../reducers/admin-users';

export const history = createMemoryHistory();

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        alert("No esta permitido sessionStorage: " + err);
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        alert("No esta permitido sessionStorage: " + err);
    }
};

export default () => {
    const persistedState = loadState();

    const store = createStore(
        combineReducers({
            router : connectRouter(history),
            app : appReducer,
            adminUsers: adminUsersReducer
        }),
        persistedState,
        compose(
            applyMiddleware(
                thunkMiddleware,
                routerMiddleware(history), // for dispatching history actions
            ),
            ...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
        ),
    );

    store.subscribe(() => {
        saveState(store.getState());
    });

    return store;
};
