import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { types } from '../constants/constants';

import { inputFieldChanged,
            isLogged,
            getUser,
            updateUser } from '../actions/admin-users.js';

import InputText from '../components/input';
import Menu from "../components/menu";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class UserProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
        };
    }

    componentDidMount(){

        Promise.resolve(this.props.isLogged())
        .then((response)=> {

            if(this.props.isLoggedUser){
        
                Promise.resolve(this.props.getUser(this.props.user._id))
                .then((response)=> {
                    this.setState({ isLoaded: true });
                });

            } else {

                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')

            }

        });

    }

    _saveData() {
        this.setState({ isLoaded : false });

        Promise.resolve(this.props.updateUser(
            this.props.user._id, this.props.first_name, this.props.last_name, 
            this.props.email, this.props.department, this.props.position, 
            this.props.phone, this.props.mobile, this.props.city, this.props.country))
        .then((response)=> {

            this.setState({ isLoaded : true });

        });
    }

    _renderForm() {
        const { t } = this.props;

        let first_name = this.props.first_name == 'undefined' ? '' : this.props.first_name;
        let last_name = this.props.last_name == 'undefined' ? '' : this.props.last_name;
        let email = this.props.email == 'undefined' ? '' : this.props.email;
        let department = this.props.department == 'undefined' ? '' : this.props.department;
        let position = this.props.position == 'undefined' ? '' : this.props.position;
        let phone = this.props.phone == 'undefined' ? '' : this.props.phone;
        let mobile = this.props.mobile == 'undefined' ? '' : this.props.mobile;
        let city = this.props.city == 'undefined' ? '' : this.props.city;
        let country = this.props.country == 'undefined' ? '' : this.props.country;

        return(
            <section>
                <div className={'box-with-shadow'}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'first_name'}
                                name={'first_name'}
                                placeholder={t('Tu nombre')}
                                classes={'w-100'}
                                label={t('Nombre completo')}
                                value={first_name}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.first_name} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'last_name'}
                                name={'last_name'}
                                placeholder={t('Tu apellido')}
                                classes={'w-100'}
                                label={t('Tu apellido')}
                                value={last_name}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.last_name} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'email'}
                                name={'email'}
                                placeholder={t('Tu email')}
                                classes={'w-100'}
                                label={t('Tu email')}
                                value={email}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.email} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4 d-none'}>
                            <InputText 
                                id={'company'}
                                name={'company'}
                                placeholder={''}
                                classes={'w-100'}
                                label={''}
                                value={typeof this.props.user.company != 'undefined' ? this.props.user.company._id : ''}
                                disabled />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'company_name'}
                                name={'company_name'}
                                placeholder={''}
                                classes={'w-100'}
                                label={''}
                                value={typeof this.props.user.company != 'undefined' ? this.props.user.company.name : ''}
                                disabled />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'department'}
                                name={'department'}
                                placeholder={t('Tu departamento')}
                                classes={'w-100'}
                                label={t('Tu departamento')}
                                value={department}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.department} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'position'}
                                name={'position'}
                                placeholder={t('Tu puesto')}
                                classes={'w-100'}
                                label={t('Tu puesto')}
                                value={position}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.position} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'phone'}
                                name={'phone'}
                                placeholder={t('Tu teléfono')}
                                classes={'w-100'}
                                label={t('Tu teléfono')}
                                value={phone}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.phone} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'mobile'}
                                name={'mobile'}
                                placeholder={t('Tu móvil')}
                                classes={'w-100'}
                                label={t('Tu móvil')}
                                value={mobile}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.mobile} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'city'}
                                name={'city'}
                                placeholder={t('Tu ciudad')}
                                classes={'w-100'}
                                label={t('Tu ciudad')}
                                value={city}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.city} />
                        </div>

                        <div className={'col-12 col-md-6 mb-4'}>
                            <InputText 
                                id={'country'}
                                name={'country'}
                                placeholder={t('Tu país')}
                                classes={'w-100'}
                                label={t('Tu país')}
                                value={country}
                                onChange={this.props.inputFieldChanged.bind(this)}
                                error={this.props.dataErrorMessage.country} />
                        </div>

                        { typeof this.props.dataErrorMessage.message !== 'undefined' 
                            && this.props.dataErrorMessage.message !== ''
                            && this.props.dataErrorMessage.message.length ?

                            <div className="col-12 mt-4">
                                <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.message }</p>
                            </div>

                        : null }

                        { typeof this.props.successMessage !== 'undefined' 
                            && this.props.successMessage !== '' ?

                            <div className="col-12 mt-4">
                                <p className={'text-success fs-09 mt-1'}>{ this.props.successMessage }</p>
                            </div>

                        : null }
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 text-right'}>
                        <a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
                            <span className={'circle'} aria-hidden="true">
                                  <span className={'icon arrow'}></span>
                            </span>
                            <span className={'button-text'}>{t('Guardar')}</span></a>
                    </div>
                </div>
            </section>
        )
    }

    _renderLoader() {
        return (
            <section>
                <div className={"row pt-5 mb-10"}>
                    <div className={"col-12 text-center"}>
                        <Loader type="ThreeDots" color="#FF866B" height={60} width={60} />
                    </div>
                </div>
            </section>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu />
                    
                    <div className={"container coachee"}>
                        
                        <div className={"row"}>
                            <div className={"col-12 pt-5"}>
                                
                                <header className={"managementPanel__content-header"}>
                                    <div>
                                        <h1 className={"managementPanel__title uppercase"}>
                                            EXEKUTIVE COACHING:
                                        </h1>
                                        
                                        <p className={"managementPanel__title"}>
                                            {t('Mi cuenta')}
                                        </p>
                                    </div>
                  
                                    <div className={"coachee__download-instructions"}></div>
                                </header>
                                
                                { this.state.isLoaded
                                ? this._renderForm()
                                : this._renderLoader() }

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        dataError : storeAdminUsers.dataError,
        dataErrorMessage: storeAdminUsers.dataErrorMessage,
        successMessage: storeAdminUsers.successMessage,
        user: storeAdminUsers.user,
        first_name : storeAdminUsers.first_name,
        last_name : storeAdminUsers.last_name,
        email : storeAdminUsers.email,
        phone :  storeAdminUsers.phone,
        mobile :  storeAdminUsers.mobile,
        city :  storeAdminUsers.city,
        country :  storeAdminUsers.country,
        department :  storeAdminUsers.department,
        position :  storeAdminUsers.position,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        isLogged: () => dispatch(isLogged()),
        getUser: (id) => dispatch(getUser(id)),
        updateUser: (_id, first_name, last_name, email, department, position, phone, mobile, city, country) => dispatch(updateUser(
            _id, first_name, last_name, email, department, position, phone, mobile, city, country))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserProfile));
