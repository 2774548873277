import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import moment from 'moment';

import { isLogged,
            getUserByEmail } from '../../actions/admin-users.js';
import { getProposals,
            editProposal,
            getCoachees,
            changeCoachStatus,
            updateCoacheeProfile,
            updateCoacheeDashboard,
            updateCoacheeWelcome,
            deleteUserCoachee } from '../../actions/app.js';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import { Modal, Button, Space, Switch } from 'antd';
import { EditOutlined, 
            UploadOutlined,
            EyeOutlined,
            CheckOutlined,
            CloseOutlined,
            DeleteOutlined,
            ExclamationCircleOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class CoacheeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            coachee_id : null
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 0){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        Promise.resolve(this.props.getCoachees())
        .then((response)=> {
            if(this.props.coachees){
                let dataSource = [];

                this.props.coachees.map((coachee, key) => {
                    let num = key + 1;

                    var coacheeItem = {
                        id : coachee._id,
                        num : num,
                        nombre : coachee.first_name + ' ' + coachee.last_name,
                        email : coachee.email,
                        /*fecha : coachee.user.created_at*/
                    };

                    dataSource.push(coacheeItem);
                })

                dataSource.sort(function(a,b)
                {
                    if(a.id < b.id) return 1;
                    if(a.id > b.id) return -1;
                    return 0;
                });

                this.setState({
                    dataSource : dataSource,
                    isLoaded : true
                });
            }
        });
    }

    _uploadProfile=event=>{
        const { t } = this.props;

        var data = new FormData()
        data.append('folder', 'coachees')
        data.append('file', event.target.files[0]) 

        fetch(types.API_URL + 'upload', {
              method: 'POST',
              body: data
        })
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                       alert(t('Error subiendo el fichero'))
                }
            }
        })
        .then((responseJson) => {
            Promise.resolve(this.props.updateCoacheeProfile(this.state.coachee_id, responseJson))
            .then((response)=> {
                if(this.props.dataError === false){
                    Modal.info({
                        title: t('Perfil guardado'),
                        content: t('Ya se ha guardado el perfil del Coachee')
                    });
                }else{
                    Modal.error({
                        title: t('Ha ocurrido un error'),
                        content: 'Error: ' + this.props.dataErrorMessage
                    });
                }

            });
        })
        .catch((error) => {
            alert(error)
        });
    }

    _uploadDashboard=event=>{
        const { t } = this.props;

        var data = new FormData()
        data.append('folder', 'coachees')
        data.append('file', event.target.files[0]) 

        fetch(types.API_URL + 'upload', {
              method: 'POST',
              body: data
        })
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                       alert(t('Error subiendo el fichero'))
                }
            }
        })
        .then((responseJson) => {
            Promise.resolve(this.props.updateCoacheeDashboard(this.state.coachee_id, responseJson))
            .then((response)=> {
                if(this.props.dataError === false){
                    Modal.info({
                        title: t('Cuadro de mando guardado'),
                        content: t('Ya se ha guardado el cuadro de mando del Coachee')
                    });
                }else{
                    Modal.error({
                        title: t('Ha ocurrido un error'),
                        content: 'Error: ' + this.props.dataErrorMessage
                    });
                }

            });
        })
        .catch((error) => {
            alert(error)
        });
    }

    _uploadWelcome=event=>{
        const { t } = this.props;

        var data = new FormData()
        data.append('folder', 'coachees')
        data.append('file', event.target.files[0]) 

        fetch(types.API_URL + 'upload', {
              method: 'POST',
              body: data
        })
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                       alert(t('Error subiendo el fichero'))
                }
            }
        })
        .then((responseJson) => {
            Promise.resolve(this.props.updateCoacheeWelcome(this.state.coachee_id, responseJson))
            .then((response)=> {
                if(this.props.dataError === false){
                    Modal.info({
                        title: t('Documento de bienvenida guardado'),
                        content: t('Ya se ha guardado el documento de bienvenida del Coachee')
                    });
                }else{
                    Modal.error({
                        title: t('Ha ocurrido un error'),
                        content: 'Error: ' + this.props.dataErrorMessage
                    });
                }

            });
        })
        .catch((error) => {
            alert(error)
        });
    }

    _deleteUserCoachee(id){
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres borrar a este Coachee?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si lo eliminas perderá todas sus herramientas y no podrá acceder a la plataforma'),
            okText: t('Borrar'),
            cancelText: t('Cancelar'),
            onOk : () => {
                Promise.resolve(this.props.deleteUserCoachee(id))
                .then((response)=> {
                    if(this.props.dataError == false){
                        this.setState({
                            'isLoaded' : false
                        });

                        this._getDataFromAPI();
                    }else{
                        Modal.error({
                            title: t('Ha ocurrido un error'),
                            content: this.props.dataErrorMessage.message
                        });
                    }
                });
            },
            onCancel() {
                
            },
        });
    }

    _renderList() {
        const { t } = this.props;

        const { dataSource } = this.state;

        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: "",
                dataIndex: "num",
                key: "num",
            },
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
            },
            {
                title: t('Perfil'),
                key: "perfil",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <input type="file" ref={input => this.profile = input} className={'d-none'} onChange={this._uploadProfile.bind(this)}/>
                        <a onClick={ ()=>{this.setState({coachee_id:record.id}); this.profile.click()} } className={'mx-1'} title={t('Subir el perfil del coachee')}>
                            <UploadOutlined />
                        </a>
                    </React.Fragment>
                ),
            },
            {
                title: t('Cuadro de mando'),
                key: "cuadro",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <input type="file" ref={input => this.dashboard = input} className={'d-none'} onChange={this._uploadDashboard.bind(this)}/>
                        <a onClick={ ()=>{this.setState({coachee_id:record.id}); this.dashboard.click()} } className={'mx-1'} title={t('Subir el cuadro de mando del coachee')}>
                            <UploadOutlined />
                        </a>
                    </React.Fragment>
                ),
            },
            {
                title: t('Documento de bienvenida'),
                key: "bienvenida",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <input type="file" ref={input => this.welcome = input} className={'d-none'} onChange={this._uploadWelcome.bind(this)}/>
                        <a onClick={ ()=>{this.setState({coachee_id:record.id}); this.welcome.click()} } className={'mx-1'} title={t('Subir el documento de bienvenida del coachee')}>
                            <UploadOutlined />
                        </a>
                    </React.Fragment>
                ),
            },
            {
                title: "",
                key: "herramientas",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/coachee/perfil/' + record.id} className={'mx-1'} title={t('Ver Coachee')}>
                            <EyeOutlined />
                        </a>

                        <a onClick={ this._deleteUserCoachee.bind(this, record.id) } className={'mx-1'} title={'Eliminar'}>
                            <DeleteOutlined />
                        </a>
                    </React.Fragment>
                ),
            }
        ]

        return (
            <React.Fragment>
                
                <p className={'mb-4'}>
                    {t('Tenemos')}{" "}
                    <span className={"orange"}>{ dataSource.length } {t('coachees disponibles')}</span>
                </p>
                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return(
            <div className={"split-container"}>
                <Menu history={this.props.history} />
                <div className={"container coachee"}>
                    <div className={"row"}>
                        <div className={"col-12 pt-3"}>
                            <header className={"managementPanel__content-header"}>
                                <div>
                                    <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                    <p className={"coachee__title"}>{t('Listado de coachees')}</p>
                                </div>
                            </header>

                            <section>
                                { 
                                    this.state.isLoaded ? 
                                        this._renderList()
                                    :
                                        this._renderLoader()

                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coachees: storeApp.coachees,
        proposal: storeApp.proposal,
        dataError: storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getCoachees: () => dispatch(getCoachees()),
        editProposal: (proposal_id) => dispatch(editProposal(proposal_id)),
        changeCoachStatus: (firstname, email, picture, city, country, newStatus) => dispatch(changeCoachStatus(firstname, email, picture, city, country, newStatus)),
        getUserByEmail: (email) => dispatch(getUserByEmail(email)),
        updateCoacheeProfile: (coachee_id, url) => dispatch(updateCoacheeProfile(coachee_id, url)),
        updateCoacheeDashboard: (coachee_id, url) => dispatch(updateCoacheeDashboard(coachee_id, url)),
        updateCoacheeWelcome: (coachee_id, url) => dispatch(updateCoacheeWelcome(coachee_id, url)),
        deleteUserCoachee: (id) => dispatch(deleteUserCoachee(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoacheeList));
