import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';

import CustomMenu from "../../components/menu"
import { CoachTools } from "../../components/coach-tools"
import { Coachee } from "../../components/coachee"
import { Tool } from "../../components/tool"
import { Menu, Dropdown } from "antd"
import { Modal, Button, Space, Switch } from 'antd';

import { isLogged,
            getUser } from '../../actions/admin-users.js';

import { getCoach,
            getCoachTools,
            getCoachCoachees,
            addCoacheeTool } from '../../actions/app.js';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import PDFIcon from "../../assets/img/file-icons/doc-pdf.svg"

import { withTranslation } from 'react-i18next';

const menu = (
    <Menu>
        <Menu.Item key="0">
            <a href="http://www.alipay.com/">1st menu item</a>
        </Menu.Item>
        <Menu.Item key="1">
            <a href="http://www.taobao.com/">2nd menu item</a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
)

class CoachDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            coachTools : [],
            coachees : []
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 1){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        Promise.resolve(this.props.getCoach(this.props.user._id))
        .then((response)=> {
            Promise.resolve(this.props.getCoachCoachees(this.props.user._id))
            .then((response)=> {

                this.setState({
                    isLoaded : true,
                    coachTools : this.props.coach.tools,
                    coachees : this.props.coachees
                });
            })
        });
    }

    _updateStore(tool_id, coach_id, coachee_id){
        const { t } = this.props;

        Promise.resolve(this.props.addCoacheeTool(tool_id, coach_id, coachee_id))
        .then((response)=> {
            if(this.props.dataError === false){
                Modal.info({
                    title: t('Herramienta asignada'),
                    content: t('La herramienta ha sido asignada al Coachee')
                });
            }else{
                Modal.error({
                    title: t('Herramienta repetida'),
                    content: t('Esta herramienta ya esta añadida')
                });
            }
        })
    }

    _showCoachee(coachee_id){
        this.props.history.push('/coachee/perfil/' + coachee_id)
    }

    _renderCoachTools () {
        const { t } = this.props;

        let coachTools = this.state.coachTools;

        if(typeof coachTools != 'undefined'){
            coachTools.sort(function(a,b)
            {
                if(a.name < b.name) return -1;
                if(a.name > b.name) return 1;
                return 0;
            });
        }

        return(
            <div className={'row'}>

                { typeof coachTools != 'undefined' && coachTools.length ?
                    coachTools.map((tool, key) => (

                        <Tool
                            key={ key }
                            tool={ tool }
                            coach_id={ this.props.coach._id }
                            updateStore={this._updateStore.bind(this)} />

                    ))
                :
                    <p className={'m-0'}>{t('No hay herramientas disponibles')}</p>
                }

            </div>
        )
    }

    _renderPanel(){
        const { t } = this.props;

        const coachTools = this.state.coachTools;
        const coachToolsNumber = typeof coachTools != 'undefined' ? coachTools.length : 0;
        const coaches = this.state.coachees;

        return(
            <div className={"container coachee"}>
                <div className={"row"}>
                    <div className={"col-8 pt-5"}>
                        <header className={"managementPanel__content-header"}>
                            <div>
                                <h1 className={"managementPanel__title"}>
                                  EXEKUTIVE COACHING:
                                </h1>
                                
                                <p className={"managementPanel__title"}>{t('Panel de gestión')}</p>
                            </div>
                        </header>
                        
                        <section>
                            <div className="row">
                                <div className="col-7">
                                    <p>    
                                        EXEKUTIVE Coaching Toolbox{" "}
                                        <span className={"orange"}>
                                            { coachToolsNumber } {t('herramientas disponibles')}
                                        </span>
                                    </p>
                                      
                                    <p className="fs-09 edit-gray">
                                        {t('Selecciona el archivo y arrástralo sobre el usuario que quieres que acceda a este documento')}
                                    </p>
                                </div>
                                
                                <div className="col-12 mt-5 mb-4 d-flex justify-content-between align-center">
                                    <div className="filter d-flex">
                                        {/*<p className="mb-0">Filtrar por</p>
                                        
                                        <Dropdown overlay={menu} trigger={["click"]}>
                                            <a
                                            className="ant-dropdown-link dark-gray bold ml-2"
                                            onClick={(e) => e.preventDefault()}>
                                                CATEGORÍA
                                            </a>
                                        </Dropdown>*/}
                                    </div>
                                    
                                    <a href="https://exekutivecoaching.com/contacto/" target="_blank">{t('¿Tienes más herramientas?')}</a>
                                </div>
                            </div>
                            
                            { this._renderCoachTools() }

                        </section>
                    </div>
          
                    <div className="col-4 pt-5">
                        <h1 className={"managementPanel__title text-center border-bottom-gray pb-5 mr-4 ml-4 mb-3"}>
                            {t('TUS COACHEES')}
                        </h1>
                        
                        <div className="row d-flex justify-content-center">
                            { typeof coachTools != 'undefined' && coachTools.length ?
                                coaches.map((coachee, key) => (
                                    <Coachee
                                    key={key}
                                    user={coachee}
                                    showCoachee={this._showCoachee.bind(this, coachee._id)} />

                                ))
                            :
                                <p className={'m-0'}>{t('No tienes coachees')}</p>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }
    
    render() {
        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <CustomMenu />
                    
                    <DndProvider backend={HTML5Backend}>
                        { 
                            this.state.isLoaded ? 
                                this._renderPanel()
                            :
                                this._renderLoader()

                        }
                    </DndProvider>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coach: storeApp.coach,
        coachTools: storeApp.coachTools,
        coachees: storeApp.coachees,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getUser: (id) => dispatch(getUser(id)),
        getCoach: (id) => dispatch(getCoach(id)),
        getCoachTools: () => dispatch(getCoachTools()),
        getCoachCoachees: (id) => dispatch(getCoachCoachees(id)),
        addCoacheeTool: (tool_id, coach_id, coachee_id) => dispatch(addCoacheeTool(tool_id, coach_id, coachee_id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoachDashboard));
