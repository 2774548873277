import React from "react"

const Info = (props) => {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g style={{ fill: props.active ? '#FF866B' : '#C6C6C6'}} fillRule="nonzero">
              <path d="M11,21.725 C5.07674606,21.725 0.275,16.9232539 0.275,11 C0.275,5.07674606 5.07674606,0.275 11,0.275 C16.9232539,0.275 21.725,5.07674606 21.725,11 C21.725,16.9232539 16.9232539,21.725 11,21.725 Z M11,20.075 C16.0119841,20.075 20.075,16.0119841 20.075,11 C20.075,5.9880159 16.0119841,1.925 11,1.925 C5.9880159,1.925 1.925,5.9880159 1.925,11 C1.925,16.0119841 5.9880159,20.075 11,20.075 Z M11.7414,9.61620011 L11.7414,16.3878003 L10.1112,16.3878003 L10.1112,9.61620011 L11.7414,9.61620011 Z M11.9856,7.64280001 C11.9856,7.78360072 11.9570003,7.9155994 11.8998,8.03880002 C11.8425997,8.16200063 11.7667005,8.26979956 11.6721,8.36220002 C11.5774996,8.45460048 11.4664007,8.52829975 11.3388,8.58330002 C11.2111994,8.6383003 11.0748007,8.66580002 10.9296,8.66580002 C10.7887993,8.66580002 10.6557006,8.6383003 10.5303,8.58330002 C10.4048994,8.52829975 10.2960005,8.45460048 10.2036,8.36220002 C10.1111995,8.26979956 10.0375003,8.16200063 9.9825,8.03880002 C9.92749973,7.9155994 9.9,7.78360072 9.9,7.64280001 C9.9,7.49759928 9.92749973,7.36120065 9.9825,7.23360001 C10.0375003,7.10599937 10.1111995,6.99600047 10.2036,6.9036 C10.2960005,6.81119954 10.4048994,6.73750028 10.5303,6.6825 C10.6557006,6.62749973 10.7887993,6.6 10.9296,6.6 C11.0748007,6.6 11.2111994,6.62749973 11.3388,6.6825 C11.4664007,6.73750028 11.5774996,6.81119954 11.6721,6.9036 C11.7667005,6.99600047 11.8425997,7.10599937 11.8998,7.23360001 C11.9570003,7.36120065 11.9856,7.49759928 11.9856,7.64280001 Z" id="Combined-Shape"></path>
          </g>
      </g>
    </svg>
  )
}

export default Info;
