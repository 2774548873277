import React from 'react';

export default class InputNumber extends React.Component {
    constructor(props) {
        super(props);

        const {value, name} = this.props;

        this.state = {
            value: value,
            name: name
        };
    }

    _increaseNumber() {
        const {name} = this.state;

        if(this.props.disabled !== true && this.props.readonly !== true)
        {
            let new_value = this.state.value + 1;
            this.setState({
                value: new_value
            });

            this.props.onChange(name, new_value);
        }
    }

    _decreaseNumber() {
        const {name} = this.state;

        if(this.state.value > 0 && (this.props.disabled !== true && this.props.readonly !== true))
        {
            let new_value = this.state.value - 1;
            this.setState({
                value: new_value
            });

            this.props.onChange(name, new_value);
        }
    }

    _onChange(e) {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    render() {
        const {id, placeholder, className, name, disabled, readonly} = this.props;

        const {value} = this.state;

        return (
            <React.Fragment>
                <div className={'w-100 input-number-wrapper'}>
                    <button className={'btn-minus'} onClick={this._decreaseNumber.bind(this)}>&ndash;</button>
                    <input 
                        type={'number'} 
                        id={id} 
                        name={name}
                        placeholder={placeholder}
                        onChange={this._onChange.bind(this)} 
                        value={value} 
                        disabled={disabled}
                        className={className}
                        readOnly={readonly} />
                    <button className={'btn-plus'} onClick={this._increaseNumber.bind(this)}>+</button>
                </div>
            </React.Fragment>
        );
    }
}
