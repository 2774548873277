import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import moment from 'moment';

import { isLogged } from '../../actions/admin-users.js';
import { getProposals,
            editProposal } from '../../actions/app.js';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import { EditOutlined } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class ProposalList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
        };
    }

    componentDidMount() {
        const { t } = this.props;

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 0){

                Promise.resolve(this.props.getProposals())
                .then((response)=> {
                    if(this.props.proposals){
                        let dataSource = [];

                        var formatter = new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        });

                        this.props.proposals.map((proposal, key) => {
                            dataSource.push({
                                num : (key + 1),
                                id : proposal._id,
                                key : key,
                                nombre : proposal.fullname,
                                email : proposal.email,
                                fecha : moment(proposal.created_at).format('DD-MM-YYYY'),
                                estado : t(proposal.status.message),
                                total : formatter.format(proposal.totalPropuesta)
                            });
                        })

                        this.setState({
                            dataSource : dataSource,
                            isLoaded : true
                        });
                    }
                });

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _editProposal(proposal_id) {

        Promise.resolve(this.props.editProposal(proposal_id))
        .then((response)=> {
            if(this.props.proposal){
                this.props.history.push('/solicitud/resumen')
            }
        });

    }

    _renderList() {
        const { t } = this.props;

        const { dataSource } = this.state;

        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: "",
                dataIndex: "num",
                key: "num",
            },
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
            },
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
            },
            {
                title: "Total",
                dataIndex: "total",
                key: "total",
                align: "center",
            },
            {
                title: t('Estado'),
                dataIndex: "estado",
                key: "fecha",
            },
            {
                title: "",
                key: "acciones",
                width: "125px",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={'/solicitud/' + record.id} className={'mx-1'} title={'Detalle solicitud'}>
                            <EyeOutlined />
                        </a>

                        <a onClick={ this._editProposal.bind(this, record.id) } className={'mx-1'} title={'Editar'}>
                            <EditOutlined />
                        </a>
                    </React.Fragment>
                ),
            },
        ]

        return (
            <React.Fragment>
                
                <p className={'mb-4'}>
                    {t('Tenemos')}{" "}
                    <span className={"orange"}>{ dataSource.length } {t('solicitudes disponibles')}</span>
                </p>
                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return(
            <div className={"split-container"}>
                <Menu history={this.props.history} />
                <div className={"container coachee"}>
                    <div className={"row"}>
                        <div className={"col-12 pt-3"}>
                            <header className={"managementPanel__content-header"}>
                                <div>
                                    <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                    <p className={"coachee__title"}>{t('Listado de solicitudes de propuesta')}</p>
                                </div>
                            </header>

                            <section>
                                { 
                                    this.state.isLoaded ? 
                                        this._renderList()
                                    :
                                        this._renderLoader()

                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        proposals: storeApp.proposals,
        proposal: storeApp.proposal
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getProposals: () => dispatch(getProposals()),
        editProposal: (proposal_id) => dispatch(editProposal(proposal_id)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProposalList));
