import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import Header from '../../components/header';
import Steps from '../../components/steps';
import InputNumber from '../../components/input-number';
import TestimonialsMMP from '../../components/testimonials-mmp';

import {inputFieldChanged, 
		saveParticipants,
		getCoachesCategories } from '../../actions/app.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { Select } from 'antd';

const { Option } = Select;

class ParticipantesEdit extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
        	isLoaded : false,
        };
    }

    componentDidMount(){
    	// Obtener las categorias de Coach
	    Promise.resolve(this.props.getCoachesCategories())
		.then((response)=> {
	        this.setState({
				'isLoaded' : true
			});
	    });
    }

    _saveData(){
    	const {coacheesNumber, coachCategory} = this.props;
    	
    	Promise.resolve(this.props.saveParticipants(coacheesNumber, coachCategory))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.props.history.push('/solicitud/herramientas');
	        }
	    });
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    _renderParticipants(){
    	const { t } = this.props;
    	
    	let {coacheesNumber, coachCategory} = this.props;

    	coacheesNumber = (typeof coacheesNumber == 'undefined' || coacheesNumber == null) ? 0 : coacheesNumber;

    	return(
	    	<div className={'box-with-shadow'}>
				<h2 className={'dark-gray mb-5'}>
					Mis participantes 
				</h2>

				<div className={'row my-4'}>
					<div className={'col-7 col-md-8 my-auto'}>
						<p className={'mb-0'}>¿Cuántos coachees tienes?</p>
					</div>
	    		<div className={'col-5 col-md-4 my-auto'}>
					<InputNumber 
	    				id={'coacheesNumber'}
	    				name={'coacheesNumber'}
	    				placeholder={'0'}
	    				value={coacheesNumber}
	    				onChange={this.props.inputFieldChanged.bind(this)} />
	    		</div>
	    		
	    		{(typeof this.props.dataErrorMessage.coacheesNumber !== 'undefined' && this.props.dataErrorMessage.coacheesNumber !== '') ?
	                <p className={'red fs-09 mt-1'}>{this.props.dataErrorMessage.coacheesNumber}</p>
	            : null }
	    	</div>

	    	<div className={'row my-4'}>
					<div className={'col-12'}>
		        	<Select 
		        	id={'coachCategory'}
		        	placeholder="¿Qué categoría de coach quieres que tengan?" 
		        	className={'w-100'}
		        	value={coachCategory}
		        	onChange={this.props.inputFieldChanged.bind(this, 'coachCategory')}>

			        	{typeof this.props.coachCategories !== 'undefined' ? 
			        		Object.entries(this.props.coachCategories).map(([key, category]) => {
								return(
									<Option key={key} value={category.name}>{category.name}</Option>
								);
								})
							: null}

				    </Select>
				</div>

				{(typeof this.props.dataErrorMessage.coachCategory !== 'undefined' && this.props.dataErrorMessage.coachCategory !== '') ?
	                <p className={'red fs-09 mt-1'}>{this.props.dataErrorMessage.coachCategory}</p>
	            : null }
			</div>
			</div>
		)
    }

    render() {
    	let {coacheesNumber, coachCategory} = this.props;

    	coacheesNumber = (typeof coacheesNumber == 'undefined' || coacheesNumber == null) ? 0 : coacheesNumber;

        return (
       		<React.Fragment>
			    <TestimonialsMMP proposal={this.props.proposal} />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<Steps 
			      				currentStep={"2"}
			      				history={this.props.history} />

			      			<div className={'row pt-3'}>
			      				<div className={'col-12 text-left mb-3'}>
			      					<p className={'bold'}>Dale forma a tu programa</p>
			      				</div>

			      				<div className={'col-12'}>
			      					{ 
	                                    this.state.isLoaded ? 
	                                        this._renderParticipants()
	                                    :
	                                        this._renderLoader()
	                                }
			      				</div>

			      				<div className={'col-12 text-right'}>
			      					<a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>Siguiente</span></a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
        coacheesNumber : storeApp.coacheesNumber,
        coachCategory: storeApp.coachCategory,
        coachCategories: storeApp.coachCategories,
        proposal : storeApp.proposal,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        saveParticipants: (coacheesNumber, coachCategory) => dispatch(saveParticipants(coacheesNumber, coachCategory)),
        getCoachesCategories: () => dispatch(getCoachesCategories()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParticipantesEdit);

