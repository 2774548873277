import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { inputFieldChanged,
            doLogin,
            doLogout,
            isLogged } from '../actions/admin-users.js';

import InputText from '../components/input';
import TestimonialsMMP from '../components/testimonials-mmp';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.doLogout())
        .then((response)=> {
            this.props.history.push('/login')
        });

    }

    _saveData(){
        Promise.resolve(this.props.doLogin(this.props.email, this.props.password))
        .then((response)=> {
            if(this.props.user){
                this.props.history.push(this.props.redirectTo);
            }
        });
    }

    render () {
        return (
            <React.Fragment>
            
                <TestimonialsMMP />
            
                <div className={'container'}>
                    <div className={'d-flex flex-column justify-content-center'} style={{height: '100vh'}}>
                        <div className={'row'}>
                            <div className={'col-12 col-md-8 offset-md-4 align-self-center'}>

                                <div className={'row mt-5 mb-5'}>
                                  <div className={'col-12 text-left'}>
                                    <h1>ACCEDE A <br /> EXEKUTIVE COACHING</h1>
                                  </div>
                                </div>

                                <div className={'row'}>
                                    <div className={'col-12 text-left mb-2'}>
                                        <p className={'bold dark-gray'}>Introduce tus datos</p>
                                    </div>

                                    <div className={'col-12'}>
                                        <InputText
                                            id={'email'}
                                            name={'email'}
                                            placeholder={'Email'}
                                            classes={'w-100'}
                                            label={'Email'}
                                            value={this.props.email}
                                            onChange={this.props.inputFieldChanged.bind(this)}
                                            error={this.props.dataErrorMessage.email}  
                                            />

                                        <InputText
                                            id={'password'}
                                            name={'password'}
                                            type={'password'}
                                            placeholder={'Contraseña'}
                                            classes={'w-100'}
                                            label={'Contraseña'}
                                            value={this.props.password}
                                            onChange={this.props.inputFieldChanged.bind(this)}
                                            error={this.props.dataErrorMessage.password} 
                                            />
                                    </div>

                                    { typeof this.props.dataErrorMessage.login !== 'undefined' 
                                        && this.props.dataErrorMessage.login !== ''
                                        && this.props.dataErrorMessage.login.length ?

                                        <div className="col-12 mt-4">
                                            <p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.login }</p>
                                        </div>

                                    : null }

                                    <div className={'col-12 text-right mt-4'}>
                                        <a className={'mt-4'} href="/">
                                            <span className={'button-text'}>¿Has olvidado tu contraseña?</span>
                                        </a>
                                    </div>

                                    <div className={'col-12'}>
                                        <a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
                                            <span className={'circle'} aria-hidden="true">
                                                <span className={'icon arrow'}></span>
                                            </span>
                                            <span className={'button-text'}>Entrar</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLogged : storeAdminUsers.isLogged,
        user : storeAdminUsers.user,
        email : storeAdminUsers.email,
        password : storeAdminUsers.password,
        dataError : storeAdminUsers.dataError,
        dataErrorMessage: storeAdminUsers.dataErrorMessage,
        redirectTo: storeAdminUsers.redirectTo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        doLogin: (email, password) => dispatch(doLogin(email, password)),
        doLogout: () => dispatch(doLogout()),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);