import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import Header from '../components/header';
import Steps from '../components/steps';
import InputText from '../components/input';
import TestimonialsMMP from '../components/testimonials-mmp';

import {inputFieldChanged, 
		saveProgramName} from '../actions/app.js';

import { QuestionCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';

import { withTranslation } from 'react-i18next';

class MiPrograma extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    _saveData(){
    	const {programName} = this.props;
    	
    	Promise.resolve(this.props.saveProgramName(programName))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.props.history.push('/MMP/participantes');
	        }
	    });
    }

    render() {
    	const { t } = this.props;
    	const content = (
		  	<div>
		    	<p>{t('Un programa es una configuración específica de un proceso de coaching')}. {t('Puede ser un solo coachee o un grupo')}.</p>
		    	<p>{t('Cumplimenta tantos Programas como configuraciones específicas necesites')}. {t('Nómbralos')}. P.e: {t('DG, Mgrs Comerciales, Altos potenciales Liderazgo, etc')}.</p>
		    	<p>{t('Modifica cualquier selección que hayas realizado en cualquier momento para ajustar a tus necesidades técnicas y económicas')}.</p>
		    	<p>{t('La herramienta sumará todos los Programas al final en la hoja Resumen')}</p>
		  	</div>
		);
        
        return (
       		<React.Fragment>
			    <TestimonialsMMP changeLanguage={true} />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<Steps 
			      				currentStep={"1"}
			      				history={this.props.history} />

			      			<div className={'row pt-3'}>
			      				<div className={'col-12 text-left mb-3'}>
			      					<p className={'bold'}>{t('Crea un programa')}</p>
			      				</div>

			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>
			      						<h2 className={'dark-gray mb-5'}>
			      							{t('Mi programa')} 
			      							<Popover placement="right" content={content} trigger="hover">
										        <QuestionCircleFilled style={{ color: '#FF866B', fontSize: '1.1em', marginLeft: '1em' }} />
										    </Popover>
			      						</h2>

		        						<InputText 
		        							id={'programName'}
		        							name={'programName'}
		        							placeholder={t('Nombre del programa')}
		        							classes={'w-100'}
		        							label={t('Nombre del programa')}
		        							value={this.props.programName}
		        							onChange={this.props.inputFieldChanged.bind(this)}
		        							error={this.props.dataErrorMessage.programName} />
			      					</div>
			      				</div>

			      				<div className={'col-12 text-right'}>
			      					<a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Siguiente')}</span></a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
        programName : storeApp.programName,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        saveProgramName: (programName) => dispatch(saveProgramName(programName))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MiPrograma));

