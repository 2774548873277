import React from 'react';
import { useDrop } from 'react-dnd'
import { types } from '../constants/constants';

import { Avatar } from 'antd';

import { Tool } from "./tool"

export const Coachee = ({ user, showCoachee }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: types.TOOL,
        drop: () => ({ name: user.first_name, id: user._id }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    
    const isActive = canDrop && isOver

    return (
        <React.Fragment>
            <div ref={drop} className="row d-flex justify-content-center coachee_item w-75">
                <div className="col-12 d-flex align-items-center mt-2 mb-2" onClick={ showCoachee.bind(user._id) }>
                    <Avatar size={32} style={{ color: '#463B34', backgroundColor: '#F5F3EF', marginRight: '1em', marginLeft: '1em', verticalAlign: '-0.3em' }}>
                        { user.first_name[0].toUpperCase() }
                        { user.last_name[0].toUpperCase() }
                    </Avatar>
                    <div>
                        <p className="fs-09 dark-gray mb-0">{ user.first_name } { user.last_name }</p>
                        <p className="fs-09 bold dark-gray mb-0">
                        { user.company.name }
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
