export const $$initialState = {
    user : [],
    isLogged : false,
    email : '',
    password : '',
    dataError : false,
    dataErrorMessage : '',
    redirectTo : '',
    successMessage : '',
    first_name : '',
    last_name : '',
    phone : '',
    mobile : '',
    city : '',
    country : '',
    department : '',
    position : '',

};

export default function adminUsersReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'ADMIN_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'DO_LOGIN':
            return Object.assign({}, $$state, {
                isLogged: true,
                user : action.payload.user,
                email : '',
                password : '',
                dataError: false,
                dataErrorMessage : '',
                redirectTo: action.payload.redirectTo,
            });

        case 'DO_LOGIN_ERROR':
            return Object.assign({}, $$state, {
                user : [],
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'DO_LOGOUT':
            return Object.assign({}, $$state, {
                user : [],
                email : '',
                password : '',
                dataError: false,
                dataErrorMessage : '',
                redirectTo: ''
            });

        case 'DO_RECOVER':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage : '',
                successMessage : action.payload.successMessage
            });

        case 'DO_RECOVER_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage: ''
            });

        case 'GET_USER':
            return Object.assign({}, $$state, {
                user: action.payload.user,
                first_name: action.payload.user.first_name,
                last_name: action.payload.user.last_name,
                email: action.payload.user.email,
                department: action.payload.user.department,
                position: action.payload.user.position,
                phone: action.payload.user.phone,
                mobile: action.payload.user.mobile,
                city: action.payload.user.city,
                country: action.payload.user.country,
                dataError: false,
                dataErrorMessage : '',
                successMessage : ''
            });

        case 'GET_USER_ERROR':
            return Object.assign({}, $$state, {
                user : [],
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage : ''
            });

        case 'GET_COACHEE':
            return Object.assign({}, $$state, {
                coachee: action.payload.user,
                dataError: false,
                dataErrorMessage : '',
                successMessage : ''
            });

        case 'GET_COACHEE_ERROR':
            return Object.assign({}, $$state, {
                coachee : [],
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage : ''
            });

        case 'GET_COACH':
            return Object.assign({}, $$state, {
                coach: action.payload.user,
                dataError: false,
                dataErrorMessage : ''
            });

        case 'GET_COACH_ERROR':
            return Object.assign({}, $$state, {
                coach : [],
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'GET_USER_COMPANIES':
            return Object.assign({}, $$state, {
                user_companies: action.payload.user_companies,
                dataError: false,
                dataErrorMessage : ''
            });

        case 'GET_USER_COMPANIES_ERROR':
            return Object.assign({}, $$state, {
                user_companies : [],
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'DO_USER_UPDATE':
            return Object.assign({}, $$state, {
                user: action.payload.user,
                first_name: action.payload.user.first_name,
                last_name: action.payload.user.last_name,
                email: action.payload.user.email,
                department: action.payload.user.department,
                position: action.payload.user.position,
                phone: action.payload.user.phone,
                mobile: action.payload.user.mobile,
                city: action.payload.user.city,
                country: action.payload.user.country,
                dataError: false,
                dataErrorMessage : '',
                successMessage : action.payload.successMessage
            });

        case 'DO_USER_UPDATE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                successMessage : ''
            });

        case 'IS_LOGGED_SUCCESS':
            return Object.assign({}, $$state, {
                isLogged: true,
                user: action.payload.user,
                redirectTo: '/dashboard',
                dataError: false,
                dataErrorMessage : '',
            });

        case 'IS_LOGGED_ERROR':
            return Object.assign({}, $$state, {
                isLogged: false,
                user : [],
                redirectTo: '/login',
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        default: {
            return $$state;
        }
    }
}
