import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import Header from '../components/header';
import Steps from '../components/steps';
import TestimonialsMMP from '../components/testimonials-mmp';

import {inputFieldChanged, 
		saveTools,
		getTools } from '../actions/app.js';

import { Select } from 'antd';

import { PlusCircleOutlined } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';

import { withTranslation } from 'react-i18next';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

const { Option } = Select;

class Herramientas extends React.Component {


    constructor(props) {
        super(props);

        const { t } = this.props;

        this.state = {
        	herramientas: [
        		{
        			'label_herramienta' : t('Selecciona herramienta'),
        			'herramienta' : '',
        			'label_numero' : t('Cantidad por coachee'),
        			'numero' : null
        		}
        	],
        	isLoaded : false,
        };
    }

    componentDidMount(){
    	const { t, i18n } = this.props
        const current_lang = i18n.language
    	
    	// Obtener las Herramientas
	    Promise.resolve(this.props.getTools(current_lang))
	    .then((response)=> {
	        this.setState({
				'isLoaded' : true
			});
	    });
    }

    _saveData(){
    	const {herramientas} = this.props;
    	
    	Promise.resolve(this.props.saveTools(herramientas))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.props.history.push('sesiones');
	        }
	    });
    }

	_handleInputToolChange(key, field, value) {
  		const {herramientas} = (this.props.herramientas == 'undefined' || this.props.herramientas == null) ? this.state : this.props;

  		if(field === 'herramienta'){
  			herramientas[key].label_herramienta = value.label;
  			herramientas[key].herramienta = value.label;
  		}

	  	if(field === 'cantidadCoachee'){
	  		herramientas[key].label_numero = value.label;
	  		herramientas[key].numero = value.label;
	  	}

	  	this.setState({
            herramientas: herramientas
        });

        this.props.inputFieldChanged('herramientas', herramientas);
	}

	_addNewTool() {
		const { t } = this.props;

		const {herramientas} = (this.props.herramientas == 'undefined' || this.props.herramientas == null) ? this.state : this.props;

		herramientas.push({
			'label_herramienta' : t('Selecciona herramienta'),
			'herramienta' : '',
			'label_numero' : t('Cantidad por coachee'),
			'numero' : null
		});

		this.setState({
            herramientas: herramientas
        });
	}

	_removeTool(key) {
		const {herramientas} = (this.props.herramientas == 'undefined' || this.props.herramientas == null) ? this.state : this.props;

		herramientas.splice(key, 1);

		this.setState({
            herramientas: herramientas
        });

        this.props.inputFieldChanged('herramientas', herramientas);
	}

	_renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

	_renderTools() {
		const { t } = this.props;

    	const {herramientas} = (this.props.herramientas == 'undefined' || this.props.herramientas == null) ? this.state : this.props;

		return(
			<div className={'box-with-shadow'}>
				<h2 className={'dark-gray mb-5'}>
					{t('Mis herramientas')}*
				</h2>

				{ herramientas.map((herramienta, key) => {
					let tool_id = 'tool_' + key;
					let amount_id = 'amount_' + key;

                return(
              		<div className={'row my-4'} key={key}>
							<div className={' col-12 col-md-5 my-2'}>
				        	<Select 
				        		labelInValue
				        		className={'w-100'}
								defaultValue={{ key: herramienta.herramienta, label: herramienta.label_herramienta }}
				        		onChange={this._handleInputToolChange.bind(this, key, 'herramienta')}>

						        	{typeof this.props.tools !== 'undefined' ? 
						        		Object.entries(this.props.tools).map(([tool_key, tool]) => {
    										return(
    											<Option key={tool_key} value={tool.label}>{tool.label}</Option>
    										);
											})
						        	: null}

						    </Select>
						</div>

		        		<div className={'col-10 col-md-5 my-2'}>
							<Select 
								labelInValue
								className={'w-100'}
								defaultValue={{ key: herramienta.numero, label: herramienta.label_numero }}
								onChange={this._handleInputToolChange.bind(this, key, 'cantidadCoachee')}>
							    	<Option value="1">1</Option>
							      	<Option value="2">2</Option>
							      	<Option value="3">3</Option>
							      	<Option value="4">4</Option>
							      	<Option value="5">5</Option>
						    </Select>
		        		</div>
		        		<div className={'col-2 col-md-2 align-self-center'}>
		        			<MinusCircleOutlined 
		        				style={{ color: '#FF866B', fontSize: '1.4em', marginLeftt: '0.7em' }}
		        				onClick={this._removeTool.bind(this, key)} />
		        		</div>
		        	</div>  	
                );
            })}

            {(typeof this.props.dataErrorMessage.tools !== 'undefined' && this.props.dataErrorMessage.tools !== '') ?
                <div className={'row'}>
                    <div className={'col-12'}>
                    	<p className={'red fs-09 mt-1'}>{t(this.props.dataErrorMessage.tools)}</p>
                    </div>
                </div>
            : null }

            {(typeof this.props.dataErrorMessage.toolsNumber !== 'undefined' && this.props.dataErrorMessage.toolsNumber !== '') ?
                <div className={'row'}>
                    <div className={'col-12'}>
                    	<p className={'red fs-09 mt-1'}>{this.props.dataErrorMessage.toolsNumber}</p>
                    </div>
                </div>
            : null }

        	<div className={'row'}>
					<div className={'col-12'}>
  					<a className={'fs-09 btn-add-item w-100'} onClick={this._addNewTool.bind(this)}>
  						<PlusCircleOutlined style={{ color: '#7C7977', fontSize: '2em', marginRight: '0.7em' }} />
  						{t('Añadir Nueva herramienta Diagnóstica')}
  					</a>
  				</div>
				</div>
			</div>
		)
	}

    render() {

    	const { t } = this.props;

    	const {herramientas} = (this.props.herramientas == 'undefined' || this.props.herramientas == null) ? this.state : this.props;
        
        return (
       		<React.Fragment>
			    <TestimonialsMMP />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<Steps 
			      				currentStep={"3"}
			      				history={this.props.history} />

			      			<div className={'row pt-3 mb-5'}>
			      				<div className={'col-12 text-left mb-3'}>
			      					<p className={'bold'}>{t('Dale forma a tu programa')}</p>
			      				</div>

			      				<div className={'col-12'}>
			      					{ 
	                                    this.state.isLoaded ? 
	                                        this._renderTools()
	                                    :
	                                        this._renderLoader()
	                                }
			      				</div>

			      				<div className={'col-12 mt-4'}>
			      					<p className={'fs-09 gray07'}>
			      						*{t('Cada herramienta incluye')}: {t('pasación, informe y sesión de devolución del informe')}.<br />
										{t('Puedes añadir tantas como quieras')}.
			      					</p>
			      				</div>

			      				<div className={'col-12 text-right'}>
			      					<a className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Siguiente')}</span></a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
        tools : storeApp.tools,
        herramientas : storeApp.herramientas,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getTools: (lang) => dispatch(getTools(lang)),
        saveTools: (herramientas) => dispatch(saveTools(herramientas)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Herramientas));

