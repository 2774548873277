import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import moment from 'moment';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { isLogged,
            getUser } from '../../actions/admin-users.js';

import { getCoach,
            getCoachTools,
            addCoachTool,
            deleteCoachTool } from '../../actions/app.js';

import Menu from "../../components/menu"
import { CoachTools } from "../../components/coach-tools"
import { Tool } from "../../components/tool"
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import { Modal, Button, Space, Switch } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class CoachToolList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            coachTools : []
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 0){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        this.setState({
            isLoaded : false,
        });

        let coach_id = typeof this.props.match.params.id != 'undefined' ? this.props.match.params.id : null;

        Promise.resolve(this.props.getCoach(coach_id))
        .then((response)=> {

            Promise.resolve(this.props.getCoachTools())
            .then((response)=> {

                let coachTools = this.props.coachTools;

                this.setState({
                    isLoaded : true,
                    coachTools : coachTools
                });
            });
        });
    }

    _updateStore(tool_id, coach_id){
        const { t } = this.props;

        Promise.resolve(this.props.addCoachTool(tool_id, coach_id))
        .then((response)=> {
            if(this.props.dataError === false){
                this._getDataFromAPI()
            }else{
                Modal.error({
                    title: t('Herramienta repetida'),
                    content: t('Esta herramienta ya esta añadida')
                });
            }
        })
    }

    _deleteTool(tool_id, coach_id){
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres borrar esta herramienta?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas, este Coach ya no podrá utilizarla más.'),
            okText: t('Borrar'),
            cancelText: t('Cancelar'),
            onOk : () => {

                Promise.resolve(this.props.deleteCoachTool(tool_id, coach_id))
                .then((response)=> {
                    if(this.props.dataError === false){
                        this._getDataFromAPI()
                    }else{
                        Modal.error({
                            title: t('Error borrando la herramienta'),
                            content: this.props.dataErrorMessage
                        });
                    }
                })
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTools () {
        const { t } = this.props;

        return(
            <React.Fragment>
                <div className={'row'}>

                    <div className={'col-6'}>

                        <p className={'fs-12 fw-600'}>{t('Disponibles')}:</p>
                        
                        { this._renderCoachTools() }
                    </div>

                    <div className={'col-6'}>
                        <p className={'fs-12 fw-600'}>{t('Asignadas')}:</p>

                        <CoachTools
                            coach={ this.props.coach }
                            deleteTool={this._deleteTool.bind(this)}
                            t={t} />
                    </div>

                </div>
            </React.Fragment>
        );
    }

    _renderCoachTools () {
        const { t } = this.props;

        let coachTools = this.state.coachTools;

        coachTools.sort(function(a,b)
        {
            if(a.name < b.name) return -1;
            if(a.name > b.name) return 1;
            return 0;
        });

        return(
            <div className={'row'}>

                { coachTools.length ?
                    coachTools.map((tool, key) => (

                        <Tool
                            key={ key }
                            tool={ tool }
                            coach_id={ this.props.coach._id }
                            updateStore={this._updateStore.bind(this)} />

                    ))
                :
                    <p className={'m-0'}>{t('No hay herramientas disponibles')}</p>
                }

            </div>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return(
            <DndProvider backend={HTML5Backend}>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12 pt-3"}>
                                <header className={"managementPanel__content-header"}>
                                    { 
                                        this.state.isLoaded && (
                                            <div>
                                                <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                                <p className={"coachee__title"}>{t('Listado de herramientas del coach')} { this.props.coach.first_name }</p>
                                            </div>
                                        )
                                    }
                                </header>

                                <section>
                                    { 
                                        this.state.isLoaded ? 
                                            this._renderTools()
                                        :
                                            this._renderLoader()

                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </DndProvider>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coach: storeApp.coach,
        proposal: storeApp.proposal,
        coachTools: storeApp.coachTools,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getUser: (id) => dispatch(getUser(id)),
        getCoach: (id) => dispatch(getCoach(id)),
        getCoachTools: () => dispatch(getCoachTools()),
        addCoachTool: (tool_id, coach_id) => dispatch(addCoachTool(tool_id, coach_id)),
        deleteCoachTool: (tool_id, coach_id) => dispatch(deleteCoachTool(tool_id, coach_id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoachToolList));
