

import React from 'react';

const Download = (props) => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset-Artboard-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons/Download" style={{ fill: props.active ? '#FF866B' : '#C6C6C6' }}>
          <path d="M0,15 L16,15 L16,16 L0,16 L0,15 Z M13,7.08984381 L7.99945145,12 L3,7.08876702 L3.78151721,6.31746467 L7.44370946,9.91193501 L7.44370946,0 L8.55519344,0 L8.55519344,9.91193501 L12.2163209,6.31854147 L13,7.08984381 Z" id="Combined-Shape"></path>
        </g>
      </g>
    </svg>
  )
}

export default Download;