import React from 'react';
import { useDrag } from 'react-dnd'
import { types } from '../constants/constants';
import PDFIcon from "../assets/img/file-icons/doc-pdf.svg"
import WordIcon from "../assets/img/file-icons/doc-word.svg"

import { DeleteOutlined } from '@ant-design/icons';

export const Tool = ({ tool, coach_id, updateStore, deleteTool, disabled=false, deleteable=false }) => {
    const [{ isDragging }, drag] = useDrag({
        item: { id: tool._id, coach_id: coach_id, type: types.TOOL },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            
            if (item && dropResult && disabled!=true) {
                updateStore(item.id, item.coach_id, dropResult.id)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }), 
    })

    let type = '';

    if(tool.type.indexOf('pdf') != -1) { type = PDFIcon }
    if(tool.type.indexOf('presentation') != -1) { type = WordIcon }

    return(
        <React.Fragment>
            { disabled == true && (
                <div className={'toolbox-tool disabled w-100 position-relative'}>
                    <div className={'text-center pr-3'}>
                        <img src={type} alt={ tool.type } className={'m-0'} />
                    </div>
                    
                    <div>
                        <p className="fs-09 bold dark-gray text-break">{tool.name}</p>
                    </div>

                    <div className={'overlay d-flex w-100 h-100 position-absolute align-items-center justify-content-center'}>
                        <DeleteOutlined className={'icon'} style={{color: 'white', fontSize: '2em'}} />
                    </div>
                </div>
            )}

            { disabled == false && (
                <div ref={drag} className={'toolbox-tool w-100'}>
                    <div>
                        <img src={type} alt={ tool.type } />
                    </div>
                    
                    <div>
                        <p className="fs-09 bold dark-gray text-break">{tool.name}</p>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
