import React from 'react';

import InputText from '../components/input';
import InputNumber from '../components/input-number';
import { Select } from 'antd';

const { Option } = Select;


class UIKit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        	current: 2
        };
    }

    _handleSelectChange(value) {
  		console.log(`selected ${value}`);
	}

	_handleInputTextChange(value) {
  		console.log(`selected ${value}`);
	}

	_handleInputNumberChange(value) {
  		console.log(`selected ${value}`);
	}

    render() {
	    return (
	      	<React.Fragment>
	      		<div className={'container'}>
	      			<div className={'row'}>
	      				<div className={'col-6'}>
			      			<h1 className={'orange'}>Header 1</h1>
		        			<h2 className={'dark-gray'}>Header 2</h2>
		        			<p className={'gray'}>Lorem ipsum sit dolor ament</p>
		        		</div>
		        	</div>

		        	<div className={'row'} id={'steps'}>
	      				<div className={'col-12'}>
	      					<div className={'stepsproccess'}>
							    <div className={'stepsproccess-row'}>
							        <div className={'stepsproccess-step completed'}>
							        	<p>Paso 1</p>
							            <button type={'button'} className={'btn btn-circle'}>1</button>							          
							        </div>
							        <div className={'stepsproccess-step active'}>
							        	<p>Paso 2</p>
							            <button type={'button'} className={'btn btn-circle'}>2</button>
							        </div>
							        <div className={'stepsproccess-step'}>
							        	<p>Paso 3</p>
							            <button type={'button'} className={'btn btn-circle'}>3</button>
							        </div> 
							        <div className={'stepsproccess-step'}>
							        	<p>Paso 4</p>
							            <button type={'button'} className={'btn btn-circle'}>4</button>
							        </div> 
							    </div>
							</div>
		        		</div>
		        	</div>

		        	<div className={'row'}>
		        		<div className={'col-12'}>
			        		<Select placeholder="Selecciona una opción" className={'w-100'} onChange={this._handleSelectChange}>
						    	<Option value="jack">Jack</Option>
						      	<Option value="lucy">Lucy</Option>
						      	<Option value="Yiminghe">yiminghe</Option>
						    </Select>
						</div>

						<div className={'col-12 my-4'}>
							<InputText 
		        				id={'mi-campo'}
		        				placeholder={'Campo de texto'}
		        				classes={'w-100'}
		        				label={'Campo'}
		        				onChange={this._handleInputTextChange} />
		        		</div>
		        	</div>

		        	<div className={'row'}>
		        		<div className={'col-2'}>
							<InputNumber 
		        				id={'mi-campo-numerico'}
		        				placeholder={'0'}
		        				classes={''}
		        				defaultValue={0}
		        				onChange={this._handleInputNumberChange} />
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
	 }

}

export default UIKit;