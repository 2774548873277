import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import Menu from '../components/menu';

import { isLogged } from '../actions/admin-users.js';

import { withTranslation } from 'react-i18next';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : true
        };
    }

    componentDidMount() {
        const { t, i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang == 'es' || lang == 'en'){ 
            i18n.changeLanguage(lang) 
        }

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){
                if(this.props.user.role.code == 3){ // Is company
                    this.props.history.push('/empresa/facturas')
                }
                if(this.props.user.role.code == 2){ // Is coachee
                    this.props.history.push('/coachee/panel')
                }
                if(this.props.user.role.code == 1){ // Is coach
                    this.props.history.push('/coach/panel')
                }
            }else{
                this.props.history.push('/login')
            }
        });

    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu history={this.props.history} />
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12 pt-3"}>
                                <header className={"managementPanel__content-header"}>
                                    <div>
                                        <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                        <p className={"coachee__title"}>{t('Bienvenido a tu panel de control')}</p>
                                    </div>
                                </header>
                                
                                <section className={"coachee__content-body"}>
                                  
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard));



