import React from 'react'

const Score = (props) => {
  const widthValue = (props.value * 100) / 5;
  return (
    <div className={'score'}>
      <div className={'score__bg'}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={'score__value'} style={{ '--value': `${widthValue}%`}}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Score;