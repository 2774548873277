import React from 'react';
import { Table, Empty } from 'antd';


const CustomTable = (props) => {
  // Add hover effect
  props.columns.push(
    {
      title: "",
      key: "hoverEffect",
      width: "0px",
    }
  )
  const dataSource = props.dataSource.map(el => { el.hoverEffect = ''; return el })
  const { t } = props;
  return (
    <Table
      columns={props.columns}
      dataSource={dataSource}
      rowClassName={'fs-11'}
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>
          {t('No hay registros')}
        </span>
      } /> }}
    />
  )
}


export default CustomTable;