import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

if(process.env.NODE_ENV === 'development'){

  i18next
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'es',
      saveMissing: true, // send not translated keys to endpoint
      debug: true,
      returnEmptyString: false,
      preload: ['en', 'es'],
      ns: ['translation'],
      defaultNS: 'translation',
      backend: {
          loadPath: 'https://platform.exekutivecoaching.com/locales/{{lng}}/{{ns}}.json'
      },

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

}else{

  i18next
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'es',
      saveMissing: true, // send not translated keys to endpoint
      debug: true,
      returnEmptyString: false,
      preload: ['en', 'es'],
      ns: ['translation'],
      defaultNS: 'translation',
      backend: {
          loadPath: 'https://platform.exekutivecoaching.com/locales/{{lng}}/{{ns}}.json'
      },

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

}


export default i18next;