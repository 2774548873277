import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { withTranslation } from 'react-i18next';

class Steps extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        const {currentStep} = this.props;

        let classStepOne = currentStep === "1" ? 'active' : parseInt(currentStep) > 1 ? 'completed' : '';
        let classStepTwo = currentStep === "2" ? 'active' : parseInt(currentStep) > 2 ? 'completed' : '';
        let classStepThree = currentStep === "3" ? 'active' : parseInt(currentStep) > 3 ? 'completed' : '';
        let classStepFour = currentStep === "4" ? 'active' : parseInt(currentStep) > 4 ? 'completed' : '';

        let onClickFunctionOne, onClickFunctionTwo, onClickFunctionThree = () => { return false; };

        if(currentStep > 1) {
            classStepOne = classStepOne + ' pointer'
            onClickFunctionOne = (redirectTo) => {this.props.history.push('/MMP/mi-programa')}
        }

        if(currentStep > 2) {
            classStepTwo = classStepOne + ' pointer';
            onClickFunctionTwo = (redirectTo) => {this.props.history.push('/MMP/participantes')};
        }

        if(currentStep > 3) {
            classStepThree = classStepOne + ' pointer';
            onClickFunctionThree = (redirectTo) => {this.props.history.push('/MMP/herramientas')};
        }

        return (
            <React.Fragment>
                <div className={'row mt-4 mb-4'} id={'steps'}>
                    <div className={'col-12'}>
                        <div className={'stepsproccess'}>
                            <div className={'stepsproccess-row'}>
                           
                                <div className={'stepsproccess-step ' + classStepOne} onClick={ onClickFunctionOne }>
                                    <p>{t('Nuevo programa')}</p>
                                    <button type={'button'} className={'btn btn-circle'}>1</button>
                                    <div className="dashed-line-next"></div>
                                </div>

                                <div className={'stepsproccess-step ' + classStepTwo} onClick={ onClickFunctionTwo }>
                                    <p>{t('Participantes')}</p>
                                    <div className="dashed-line-prev"></div>
                                    <button type={'button'} className={'btn btn-circle'}>2</button>
                                    <div className="dashed-line-next"></div>
                                </div>
                                
                                <div className={'stepsproccess-step ' + classStepThree} onClick={ onClickFunctionThree }>
                                    <p>{t('Herramientas')}</p>
                                    <div className="dashed-line-prev"></div>
                                    <button type={'button'} className={'btn btn-circle'}>3</button>
                                    <div className="dashed-line-next"></div>
                                </div> 
                                
                                <div className={'stepsproccess-step ' + classStepFour}>
                                    <p>{t('Sesiones')}</p>
                                    <div className="dashed-line-prev"></div>
                                    <button type={'button'} className={'btn btn-circle'}>4</button>
                                </div> 

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Steps));
