import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import 'moment/locale/es';

import { types } from '../../constants/constants';

import Header from '../../components/header';
import TestimonialsMMP from "../../components/testimonials-mmp"
import InputText from '../../components/input';

import {getProposalOfficial,
			getTools,
			getSessions,
			getCoachesCategories,
			saveFiscal,
			inputFieldChanged } from '../../actions/app.js';

import { isLogged } from '../../actions/admin-users.js';

import { Checkbox, Radio } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';
import { MailOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Modal, Button, Space } from 'antd';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

moment.locale('es');

const { confirm } = Modal;

class ProposalConfirmClient extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        	isLoaded : false,
        	showForm : true,
        	showFormOK : false,
        	company_profile : false
        };
    }

    componentDidMount(){
    	
    	this._getDataFromAPI();

    }

    _getDataFromAPI(){

    	const { t, i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang){ 
            i18n.changeLanguage(lang) 
        }
					        	
    	let token = typeof this.props.match.params.token != 'undefined' ? this.props.match.params.token : null;
    	let token_decoded = jwt.decode(token);

    	if (typeof token_decoded === 'undefined' || typeof token_decoded.proposal_id === 'undefined') {
            Modal.error({
			    title: t('Esta propuesta no está disponible'),
			    content: t('Por favor pongase en contacto con nosotros.'),
			});
        } else {
         	Promise.resolve(this.props.getProposalOfficial(token_decoded.proposal_id, token))
		    .then((response)=> {
		        if(this.props.dataError == false){
				    if(typeof this.props.proposal === 'undefined' || this.props.proposal === null){
			    		this.props.history.push('/')
			    	}else{
			    		if(this.props.proposal.status.code === 2){
			    			this.setState({
								'showFormOK' : true
							});
			    		}

			    		// Comprobamos si tienen perfil de empresa activo en MVP
						var formBody = [];

				        var details={ 
				            'email' : this.props.proposal.email
				        };

				        for (var property in details) {
				              var encodedKey = encodeURIComponent(property);
				              var encodedValue = encodeURIComponent(details[property]);
				              formBody.push(encodedKey + "=" + encodedValue);
				        }

				        var status = null;

				        Promise.resolve(fetch(types.API_URL + 'user/get-by-email', {
				            method: 'post',
				            headers: { "Content-Type": "application/x-www-form-urlencoded" },
				            body: formBody.join("&"),
				        })
				        .then(function(response) {
				            status = response.status; // Get HTTP status code
				            return response.json();
				        })
				        .then((responseJson, response) => {
				            if(status === 200){ // If success
				            	// Solo si el usuario con este email es empresa
				            	if(responseJson.user.role.indexOf('5ee5ff775b622f72ef2b5421') != -1){
				            		if(responseJson.user){
										this.props.inputFieldChanged('razonFiscal', responseJson.user.company.name)
										this.props.inputFieldChanged('cif', responseJson.user.company.cif)
									}
				            		this.setState({
										'company_profile' : responseJson.user,
										'isLoaded' : true
									});
				                }
				            }else{
				            	console.log(responseJson);
				            	this.setState({
				            		'company_profile' : null,
									'isLoaded' : true,
								});
				            }
				        })
				        .catch((error) => {
				            alert(error);
				        }));
			    	}
		        }
		    });   
        }

    }

    _confirmProposal(){
    	let token = typeof this.props.match.params.token != 'undefined' ? this.props.match.params.token : null;

    	const {razonFiscal, cif, direccionFacturacion, provinciaFacturacion, ciudadFacturacion, telefonoFacturacion, codigopostalFacturacion, lopdFacturacion, pago } = this.props;

    	this.setState({
			'isLoaded' : false
		});

    	Promise.resolve(this.props.saveFiscal(this.props.proposal._id, razonFiscal, cif, direccionFacturacion, provinciaFacturacion, ciudadFacturacion, telefonoFacturacion, codigopostalFacturacion, lopdFacturacion, pago, token))
	    .then((response)=> {
	        if(this.props.dataError == false){
	    		this.setState({
					'showFormOK' : true
				});
	        }

	        this.setState({
				'isLoaded' : true,
			});
	    }); 
    }

	_renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    _renderFormConfirm(){
    	const { t } = this.props;

    	const proposal = this.props.proposal;

    	let proposalNumber = 'prop-' + moment(proposal.created_at).format('YYYY') + 
								'-' + proposal.proposalId.toString().padStart(5, '0');												

    	return(

			<React.Fragment>
				<div className={'row mt-5 mb-5'}>
      				<div className={'col-12 col-md-6 text-left align-self-top'}>
      					<h1 className={'orange fs-12'}>
      						{t('MI MEJOR PROPUESTA')}
      					</h1>
      				</div>

      				<div className={'col-12 col-md-6 text-right align-self-top'}>
      					<p className={'mb-1'}>{t('Nº de propuesta')}: {proposalNumber}</p>
      					<p className={'capitalize'}>
      						{ moment(this.props.proposal.created_at).format('DD MMMM YYYY') }
      					</p>
      				</div>
      			</div>

	      		<div className={'box-with-shadow'}>

	      			<div className={'row'}>
						<div className={'col-md-12'}>
							<h2 className={'mb-4'}>{t('Datos fiscales')}</h2>

							<p className={'mb-4'}>
								{t('Para poder terminar de tramitar tu solicitud, necesitamos que nos facilites la siguiente información')}.
							</p>
						</div>
					</div>

					<div className={'row'}>
						<div className={'col-12 mb-4'}>
							<InputText 
							id={'razonFiscal'}
							name={'razonFiscal'}
							placeholder={t('Razón fiscal')}
							classes={'w-100'}
							label={t('Razón fiscal')}
							value={ this.state.company_profile != null ? this.state.company_profile.company.name : this.props.razonFiscal }
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.razonFiscal}
							disabled={ this.state.company_profile != null ? 'disabled' : '' } />
						</div>
					</div>

					<div className={'row'}>
						<div className={'col-12 mb-4'}>
							<InputText 
							id={'cif'}
							name={'cif'}
							placeholder={t('NIF-CIF')}
							classes={'w-100'}
							label={t('NIF-CIF')}
							value={ this.state.company_profile != null ? this.state.company_profile.company.cif : this.props.cif }
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.cif}
							disabled={ this.state.company_profile != null ? 'disabled' : '' } />
						</div>
					</div>

					<div className={'row'}>
						<div className={'col-12 mb-4'}>
							<InputText 
							id={'direccionFacturacion'}
							name={'direccionFacturacion'}
							placeholder={t('Dirección de facturación')}
							classes={'w-100'}
							label={t('Dirección de facturación')}
							value={this.props.direccionFacturacion}
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.direccionFacturacion} />
						</div>
					</div>

					<div className={'row'}>
						<div className={'col-12 col-md-6 mb-4'}>
							<InputText 
							id={'provinciaFacturacion'}
							name={'provinciaFacturacion'}
							placeholder={t('Provincia')}
							classes={'w-100'}
							label={t('Provincia')}
							value={this.props.provinciaFacturacion}
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.provinciaFacturacion} />
						</div>

						<div className={'col-12 col-md-6 mb-4'}>
							<InputText 
							id={'ciudadFacturacion'}
							name={'ciudadFacturacion'}
							placeholder={t('Ciudad')}
							classes={'w-100'}
							label={t('Ciudad')}
							value={this.props.ciudadFacturacion}
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.ciudadFacturacion} />
						</div>
					</div>

					<div className={'row'}>
						<div className={'col-12 col-md-6 mb-4'}>
							<InputText 
							id={'codigopostalFacturacion'}
							name={'codigopostalFacturacion'}
							placeholder={t('Código postal')}
							classes={'w-100'}
							label={t('Código postal')}
							value={this.props.codigopostalFacturacion}
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.codigopostalFacturacion} />
						</div>

						<div className={'col-12 col-md-6 mb-4'}>
							<InputText 
							id={'telefonoFacturacion'}
							name={'telefonoFacturacion'}
							placeholder={t('Teléfono de contacto')}
							classes={'w-100'}
							label={t('Teléfono de contacto')}
							value={this.props.telefonoFacturacion}
							onChange={this.props.inputFieldChanged.bind(this)}
							error={this.props.dataErrorMessage.telefonoFacturacion} />
						</div>
					</div>

					<div className={'row mt-2 mb-4'}>
						<div className={'col-12'}>
							<Checkbox 
								id={'lopdFacturacion'}
								name={'lopdFacturacion'}
								checked={ typeof this.props.lopdFacturacion !== 'undefined' ? this.props.lopdFacturacion.target.checked : false }
								onChange={this.props.inputFieldChanged.bind(this, 'lopdFacturacion')}
								>{t('He leído y acepto las')} <a href={ types.BLOG_URL + 'politica-privacidad'} target='_blank'>{t('condiciones de privacidad')}</a></Checkbox>
						</div>
					</div>

					{ typeof this.props.dataErrorMessage != 'undefined' && this.props.dataErrorMessage.lopdFacturacion !== '' ?

                    	<div className='row mt-4'>
							<div className="col-12">
                    			<p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.lopdFacturacion }</p>
                    		</div>
						</div>

					: null }

					{ typeof this.state.company_profile != 'undefined' && this.state.company_profile != null ? 

						<div className='row mt-2'>
							<div className="col-12">
                    			<p className={'fs-08 mt-1'}><b>{ t('NOTA') + ': ' + t('Para modificar los datos de tu perfil de empresa, contáctanos en') } {' '} 
                    				<a href={'mailto: info@exekutivecoaching.com'}>info@exekutivecoaching.com</a></b>
                    			</p>
                    		</div>
						</div>

					: null }

					<div className={'row mt-5'}>
						<div className={'col-md-12'}>
							<h2 className={'mb-4'}>{t('Método de pago')}</h2>
						</div>
					</div>

					<div className={'row mt-2 mb-4'}>
						<div className={'col-12'}>

							<Radio.Group 
							onChange={this.props.inputFieldChanged.bind(this, 'pago')} 
							value={typeof this.props.pago != 'undefined' ? this.props.pago.target.value : null}>
						        <Radio 
						        	value={'inmediato'}>
						        	{t('Pago inmediato')}</Radio>
						        <Radio 
						        	value={'aplazado'}>
						        	{t('Pago a 45 días')}</Radio>
						    </Radio.Group>

						</div>
					</div>

					{ typeof this.props.dataErrorMessage != 'undefined' && this.props.dataErrorMessage.pago !== '' ?

                    	<div className='row mt-4'>
							<div className="col-12">
                    			<p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.pago }</p>
                    		</div>
						</div>

					: null }

				</div>

				<div className={'col-6 offset-6 col-sm-5 offset-sm-7 col-md-4 offset-md-8 col-lg-4 offset-lg-8 text-right mb-5'}>
  					<a className={'btn-submit mt-4 w-100'} onClick={this._confirmProposal.bind(this)}>
  						<span className={'circle'} aria-hidden="true">
  							<span className={'icon arrow'}></span>
  						</span>
  						<span className={'button-text'}>{t('Confirmar')}</span></a>
  				</div>

      		</React.Fragment>
      	);
    }

    _renderFormConfirmOk(){
    	const { t } = this.props;

    	const proposal = this.props.proposal;

    	let proposalNumber = 'prop-' + moment(proposal.created_at).format('YYYY') + 
								'-' + proposal.proposalId.toString().padStart(5, '0');

    	return(

			<React.Fragment>
				<div className={'row mt-5 mb-2'}>
      				<div className={'col-12 col-md-6 text-left align-self-top'}>
      					<h1 className={'orange fs-12'}>
      						{t('MI MEJOR PROPUESTA')}
      					</h1>
      				</div>

      				<div className={'col-12 col-md-6 text-right align-self-top'}>
      					<p className={'mb-1'}>{t('Nº de propuesta')}: {proposalNumber}</p>
      					<p className={'capitalize'}>
      						{ moment(this.props.proposal.created_at).format('DD MMMM YYYY') }
      					</p>
      				</div>
      			</div>

      			<div className={'row'}>
					<div className={'col-md-12'}>
						<p className={'mb-4'}>
							<CheckCircleOutlined style={{ color: '#FF866B', fontSize: '2em', marginRight: '0.5em' }} />
							{t('¡Propuesta confirmada!')}
						</p>
					</div>
				</div>

				<div className={'box-with-shadow'}>
					<div className={'row'}>
						<div className={'col-md-12'}>
							<h2 className={'mb-4'}>{t('¡GRACIAS!')}</h2>

							<p className={'mb-4'}>
								{t('Te recordamos que si deseas realizar la contratación de estos servicios necesitamos que nos envíes la orden de compra y/o la propuesta formal firmada a')} 
								{': '} <a href="mailto:info@exekutivecoaching.com">info@exekutivecoaching.com</a>
							</p>
						</div>
					</div>
				</div>

      		</React.Fragment>
      	);
    }

    render() {

    	let token = typeof this.props.match.params.token != 'undefined' ? this.props.match.params.token : null;

        return(
        	<React.Fragment>
	                
	            	<TestimonialsMMP proposal={ this.props.proposal } />

	                <div className={"container"}>
	                    <div className={"row"}>
	                        <div className={"col-12 col-md-8 offset-md-4 align-self-center pt-5"}>
	                            <section>
	                                { 
	                                    this.state.isLoaded ? 
	                                    	this.state.showFormOK ?
	                                        	this._renderFormConfirmOk()
	                                        :
	                                        	this._renderFormConfirm()
	                                    :
	                                        this._renderLoader()
	                                }
	                            </section>
	                        </div>
	                    </div>
	                </div>
	        </React.Fragment>
        );

    }

}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
    	proposal : storeApp.proposal,
    	tools : storeApp.tools,
    	sessions : storeApp.sessions,
    	coachCategories: storeApp.coachCategories,
    	lopdFacturacion: storeApp.lopdFacturacion,
    	razonFiscal: storeApp.razonFiscal,
    	cif: storeApp.cif,
    	direccionFacturacion: storeApp.direccionFacturacion,
    	provinciaFacturacion: storeApp.provinciaFacturacion,
    	ciudadFacturacion: storeApp.ciudadFacturacion,
    	codigopostalFacturacion: storeApp.codigopostalFacturacion,
    	telefonoFacturacion: storeApp.telefonoFacturacion,
    	pago: storeApp.pago,
    	dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage,
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getProposalOfficial: (id, token) => dispatch(getProposalOfficial(id, token)),
        saveFiscal: (proposal_id, razonFiscal, cif, direccionFacturacion, provinciaFacturacion, ciudadFacturacion, telefonoFacturacion, codigopostalFacturacion, lopdFacturacion, pago, token) => dispatch(saveFiscal(proposal_id, razonFiscal, cif, direccionFacturacion, provinciaFacturacion, ciudadFacturacion, telefonoFacturacion, codigopostalFacturacion, lopdFacturacion, pago, token)),
        getTools: () => dispatch(getTools()),
        getSessions: () => dispatch(getSessions()),
        getCoachesCategories: () => dispatch(getCoachesCategories()),
        isLogged: () => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProposalConfirmClient));
