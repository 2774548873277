import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { types } from '../constants/constants';

import InputText from '../components/input';
import TestimonialsMMP from '../components/testimonials-mmp';

import { getSessions,
			getTools,
			getCoachesCategories,
			saveResumen,
			validateCoupon,
			inputFieldChanged } from '../actions/app.js';

import { EditOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Modal, Button, Space } from 'antd';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class Resumen extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
        	'isLoaded' : false,
        	'totalDescuento' : 0,
        };
    }

    componentDidMount(){
    	window.onpopstate = e => {
			this.props.history.push('/MMP/mi-programa');   
		}

    	Promise.resolve(this._getDataFromAPI())
	    .then((response)=> {

	    	let totalDescuento = this._calculateDiscount(this.props.programs);

			this.setState({
				'totalDescuento' : totalDescuento
			});
		});
    }

    _getDataFromAPI(){
    	const { t, i18n } = this.props
        let current_lang = i18n.language

    	// Obtener las Coach categories
    	Promise.resolve(this.props.getCoachesCategories())
    	.then((response)=> {
        
	        if(this.props.dataError === false){

	        	let totalPropuesta = 0;
	            
	            // Procedemos a calcular los costes de cada programa
	            if ( typeof this.props.programs !== 'undefined' || this.props.programs.length > 0 ) { 

					this.props.programs.map((program, key) => {

						let totalPrograma = 0;
						let totalHerramientas = 0;
						let totalSesiones = 0;

						// Lo primero calculamos el precio de las herramientas si las tiene
						if ( typeof program.herramientas !== 'undefined' && program.herramientas.length > 0 ) {
							
							program.herramientas.map((herramienta, key) => {
								
								// Si la herramienta se ha contratado se calcula su precio
								if( herramienta.numero > 0 ){
									let totalHerramienta = 0;

									// Buscamos los precios en la tabla de herramientas traida de Wordpress
									if ( typeof this.props.tools !== 'undefined' || this.props.tools.length > 0 ) {

										this.props.tools.map((tool, key) => {

											// Cogemos el precio de la herramienta que coincide con la contratada
											if( herramienta.herramienta === tool.label ){
												// Sumamos el precio base
												/*totalHerramienta = totalHerramienta + parseInt(tool.precio_base);*/

												let categorySlug = 'precio_' + program.coachCategory.toLowerCase();

												let categoryCoach = program.coachCategory;

												categorySlug = categorySlug === 'precio_pro-bono' ? 'precio_pro_bono' : categorySlug;

												// Comprobamos si la categoria de coach puede dar la herramienta, sino le aumentamos a la siguiente.
												if ( tool[categorySlug] == 0 ) {
													categorySlug = 'precio_' + this._getNextCategory(program.coachCategory).toLowerCase();
													categoryCoach = this._getNextCategory(program.coachCategory);
													totalHerramienta = totalHerramienta + parseInt(tool[categorySlug]); 
												} else {
													totalHerramienta = totalHerramienta + parseInt(tool[categorySlug]);
												}

												herramienta.categoryCoach = categoryCoach;

											}

										})

										totalHerramienta = ((totalHerramienta * program.coacheesNumber) * herramienta.numero);

  										totalHerramientas = totalHerramientas + totalHerramienta;

  										totalPrograma = totalPrograma + totalHerramienta;

  										totalPropuesta = totalPropuesta + totalHerramienta;

  										herramienta.totalHerramienta = totalHerramienta;

									}

								}

							})

						}

						// Acontinuación calculamos el precio de las sesiones
						if ( typeof program.sesiones !== 'undefined' && program.sesiones.length > 0 ) {

							program.sesiones.map((sesion, key) => {

								// Si la sesion se ha contratado, y no es de devolución, se calcula su precio
								if( sesion.cantidadPorCoachee > 0 ){
									let totalSesion = 0;

									// Buscamos los precios en la tabla de herramientas traida de Wordpress
									if ( typeof this.props.sessions !== 'undefined' || this.props.sessions.length > 0 ) {

										this.props.sessions.map((session, key) => { 

											//console.log(session.title + ' - ' + sesion.sesion);

											//console.log(sesion.sesion.indexOf(session.title));

											if ( sesion.sesion.indexOf(session.title) != -1 ) {

												if ( typeof this.props.coachCategories !== 'undefined' || this.props.coachCategories.length > 0 ) {

													this.props.coachCategories.map((category, key) => { 

														if ( category.name === program.coachCategory ) {

															let sesionPriceSlug = 'precio_' + session.time.substring(0, session.time.length - 1);

															let categoryCoach = program.coachCategory;

															// Si es sesion de devolucion no se cobra
															if( sesion.sesion.indexOf('Devoluci') == -1 && sesion.sesion.indexOf('Herram') == -1 && sesion.sesion.indexOf('Tool Devolution') == -1){

																if ( category[sesionPriceSlug] == 0 ) {
																	
																	let coachCategorySlug  = this._getNextCategory(program.coachCategory);

																	categoryCoach = this._getNextCategory(program.coachCategory);
																	
																	this.props.coachCategories.map((category, key) => { 

																		if ( category.name === coachCategorySlug ) {
																			totalSesion = totalSesion + parseInt(category[sesionPriceSlug]);
																		}

																	})

																} else {
																	totalSesion = totalSesion + parseInt(category[sesionPriceSlug]);
																}

															} else {
																totalSesion = 0;
															}

															sesion.categoryCoach = categoryCoach;

														}

													})

												}

											}

										})

										totalSesion = ((totalSesion * program.coacheesNumber) * sesion.cantidadPorCoachee);

										//console.log(totalSesion);

  										totalSesiones = totalSesiones + totalSesion;

  										//console.log(totalSesiones);

  										totalPrograma = totalPrograma + totalSesion;

  										totalPropuesta = totalPropuesta + totalSesion;

  										sesion.totalSesion = totalSesion;

									}

								}

							})

						}

						program.totalHerramientas = totalHerramientas;

						program.totalSesiones = totalSesiones;

						program.totalPrograma = totalPrograma;

		        	});

					this.props.inputFieldChanged('totalPropuesta', totalPropuesta);

				}

				this.setState({
					'programs' : this.props.programs
				});

	        }

	        let totalDescuento = this._calculateDiscount(this.props.programs);

			this.setState({
				'totalDescuento' : totalDescuento,
				'isLoaded' : true
			});

	    })
    }

    _getNextCategory(category){
    	let new_category = '';

    	switch (category) {
			case 'PRO-BONO':
				return 'D';
			    break;
			case 'D':
			    return 'C';
			    break;
			case 'C':
			    return 'B';
			    break;
			case 'B':
			    return 'A';
			    break;
			case 'A':
			    return 'PLUS';
			    break;
			case 'PLUS':
			    return 'PREMIUM';
			    break;
			case 'PREMIUM':
			    return 'TOP';
			    break;
			default:
			    return 'PRO-BONO';
		}

		return new_category;
    }

    _calculateDiscount(programs){

    	let totalDescuento = 0;

    	programs.map((program, key) => {
    		if (typeof program.sesiones != 'undefined' && program.sesiones.length > 0){
    			program.sesiones.map((sesion, key) => {
    				if((sesion.sesion.indexOf('Devoluci') == -1 && sesion.sesion.indexOf('Herram') == -1 && sesion.sesion.indexOf('Tool Devolution') == -1)){
    					if( sesion.categoryCoach === 'A' || sesion.categoryCoach === 'B' || sesion.categoryCoach === 'C' || sesion.categoryCoach === 'D' ){
    						if(sesion.cantidadPorCoachee > 0){
    							totalDescuento = totalDescuento + sesion.totalSesion;
    						}
    					}
    				}
    			})
    		}
    	})

    	return totalDescuento
    }

    _editProgram(key){
    	this.props.inputFieldChanged('programName', this.props.programs[key].programName);
    	this.props.inputFieldChanged('coacheesNumber', this.props.programs[key].coacheesNumber);
    	this.props.inputFieldChanged('coachCategory', this.props.programs[key].coachCategory);
    	this.props.inputFieldChanged('herramientas', this.props.programs[key].herramientas);
    	this.props.inputFieldChanged('sesiones', this.props.programs[key].sesiones);
    	this.props.inputFieldChanged('isEditing', true);
    	this.props.inputFieldChanged('editingKey', key);

    	this.props.history.push('/MMP/mi-programa');
    }

    _confirmDelete(key){
    	const { t } = this.props;

    	confirm({
		    title: t('¿Seguro que quieres borrar este programa?'),
		    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
		    content: t('Si lo eliminas perderás toda la información del mismo.'),
		    okText: t('Borrar'),
		    cancelText: t('Cancelar'),
		    onOk : () => {
		      	const {programs} = (this.props.programs == 'undefined' || this.props.programs == null) ? this.state : this.props;

				programs.splice(key, 1);

		        this.props.inputFieldChanged('programs', programs);

				this.setState({
					'isLoaded' : false
				});

				this._getDataFromAPI();
		    },
		    onCancel() {
		    	
		    },
		});
    }

    _validateCoupon(){  
    	this.setState({
			'isLoaded' : false
		});
    	Promise.resolve(this.props.validateCoupon(this.props.couponCode))
	    .then((response)=> {
	    	this.setState({
				'isLoaded' : true
			});
	    });
    }

    _saveData(){
    	const { programs } = this.state;
    	
    	Promise.resolve(this.props.saveResumen(programs))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.props.history.push('/MMP/checkout');
	        }
	    });
    }

    _renderResumen() {

    	const { t } = this.props;

    	const { programs } = this.props;

    	const programsNumber = this.props.programs != 'undefined' ? this.props.programs.length : 0;

    	let couponValue = this.props.couponValidated != 'undefined' && this.props.couponValidated != null ? this.props.couponValidated.value : 0;

		var formatter = new Intl.NumberFormat('de-DE', {
		  	style: 'currency',
		  	currency: 'EUR',
		});

        return (
       		<React.Fragment>
			    <p className={'bold fs-12'}>{t('Programas')}: <span className={'orange'}>{ programsNumber }</span></p>

				{ typeof programs != 'undefined' && programs.length > 0 ? 

		        	programs.map((program, key) => {

		        		let totalPorCoachee = (parseInt(program.totalSesiones) + parseInt(program.totalHerramientas))/program.coacheesNumber;

		        		return(

	                    	<div className={'row checkout-row'} key={ key }>
	  							
	  							<div className={'col-12 col-lg-4 checkout-col no-wrap'}>
	  							
	  								<span className={'d-sm-none d-md-inline d-lg-none d-xl-inline'}>Prog. { key + 1 }</span>
	  								<span className={'d-none d-lg-inline d-xl-none'}>Prog { key + 1 }</span>
	  								
	  								<span className={'orange mt-3 pl-lg-3 d-block d-lg-inline'}>{ program.programName }</span>
	  							
	  							</div>
	  							
	  							<div className={'col-12 col-lg-4 checkout-col price-gray'}>{t('Por Coachee')} <span className={'ml-2'}>{ formatter.format(totalPorCoachee) }</span></div>
	  							
	  							<div className={'col-12 col-lg-2 checkout-col text-center fs-11'}>
	  								<a className={'edit-gray'} onClick={ this._editProgram.bind(this, key) }><EditOutlined /></a>
	  								<a className={'edit-gray'} onClick={ this._confirmDelete.bind(this, key) }><DeleteOutlined style={{ marginLeft: '0.7em' }} /></a>
	  							</div>
	  							
	  							<div className={'col-12 col-lg-2 checkout-col'}>{ formatter.format(program.totalPrograma) }</div>
	  						</div>

				        );
		        	})

	        	:   <p>{t('No hay ningún programa creado')}.</p> }

				<div className={'row mb-4 mb-lg-0'}>
					<div className={'col-12 pl-4 align-self-center'}>
						<a href="./mi-programa" className={'fs-09 fw-100 btn-add-item w-100'}>
  						<PlusCircleOutlined style={{ color: '#7C7977', fontSize: '1.6em', marginRight: '0.7em' }} />
  						{t('Añadir Nuevo programa')}
  					</a>
					</div>
				</div>

				<div className={'row checkout-row'}>
					<div className={'col-6 col-lg-9 text-left checkout-col'}>
						<span className={'d-lg-none'}>{t('Subtotal')}</span>
					</div>

					<div className={'col-6 col-lg-3 text-right checkout-col subtotal-value'}>
						{ formatter.format(this.props.totalPropuesta) }
					</div>
				</div>

				<div className={'row'}>
					<div className={'col-8 col-md-7 pr-0'}>
						<InputText 
						id={'couponCode'}
						name={'couponCode'}
						placeholder={t('¿Tienes un código de descuento?')}
						classes={'w-100'}
						label={t('Introduce tu código')}
						value={this.props.couponCode}
						onChange={this.props.inputFieldChanged.bind(this)}
						error='' />
					</div>

					<div className={'col-4 col-md-2 pl-0'}>
						<a 
						className={'btn-full btn-orange'}
						onClick={this._validateCoupon.bind(this)}>{t('Aplicar')}</a>
					</div>

					<div className={'col-12 col-md-3 mt-md-0 mt-3 text-right align-self-center'}>
						- { formatter.format(parseInt(couponValue)) }
					</div>
				</div>

				{ (typeof this.props.dataErrorMessage != 'undefined' && typeof this.props.dataErrorMessage.couponCode != 'undefined') && (

					<React.Fragment>

						<div className='row my-4'>
							<div className="col-12">
	                			<p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.couponCode }</p>
	                		</div>
						</div>

					</React.Fragment>

				)}

				<div className={'row checkout-row my-3'}>
					<div className={'col-6 col-lg-9 pr-0 text-right price-gray checkout-col'}>
						+{t('IVA')} (21%)
					</div>

					{ this.props.totalPropuesta > couponValue ? (
						<div className={'col-6 col-lg-3 text-right price-gray checkout-col'}>
							{ formatter.format(parseInt(this.props.totalPropuesta - couponValue) * 0.21) }
						</div>
					) : (
						<div className={'col-6 col-lg-3 text-right price-gray checkout-col'}>
							{ formatter.format(0) }
						</div>
					)}
				</div>

				<div className={'row checkout-row total'}>
					<div className={'col-6 col-lg-9 pr-0 pb-0 fs-19 bold text-right checkout-col'}>
						{t('Total')}
					</div>

					{ this.props.totalPropuesta > couponValue ? (
						<div className={'col-6 col-lg-3 pb-0 fs-19 bold text-right checkout-col'}>
							{ formatter.format(parseInt(this.props.totalPropuesta - couponValue) * 1.21) }
						</div>
					):(
						<div className={'col-6 col-lg-3 pb-0 fs-19 bold text-right checkout-col'}>
							{ formatter.format(0) }
						</div>
					)}
				</div>

				{ typeof this.props.dataErrorMessage.resumen !== 'undefined' && this.props.dataErrorMessage.resumen !== '' ?

                <div className='row my-4'>
						<div className="col-12">
                		<p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.resumen }</p>
                	</div>
					</div>

				: null }
	      	</React.Fragment>
	    );
    }

    _renderLoader() {
    	return(
    		<div className="col-12 text-center py-5 my-5">
			  	<Loader
			     	type="ThreeDots"
			     	color="#FF866B"
			     	height={60}
			     	width={60}
			  	/>
			</div>
		);
    }

    render() {

    	const { t, i18n } = this.props;

    	let loyalty_link = i18n.language == 'es' ? 'programa-de-fidelizacion' : 'en/loyalty-program-2/';

    	const { programs } = this.props;

    	const programsNumber = this.props.programs != 'undefined' ? this.props.programs.length : 0;

		var formatter = new Intl.NumberFormat('de-DE', {
		  	style: 'currency',
		  	currency: 'EUR',
		});

		let totalDescuento = (typeof this.props.couponValidated != 'undefined' && this.props.couponValidated != null) ? 0 : this.state.totalDescuento;

        return (
       		<React.Fragment>
			    <TestimonialsMMP />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
							
							<div className={'row mt-5 mb-5'}>
			      				<div className={'col-12 text-left align-self-center'}>
			      					<h1 className={'orange fs-12'}>
			      						{t('MI MEJOR PROPUESTA')}
			      						<span className={'gray fs-08 pl-md-3 mt-2 d-block d-md-inline align-self-center'}>{t('Resumen de mis programas')}</span>
			      					</h1>
			      				</div>
			      			</div>

			      			<div className={'row pt-3 mb-5'}>
			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>
			      						{ this.state.isLoaded == true && this._renderResumen() }
			      						{ this.state.isLoaded == false && this._renderLoader() }
			      					</div>
			      				</div>

			      				{ this.state.isLoaded == true && 
			      					<div className={'col-12 mt-4'}>
				      					<div className={'checkout-banner'}>
					      					<h4 className={'fs-19 white mb-1'}>{t('Programa de Fidelización')}</h4>
					      					
					      					<p className={'fs-12 bold white mb-3'}>{t('Recibirás un cheque descuento por valor de')} *{ formatter.format(totalDescuento) }</p>
					      					
					      					<p className={'white'}>*{t('Por oferta vigente a canjear en tu próxima contratación')}. {t('Únicamente aplicable')} {' '} 
					      					{t('para la forma de pago “inmediato”')}. {t('Consulta las')} <a href={ types.API_BLOG_URL + loyalty_link } target="_blank">{t('condiciones de contratación')}</a>{' '} 
					      					{t('en nuestro Programa de Fidelización')}.</p>
					      				</div>
				      				</div>
				      			}

			      				<div className={'col-6 offset-6 col-sm-5 offset-sm-7 col-md-4 offset-md-8 col-lg-3 offset-lg-9 text-right'}>
			      					<a className={'btn-submit mt-4 w-100'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Siguiente')}</span></a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
    }
}


function mapStateToProps(state) {
    const storeApp = state.app;

    return {
    	programName : storeApp.programName,
    	coacheesNumber : storeApp.coacheesNumber,
    	coachCategory : storeApp.coachCategory,
    	coachCategories: storeApp.coachCategories,
        sessions : storeApp.sessions,
        sesiones : storeApp.sesiones,
        tools : storeApp.tools,
        herramientas : storeApp.herramientas,
        programs : storeApp.programs,
        totalPropuesta : storeApp.totalPropuesta,
        couponCode : storeApp.couponCode,
        couponValidated : storeApp.couponValidated,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getSessions: () => dispatch(getSessions()),
        getTools: () => dispatch(getTools()),
        getCoachesCategories: () => dispatch(getCoachesCategories()),
        validateCoupon: (coupon_code) => dispatch(validateCoupon(coupon_code)),
        saveResumen: (programs) => dispatch(saveResumen(programs))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Resumen));

