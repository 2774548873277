import React from 'react';
import Menu from '../../components/menu';
import Score from '../../components/score'

class CoacheeSelection extends React.Component {
  render() {
    const coachees = [
      {
        img: 'https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/480/public/media/image/2018/08/fotos-perfil-whatsapp_16.jpg',
        name: 'Maria E',
        evaluations: 211,
        score: 2.5
      },
      {
        img: 'https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/480/public/media/image/2018/08/fotos-perfil-whatsapp_16.jpg',
        name: 'Jorge B',
        evaluations: 54,
        score: 4.5
      },
      {
        img: 'https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/480/public/media/image/2018/08/fotos-perfil-whatsapp_16.jpg',
        name: 'Alicia T',
        evaluations: 23,
        score: 3.7
      },
      {
        img: 'https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/480/public/media/image/2018/08/fotos-perfil-whatsapp_16.jpg',
        name: 'Carlos C',
        evaluations: 54,
        score: 4.5
      },
      {
        img: 'https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/480/public/media/image/2018/08/fotos-perfil-whatsapp_16.jpg',
        name: 'Lucas R',
        evaluations: 90,
        score: 5
      }
    ]
    return (
      <React.Fragment>
        <div className={"split-container"}>
          <Menu />
          <div className={"container coachee"}>
            <div className={"row"}>
              <div className={"col-12 pt-5"}>
                <header className={"managementPanel__content-header"}>
                  <div>
                    <h1 className={"managementPanel__title"}>
                      EXEKUTIVE COACHING:
                    </h1>
                    <p className={"managementPanel__title"}>
                      Selecciona tu coach
                    </p>
                  </div>
                  <div className={"coachee__download-instructions"}>
                    <p>¿Tienes alguna duda?</p>
                    <a href="" className={"orange"}>
                      Descarga las instrucciones
                    </a>
                  </div>
                </header>
                <section className={"coachee__content-body"}>
                  <p>
                    Tenemos{" "}
                    <span className={"orange"}>
                      33 coaches disponibles para ti
                    </span>
                  </p>
                  <ul className={"row"}>
                    {coachees.map((coachee) => (
                      <li className={"col-md-4 coachee__box-container"}>
                        <div className={"coachee__box"}>
                          <div className={"coachee__box-content"}>
                            <div className={"coachee__image"}>
                              <img
                                src={coachee.img}
                                alt={`coachee - ${coachee.name}`}
                              />
                            </div>
                            <p>{coachee.name}</p>
                          </div>
                          <div className={"coachee__box-footer"}>
                            <div>
                              <p>Valoraciones</p>
                              <p className={"orange"}>{coachee.evaluations}</p>
                            </div>
                            <div>
                              <p>Puntuación</p>
                              <Score value={coachee.score} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

export default CoacheeSelection;



