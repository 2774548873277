import React from 'react';
import { useLocation } from 'react-router-dom';

import logo from '../assets/img/logo-exekutivecoaching-white.svg';
import carousel1 from '../assets/img/carousel-1.jpg';
import carousel2 from '../assets/img/carousel-2.jpg';
import carousel3 from '../assets/img/carousel-3.jpg';

import { withTranslation } from 'react-i18next';

class TestimonialsMMP extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 1
        };
    }

    _selectSlide(value, e){
        if(value !== this.state.index){
            this.setState({
                index: value
            });
        }
    }

    render() {
        const { t, i18n } = this.props
        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') != -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') != -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') != -1){ i18n.changeLanguage('es') }

        const index = this.state.index;

        const logoURL = i18n.language == 'en' ? '/' + i18n.language : '/';

        return (
            <React.Fragment>
                <div className={'text-center fixed-left d-none d-md-block bg-orange'}>
                    <a href={logoURL}>
                        <img src={logo} className={'logo'} alt="Logo Exekutive Coaching" />
                    </a>

                    <div className={'row mt-5'}>
                        <div className={'col-12 px-5 pt-4'}>
                            <p className={'fs-8 white text-left mt-3'}>The mobile coaching company</p>
                        </div>

                    </div>

                    <div className={'row mt-5'}>
                        <div className={'col-12 px-5 pt-5'}>
                            <h3 className={'fs-18 lh-15 bold text-uppercase white text-left'}>{t('Democratizamos el coaching ejecutivo')}</h3>
                        </div>
                    </div>

                    <div className={'row mt-3'}>
                        <div className={'col-12 px-5'}>
                            <p className={'fs-11 white text-left pr-3'}>
                                {t('La primera solución que integra movilidad, efectividad, conveniencia y ROE para democratizar el coaching ejecutivo en tu empresa')}
                            </p>
                        </div>

                        { this.props.changeLanguage && (

                            <React.Fragment>

                                {/*<div className={'col-12 px-5 mt-5 text-left'}>
                                    <p>
                                        <a 
                                            onClick={() => { 
                                                i18n.changeLanguage('es')
                                                if(window.location.pathname.indexOf('sesiones') != -1){ window.location.reload(); }
                                            }} 
                                            className={(current_lang === "es") ? "bold white mr-3" : "white mr-3"}
                                        >
                                            ES
                                        </a>

                                        <a 
                                            onClick={() => { 
                                                i18n.changeLanguage('en')
                                                if(window.location.pathname.indexOf('sesiones') != -1){ window.location.reload(); }
                                            }}
                                            className={(current_lang === "en") ? "bold white mr-3" : "white mr-3"}
                                        >
                                            EN
                                        </a>
                                    </p>
                                </div>*/}

                            </React.Fragment>

                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(TestimonialsMMP);

/*

<img src={carousel1} className={index === 1 ? "opaque" : ""} alt="" />
<div className={`slider-caption ${index === 1 ? "opaque" : ""}`}>
    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
    <p>Laura Palmer<br />RRHH Manager ING Direct</p>
</div>

<img src={carousel2} className={index === 2 ? "opaque" : ""} alt="" />
<div className={`slider-caption ${index === 2 ? "opaque" : ""}`}>
    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
    <p>Laura Palmer<br />RRHH Manager ING Direct</p>
</div>

<img src={carousel3} className={index === 3 ? "opaque" : ""} alt="" />
<div className={`slider-caption ${index === 3 ? "opaque" : ""}`}>
    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
    <p>Laura Palmer<br />RRHH Manager ING Direct</p>
</div>

<div className={'slider-dots'}>
    <span className={index === 1 ? "opaque" : ""} onClick={this._selectSlide.bind(this, 1)}>·</span>
    <span className={index === 2 ? "opaque" : ""} onClick={this._selectSlide.bind(this, 2)}>·</span>
    <span className={index === 3 ? "opaque" : ""} onClick={this._selectSlide.bind(this, 3)}>·</span>
</div>

*/
