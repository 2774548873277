import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { withTranslation } from 'react-i18next';


class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        const programsNumber = this.props.programs != 'undefined' ? this.props.programs.length : 0;

        if ( programsNumber == 0) {
        
            return (
                <React.Fragment>
                    <div className={'row'}>
                        <div className={'col-7'}>
                        </div>
                        <div className={'col-5 text-right'}>
                            <p className={'bold'}>{t('Mis programas')} <span className={'orange-badge'}>{ programsNumber }</span></p>
                        </div>
                    </div>
                </React.Fragment>
            );

        } else {
            return (
                <React.Fragment>
                    <div className={'row'}>
                        <div className={'col-7'}>
                        </div>
                        <div className={'col-5 text-right'}>
                            <p className={'bold'}>{t('Mis programas')} <a href="/MMP/resumen"className={'orange-badge'}>{ programsNumber }</a></p>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}


function mapStateToProps(state) {
    const storeApp = state.app;

    return {
        programs : storeApp.programs
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));