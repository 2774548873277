const prod = {
	API_BLOG_URL: 'https://exekutivecoaching.com/',
  	BLOG_URL: 'https://exekutivecoaching.com/',
  	API_URL: 'https://exekutivecoaching.com/api/',
  	COACHTOOLS: 'coachtools',
  	TOOL: 'tool',
  	COACHEE: 'coachee',
};

const dev = {
	API_BLOG_URL: 'https://exekutivecoaching.com/',
  	BLOG_URL: 'https://exekutivecoaching.com/',
  	API_URL: 'http://api.executivecoaching.local/api/',
  	COACHTOOLS: 'coachtools',
  	TOOL: 'tool',
  	COACHEE: 'coachee',
};

export const types = process.env.NODE_ENV === 'development' ? dev : prod;