import React from 'react';

import {
    Switch,
    Route
} from "react-router-dom";

import 'antd/dist/antd.css';

import './App.scss';

import Checkout from './containers/checkout';
import Dashboard from './containers/dashboard';
import Herramientas from './containers/herramientas';
import Home from './containers/home';
import Login from './containers/login';
import Logout from './containers/logout';
import MiPrograma from './containers/mi-programa';
import NotFound from './containers/not-found';
import Participantes from './containers/participantes';
import ProposalList from './containers/proposal/list';
import ProposalView from './containers/proposal/view';
import ProposalViewClient from './containers/proposal/view-client';
import ProposalConfirmClient from './containers/proposal/confirm-client';
import Resumen from './containers/resumen';
import Sesiones from './containers/sesiones';
import UIKit from './containers/uikit';
import CoacheeSelection from './containers/coachee/Selection';
import CoacheeDashboard from './containers/coachee/ManagementPanel';
import CoacheeProfile from './containers/coachee/profile';
import CoacheeList from "./containers/coachee/list";
import CoacheeEvaluation from "./containers/coachee/evaluation";
import CoachDashboard from "./containers/coach/ManagementPanel";
import CoachList from "./containers/coach/list";
import CoachToolList from "./containers/coach/tools";
import ClientList from "./containers/client/list";
import ClientInvoices from "./containers/client/client-invoices";
import ToolList from "./containers/tool/list";
import MiProgramaEdit from './containers/proposal/mi-programa';
import ParticipantesEdit from './containers/proposal/participantes';
import HerramientasEdit from './containers/proposal/herramientas';
import SesionesEdit from './containers/proposal/sesiones';
import ResumenEdit from './containers/proposal/resumen';
import Recovery from './containers/recovery';
import UserProfile from './containers/user-profile';

function App() {
    return (
      <Switch>
        /* Rutas para el panel de administracion */
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/:lang/login" component={Login} />
        <Route exact path="/cerrar-sesion" component={Logout} />
        <Route exact path="/solicitud/listado" component={ProposalList} />
        <Route exact path="/solicitud/mi-programa" component={MiProgramaEdit} />
        <Route exact path="/solicitud/participantes" component={ParticipantesEdit} />
        <Route exact path="/solicitud/herramientas" component={HerramientasEdit} />
        <Route exact path="/solicitud/sesiones" component={SesionesEdit} />
        <Route exact path="/solicitud/resumen" component={ResumenEdit} />
        <Route exact path="/solicitud/ver/:token" component={ProposalViewClient} />
        <Route exact path="/solicitud/confirmar/:token" component={ProposalConfirmClient} />
        <Route exact path="/:lang/solicitud/ver/:token" component={ProposalViewClient} />
        <Route exact path="/:lang/solicitud/confirmar/:token" component={ProposalConfirmClient} />
        <Route exact path="/solicitud/:id" component={ProposalView} />
        <Route exact path="/recuperar/:token" component={Recovery} />
        <Route exact path="/:lang/recuperar/:token" component={Recovery} />
        <Route exact path="/mi-cuenta" component={UserProfile} />

        /* Rutas para herramienta MMP */
        <Route exact path="/MMP" component={Home} />
        <Route exact path="/MMP/mi-programa" component={MiPrograma} />
        <Route exact path="/MMP/participantes" component={Participantes} />
        <Route exact path="/MMP/herramientas" component={Herramientas} />
        <Route exact path="/MMP/sesiones" component={Sesiones} />
        <Route exact path="/MMP/resumen" component={Resumen} />
        <Route exact path="/MMP/checkout" component={Checkout} />
        <Route exact path="/MMP/proposal/:id" component={ProposalView} />
        
        {/* Provisional routes */}
        <Route exact path="/coachee/selection" component={CoacheeSelection} />
        <Route exact path="/coachee/panel" component={CoacheeDashboard} />
        <Route exact path="/coachee/listado" component={CoacheeList} />
        <Route exact path="/coachee/evaluation" component={CoacheeEvaluation} />
        <Route exact path="/coachee/perfil/:id" component={CoacheeProfile} />
        <Route exact path="/coachee/evaluation/:proposal_id" component={CoacheeEvaluation} />
        <Route exact path="/:lang/coachee/evaluation/:proposal_id" component={CoacheeEvaluation} />
        <Route exact path="/coach/panel" component={CoachDashboard} />
        <Route exact path="/coach/listado" component={CoachList} />
        <Route exact path="/coach/:id" component={CoachToolList} />
        <Route exact path="/empresa/listado" component={ClientList} />
        <Route exact path="/empresa/facturas" component={ClientInvoices} />
        <Route exact path="/herramienta/listado" component={ToolList} />
        <Route exact path="/MMP/uikit" component={UIKit} />
        <Route exact path="/MMP/:lang" component={Home} />
        <Route exact path="/:lang" component={Dashboard} />

        /* Ruta 404 not found */
        <Route component={NotFound} />
        <Route onEnter={() => window.location.reload()} />
      </Switch>
    )
}

export default App;
