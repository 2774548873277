import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import moment from 'moment';

import { isLogged,
            getUserByEmail } from '../../actions/admin-users.js';
import { getProposals,
            editProposal,
            getCoaches,
            changeCoachStatus, } from '../../actions/app.js';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import { Modal, Button, Space, Switch } from 'antd';
import { EditOutlined, 
            EyeOutlined,
            CheckOutlined,
            CloseOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class CoachList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 0){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        const { t } = this.props;

        Promise.resolve(this.props.getCoaches())
        .then((response)=> {
            if(this.props.coaches){
                let dataSource = [];
                let Promises = [];

                this.props.coaches.map((coach, key) => {
                    let num = key + 1;
                    let id, nombre, email, image, city, country, active;

                    if(typeof coach.acf.email != 'undefined' && coach.acf.email != ''){
                        var formBody = [];
        
                        var details={ 
                            'email' : coach.acf.email
                        };

                        for (var property in details) {
                              var encodedKey = encodeURIComponent(property);
                              var encodedValue = encodeURIComponent(details[property]);
                              formBody.push(encodedKey + "=" + encodedValue);
                        }

                        var status = null;

                        Promises.push(fetch(types.API_URL + 'user/get-by-email', {
                            method: 'post',
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                            body: formBody.join("&"),
                        })
                        .then(function(response) {
                            status = response.status; // Get HTTP status code
                            return response.json();
                        })
                        .then((responseJson, response) => {
                            if(status === 200){ // If success
                                id = responseJson.user._id;
                                nombre = responseJson.user.first_name;
                                email = responseJson.user.email;
                                image = responseJson.user.picture;
                                city = responseJson.user.city;
                                country = responseJson.user.country;
                                active = responseJson.user.active;
                            }else{
                                nombre = coach.post_title;
                                email = coach.acf.email;
                                image = coach.image;
                                city = coach.acf.city;
                                country = coach.acf.country;
                                active = false;
                            }

                            var coachItem = {
                                id : id,
                                num : num,
                                nombre : nombre,
                                email : email,
                                image : image,
                                city : city,
                                country : country,
                                active : active
                            };

                            dataSource.push(coachItem);
                        })
                        .catch((error) => {
                            alert(error);
                        }));
                    }else{
                        nombre = coach.post_title;
                        email = t('No esta incluido en Wordpress');
                        image = coach.image;
                        city = coach.acf.city;
                        country = coach.acf.country;
                        active = false;

                        var coachItem = {
                            num : num,
                            nombre : nombre,
                            email : email,
                            image : image,
                            city : city,
                            country : country
                        };

                        dataSource.push(coachItem);
                    }
                })

                Promise.all(Promises).then(values => { 
                    dataSource.sort(function(a,b)
                    {
                        if(a.num < b.num) return -1;
                        if(a.num > b.num) return 1;
                        return 0;
                    });

                    this.setState({
                        dataSource : dataSource,
                        isLoaded : true
                    });
                }).catch(reason => { 
                      console.log(reason)
                }); 
            }
        });
    }

    _activateCoach(firstname, email, image, city, country, checked, event) {
        const { t } = this.props;

        event.preventDefault();

        if(typeof email == 'undefined' || email == ''){
            Modal.error({
                title: t('No se puede activar este Coach'),
                content: (t('Este Coach no tiene asignado un email en su perfil de Wordpress') + '. ' + t('Si no le asignas un email no se puede activar'))
            });
        }else{
            Promise.resolve(this.props.changeCoachStatus(firstname, email, image, city, country, checked))
            .then((response)=> {
                this._getDataFromAPI();
            });
        }
    }

    _renderList() {
        const { t } = this.props;

        const { dataSource } = this.state;

        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: "",
                dataIndex: "num",
                key: "num",
            },
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
            },
            {
                title: t('Ciudad'),
                dataIndex: "city",
                key: "city",
            },
            {
                title: t('País'),
                dataIndex: "country",
                key: "country",
            },
            {
                title: t('Activado'),
                key: "activado",
                width: "125px",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={ record.active }
                            onClick={ this._activateCoach.bind(this, record.nombre, record.email, record.image, record.city, record.country) }
                        />
                    </React.Fragment>
                ),
            },
            {
                title: "",
                key: "herramientas",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        { record.active ? (
                            <a href={'/coach/' + record.id} className={'mx-1'} title={t('Ver herramientas')}>
                                <EyeOutlined />
                            </a>
                        ):(<p></p>)}
                    </React.Fragment>
                ),
            }
        ]

        return (
            <React.Fragment>
                
                <p className={'mb-4'}>
                    {t('Tenemos')}{" "}
                    <span className={"orange"}>{ dataSource.length } {t('coaches disponibles')}</span>
                </p>
                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return(
            <div className={"split-container"}>
                <Menu history={this.props.history} />
                <div className={"container coachee"}>
                    <div className={"row"}>
                        <div className={"col-12 pt-3"}>
                            <header className={"managementPanel__content-header"}>
                                <div>
                                    <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                    <p className={"coachee__title"}>{t('Listado de coaches')}</p>
                                </div>
                            </header>

                            <section>
                                { 
                                    this.state.isLoaded ? 
                                        this._renderList()
                                    :
                                        this._renderLoader()

                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coaches: storeApp.coaches,
        proposal: storeApp.proposal
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getCoaches: () => dispatch(getCoaches()),
        editProposal: (proposal_id) => dispatch(editProposal(proposal_id)),
        changeCoachStatus: (firstname, email, picture, city, country, newStatus) => dispatch(changeCoachStatus(firstname, email, picture, city, country, newStatus)),
        getUserByEmail: (email) => dispatch(getUserByEmail(email)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoachList));
