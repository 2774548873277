import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import moment from 'moment';

import { isLogged,
            getUserByEmail,
            getUserCompanies } from '../../actions/admin-users.js';
import { deleteCompany } from '../../actions/app.js';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import { Modal, Button, Space, Switch } from 'antd';
import { CheckOutlined,
            CloseOutlined,
            DeleteOutlined,
            ExclamationCircleOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class ClientList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            dataSource : []
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 0){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        Promise.resolve(this.props.getUserCompanies())
        .then((response)=> {
            if(this.props.dataError == false){
                let dataSource = [];

                Promise.all(this.props.user_companies.map((company, key) =>{
                    dataSource.push({
                        id : company._id,
                        num : (key + 1),
                        nombre : company.first_name,
                        email : company.email,
                        city : company.city,
                        country : company.country
                    })
                }))
                .then((result)=>{
                    this.setState({ dataSource : dataSource });
                })
            }

            this.setState({ isLoaded : true });
        });
    }

    _deleteCompany(id){
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres borrar esta empresa?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas perderá todas sus facturas y no podrán acceder a la plataforma'),
            okText: t('Borrar'),
            cancelText: t('Cancelar'),
            onOk : () => {
                Promise.resolve(this.props.deleteCompany(id))
                .then((response)=> {
                    if(this.props.dataError == false){
                        this.setState({
                            'isLoaded' : false
                        });

                        this._getDataFromAPI();
                    }else{
                        Modal.error({
                            title: t('Ha ocurrido un error'),
                            content: this.props.dataErrorMessage.message
                        });
                    }
                });
            },
            onCancel() {
                
            },
        });
    }

    _renderList() {
        const { t } = this.props;
        const dataSource = this.state.dataSource;

        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: "",
                dataIndex: "num",
                key: "num",
            },
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
            },
            {
                title: t('Ciudad'),
                dataIndex: "city",
                key: "city",
            },
            {
                title: t('País'),
                dataIndex: "country",
                key: "country",
            },
            {
                title: "",
                key: "acciones",
                width: "125px",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a onClick={ this._deleteCompany.bind(this, record.id) } className={'mx-1'} title={'Eliminar'}>
                            <DeleteOutlined />
                        </a>
                    </React.Fragment>
                ),
            },
        ]

        return (
            <React.Fragment>
                
                <p className={'mb-4'}>
                    {t('Tenemos')}{" "}
                    <span className={"orange"}>{ dataSource.length } {t('perfiles de empresa disponibles')}</span>
                </p>
                <CustomTable
                    columns={columns}
                    dataSource={dataSource}
                    t={t}
                />
                                
            </React.Fragment>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        return(
            <div className={"split-container"}>
                <Menu history={this.props.history} />
                <div className={"container coachee"}>
                    <div className={"row"}>
                        <div className={"col-12 pt-3"}>
                            <header className={"managementPanel__content-header"}>
                                <div>
                                    <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
                                    <p className={"coachee__title"}>{t('Listado de perfiles de empresa')}</p>
                                </div>
                            </header>

                            <section>
                                { 
                                    this.state.isLoaded ? 
                                        this._renderList()
                                    :
                                        this._renderLoader()

                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        user_companies: storeAdminUsers.user_companies,
        proposal: storeApp.proposal,
        dataError: storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getUserCompanies: () => dispatch(getUserCompanies()),
        deleteCompany: (id) => dispatch(deleteCompany(id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClientList));
