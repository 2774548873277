import React from "react"

const Account = (props) => {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g style={{ fill: props.active ? '#FF866B' : '#C6C6C6'}} fillRule="nonzero">
              <path d="M11,10.4162595 C8.24876256,10.4162595 6.02914453,8.12959413 6.02914453,5.32162181 C6.02914453,2.51364949 8.24876256,0.22698413 11,0.22698413 C13.7512374,0.22698413 15.9708555,2.51364949 15.9708555,5.32162181 C15.9708555,8.12959413 13.7512374,10.4162595 11,10.4162595 Z M10.9999999,8.67022745 C12.775111,8.67022745 14.2248234,7.17672396 14.2248234,5.32162168 C14.2248234,3.4665194 12.775111,1.9730159 10.9999999,1.9730159 C9.22488876,1.9730159 7.7751763,3.4665194 7.7751763,5.32162168 C7.7751763,7.17672396 9.22488876,8.67022745 10.9999999,8.67022745 Z M10.6296601,11.4932166 L14.4052469,11.4932162 L14.544831,11.5051103 C18.3749252,12.1626143 21.1742618,15.5661706 21.175,19.546108 L21.175,20.8999998 C21.175,21.3556347 20.8056349,21.7249998 20.35,21.7249998 L1.65,21.7249998 C1.19436508,21.7249998 0.825,21.3556347 0.825,20.8999998 L0.825000008,19.5459904 C0.825566167,15.5744426 3.61346129,12.1753011 7.43385292,11.5084762 L7.57490388,11.4961896 L10.6296601,11.4932166 Z M7.64980822,13.1461175 C4.66228615,13.7019605 2.47544966,16.3918754 2.475,19.5461079 L2.475,20.0749998 L19.525,20.0749998 L19.525,19.546261 C19.5244137,16.3850974 17.3282418,13.6915298 14.3327284,13.1432162 L10.6308645,13.1432162 L7.64980822,13.1461175 Z" id="Combined-Shape"></path>
          </g>
      </g>
    </svg>
  )
}

export default Account;