import React from "react"
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import moment from 'moment';

import { types } from '../../constants/constants';

import Menu from "../../components/menu"
import CustomTable from '../../components/table';
import Download from "../../components/icons/download";

import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import ExcelIcon from '../../assets/img/file-icons/doc-excel.svg';
import WordIcon from '../../assets/img/file-icons/doc-word.svg';
import DownloadIcon from "../../assets/img/icon-download.svg";

import { isLogged,
            getUser,
            getCoachee } from '../../actions/admin-users.js';

import { deleteCoacheeTool } from '../../actions/app.js';            

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';


import { ExclamationCircleOutlined,
            DeleteOutlined } from '@ant-design/icons';

import { Modal, Button, Space, Avatar } from 'antd';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class CoacheeProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            coach_id : null
        };
    }

    componentDidMount(){

        Promise.resolve(this.props.isLogged())
        .then((response)=> {

            if(this.props.isLoggedUser && (this.props.user.role.code === 1 || this.props.user.role.code === 0)){

                this.setState({ coach_id : this.props.user._id });
        
                this._getDataFromAPI();

            } else {

                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')

            }

        });

    }

    _getDataFromAPI(){
        
        // Obtener toda la info del coachee
        let coachee_id = typeof this.props.match.params.id != 'undefined' ? this.props.match.params.id : null;

        Promise.resolve(this.props.getCoachee(coachee_id))
        .then((response)=> {
            this.setState({
                isLoaded : true,
            });
        })
    }

    _deleteCoacheeTool(tool_id){
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres retirar esta herramienta al Coachee'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la retiras ya no podrá acceder a ella.'),
            okText: t('Retirar'),
            cancelText: t('Cancelar'),
            onOk : () => {

                Promise.resolve(this.props.deleteCoacheeTool(tool_id, this.state.coach_id, this.props.coachee._id))
                .then((response)=> {
                    this._getDataFromAPI();
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderTools() {
        const { t } = this.props;

        const dataSource = [];

        this.props.coachee.proposals.map((proposal, key) =>{
            if(proposal.closed == false){
                proposal.tools.map((tool, key) =>{
                    let type = '';

                    if(tool.tool.type.indexOf('pdf') != -1) { type = PDFIcon }
                    if(tool.tool.type.indexOf('presentation') != -1) { type = WordIcon }
                    
                    dataSource.push({
                        key: key,
                        nombre: tool.tool.name,
                        tipo: type,
                        fecha: moment(tool.date).format('DD-MM-YYYY'),
                        id: tool.tool._id,
                    })
                })
            }
        })

        const columns = [
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
                className: "bold",
            },
            {
                title: t('Tipo'),
                dataIndex: "tipo",
                key: "tipo",
                align: "center",
                render: (text, record) => <img src={record.tipo} />,
            },
            {
                title: t('Fecha'),
                dataIndex: "fecha",
                key: "fecha",
            },
            {
                title: "",
                key: "acciones",
                width: "115px",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={record.id}>
                            <img src={ DownloadIcon } />
                        </a>

                        <a onClick={this._deleteCoacheeTool.bind(this, record.id)}>
                            <DeleteOutlined style={{ marginLeft: '0.7em' }} />
                        </a>
                    </React.Fragment>
                ),
            },
        ]

        return(
            <React.Fragment>
                <div className={"row"}>
                    <div className={"col-12 pt-5"}>
                        <header className={"managementPanel__content-header"}>
                        
                            <div>
                            
                                <h1 className={"managementPanel__title uppercase"}>
                                    { this.props.coachee.first_name } { this.props.coachee.last_name }
                                </h1>
                                
                                <p className={"managementPanel__title"}>
                                    {t('Información sobre tu coachee')}
                                </p>

                            </div>

                            <div className={"coachee__download-instructions"}>
                                <a href={this.props.coachee.profile_file} className="btn mr-3 text-decoration-none">
                                    {t('Perfil de tu coachee')}{" "}
                                    <i className={"btn__icon-right"}>
                                        <Download active />
                                    </i>
                                </a>
                                
                                <a href={this.props.coachee.dashboard_file} className="btn text-decoration-none">
                                    {t('Cuadro de mando')}{" "}
                                    <i className={"btn__icon-right"}>
                                        <Download active />
                                    </i>
                                </a>
                            </div>

                        </header>
                    
                        <section>    
                            <p>
                              {t('Ejercicios y herramientas de trabajo que has despositado en su buzón')}
                            </p>
                            
                            <CustomTable columns={ columns } dataSource={ dataSource } t={t} />
                        </section>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className={"split-container"}>
                    <Menu />
                    
                    <div className={"container coachee"}>
                        { 
                            this.state.isLoaded ? 
                                this._renderTools()
                            :
                                this._renderLoader()
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coachee: storeAdminUsers.coachee,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: () => dispatch(isLogged()),
        getCoachee: (id) => dispatch(getCoachee(id)),
        deleteCoacheeTool: (tool_id, coach_id, coachee_id) => dispatch(deleteCoacheeTool(tool_id, coach_id, coachee_id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CoacheeProfile));
