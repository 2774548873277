import React from "react";
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import Menu from "../../components/menu";
import CustomTable from '../../components/table';
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import DownloadIcon from "../../assets/img/icon-download.svg";

import { isLogged } from '../../actions/admin-users.js';
import { getClientInvoices } from '../../actions/app.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class ClientInvoices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            dataSource : []
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        Promise.resolve(this.props.getClientInvoices())
        .then((response)=> {
            this.setState({ isLoaded : true });
        });
    }

    _renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    _renderList() {
        const { t } = this.props;

        const dataSource = this.props.invoices;
        const columns = [
            {
                title: "Num",
                dataIndex: "key",
                key: "key",
                align: "center",
            },
            {
                title: t('Nombre'),
                dataIndex: "proposal",
                key: "proposal",
                className: "bold",
            },
            {
                title: t('Tipo'),
                dataIndex: "tipo",
                key: "tipo",
                align: "center",
                render: (text, record) => <img src={PDFIcon} />,
            },
            {
                title: "",
                key: "acciones",
                width: "115px",
                align: "center",
                render: (text, record) => (
                    <a href={record.url} target="_blank">
                        <img src={DownloadIcon} />
                    </a>
                ),
            }
        ]
        return (
            <React.Fragment>
                <p>
                    {t('Tenemos')}{" "}
                    <span className={"orange"}>{ dataSource.length} {t('archivos disponibles')}</span>
                </p>
                
                <CustomTable columns={columns} dataSource={dataSource} t={t} />
            </React.Fragment>
        )
    }
    
    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className={"split-container"}>
                  
                    <Menu />
                    
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12 pt-5"}>
                                <header className={"managementPanel__content-header"}>  
                                    <div>
                                        <h1 className={"managementPanel__title"}>
                                            EXEKUTIVE COACHING:
                                        </h1>
                                    
                                        <p className={"managementPanel__title"}>{t('Panel de gestión')}</p>
                                    </div>
                                    <div className={"coachee__download-instructions"}>
                                        {/*<p>¿Tienes alguna duda?</p>
                                        <a href="" className={"orange"}>
                                            Descarga las instrucciones
                                        </a>*/}
                                    </div>
                                </header>
                                
                                <section className={'overflow-auto'}>
                                    { 
                                        this.state.isLoaded ? 
                                            this._renderList()
                                        :
                                            this._renderLoader()

                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        invoices: storeApp.invoices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getClientInvoices: () => dispatch(getClientInvoices())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClientInvoices));