import React from "react";
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import { types } from '../../constants/constants';
import Menu from "../../components/menu";
import CustomTable from '../../components/table';
import PDFIcon from '../../assets/img/file-icons/doc-pdf.svg';
import DownloadIcon from "../../assets/img/icon-download.svg";

import { UploadOutlined,
            DeleteOutlined,
            ExclamationCircleOutlined } from '@ant-design/icons';

import { isLogged } from '../../actions/admin-users.js';
import { getCoachTools,
            createTool,
            deleteTool } from '../../actions/app.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { Modal, Button, Space, Avatar } from 'antd';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class ToolList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            dataSource : []
        };
    }

    componentDidMount() {

        Promise.resolve(this.props.isLogged())
        .then((response)=> {
            if(this.props.isLoggedUser && this.props.user.role.code === 0){

                this._getDataFromAPI();

            }else{
                sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')
            }
        });

    }

    _getDataFromAPI() {
        Promise.resolve(this.props.getCoachTools())
        .then((response)=> {
            let dataSource = [];

            this.props.coachTools.map((tool, key) => {
                dataSource.push({
                    num : (key + 1),
                    key : key,
                    id : tool._id,
                    nombre : tool.name,
                    tipo : tool.type,
                    url : tool.url,
                    path : tool.path
                });
            })

            this.setState({
                dataSource : dataSource,
                isLoaded : true
            });
        });
    }

    _uploadTools=event=>{
        const { t } = this.props;

        Array.from(event.target.files).map((file, key) => {
            var data = new FormData()
            data.append('folder', 'tools')
            data.append('file', file)        

            fetch(types.API_URL + 'upload', {
                  method: 'POST',
                  body: data
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                           alert(t('Error subiendo el fichero'))
                    }
                }
            })
            .then((responseJson) => {
                Promise.resolve(this.props.createTool(responseJson, file.name, file.type))
                .then((response)=> {
                    if(this.props.dataError === false){
                        Modal.info({
                            title: t('Fichero guardado'),
                            content: t('Se ha subido la herramienta') + ': ' + file.name
                        });

                        this._getDataFromAPI();
                    }else{
                        Modal.error({
                            title: t('Ha ocurrido un error'),
                            content: 'Fichero: ' + file.name + '. Error: ' + this.props.dataErrorMessage
                        });
                    }

                });
            })
            .catch((error) => {
                alert(error)
            });
        });
    }

    _deleteTool(tool_id, tool_path) {
        const { t } = this.props;

        confirm({
            title: t('¿Seguro que quieres borrar esta herramienta?'),
            icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
            content: t('Si la eliminas dejará de estar disponible para todos los usuarios'),
            okText: t('Borrar'),
            cancelText: t('Cancelar'),
            onOk : () => {

                var formBody = [];
            
                var details={
                    'file' : tool_path,
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }    

                const token = localStorage.getItem('token');

                fetch(types.API_URL + 'delete-file', {
                      method: 'POST',
                      headers: { 
                          "Content-Type": "application/x-www-form-urlencoded",
                        'token': token 
                    },
                      body: formBody.join("&")
                })
                .then(function(response) {
                    if (response.status === 404 || response.status === 200) {
                        return response.json()
                    } else {
                        if(response.status === 401){  // If unauthorized
                            window.location.href = '/login'; 
                        }else{
                               alert(t('Error subiendo el fichero'))
                        }
                    }
                })
                .then((responseJson) => {
                    Promise.resolve(this.props.deleteTool(tool_id))
                    .then((response)=> {
                        if(this.props.dataError === false){
                            Modal.info({
                                title: t('Herramienta eliminada'),
                                content: (t('Se ha borrado la herramienta de forma permanente') + '. ' + t('Ya no está accesible'))
                            });

                            this._getDataFromAPI();
                        }else{
                            Modal.error({
                                title: t('Ha ocurrido un error'),
                                content: 'Error: ' + this.props.dataErrorMessage
                            });
                        }

                    });
                })
                .catch((error) => {
                    alert(error)
                });
                
            },
            onCancel() {
                
            },
        });
    }

    _renderLoader() {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    _renderList() {
        const { t } = this.props;

        const { dataSource } = this.state;
        const columns = [
            {
                title: "",
                dataIndex: "num",
                key: "num",
                align: "center",
            },
            {
                title: t('Nombre'),
                dataIndex: "nombre",
                key: "nombre",
                className: "bold",
            },
            {
                title: t('Tipo'),
                dataIndex: "tipo",
                key: "tipo",
                align: "center",
                render: (text, record) => <img src={PDFIcon} />,
            },
            {
                title: "",
                key: "acciones",
                width: "115px",
                align: "center",
                render: (text, record) => (
                    <React.Fragment>
                        <a href={record.url} target="_blank">
                            <img src={DownloadIcon} />
                        </a>

                        <a href="#" onClick={ this._deleteTool.bind(this, record.id, record.path) }>
                            <DeleteOutlined style={{ marginLeft: '0.5em' }} />
                        </a>
                    </React.Fragment>
                ),
            }
        ]
        return (
            <React.Fragment>
                <p>
                    {t('Tenemos')}{" "}
                    <span className={"orange"}>{ dataSource.length} {t('archivos disponibles')}</span>
                </p>

                <input type="file" multiple="multiple" ref={input => this.tool = input} className={'d-none'} onChange={this._uploadTools.bind(this)}/>
                <button 
                    className="btn fs-08 d-flex align-items-center justify-content-center mb-3"
                    onClick={ ()=>{this.tool.click()} }>
                      {t('Añadir herramientas')}{" "}
                      <UploadOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
                </button>
                
                <CustomTable columns={columns} dataSource={dataSource} t={t} />
            </React.Fragment>
        )
    }
    
    render() {
        const { t } = this.props;
        
        return (
            <React.Fragment>
                <div className={"split-container"}>
                  
                    <Menu />
                    
                    <div className={"container coachee"}>
                        <div className={"row"}>
                            <div className={"col-12 pt-5"}>
                                <header className={"managementPanel__content-header"}>  
                                    <div>
                                        <h1 className={"managementPanel__title"}>
                                            EXEKUTIVE COACHING:
                                        </h1>
                                    
                                        <p className={"managementPanel__title"}>{t('Panel de gestión')}</p>
                                    </div>
                                    <div className={"coachee__download-instructions"}>
                                        {/*<p>¿Tienes alguna duda?</p>
                                        <a href="" className={"orange"}>
                                            Descarga las instrucciones
                                        </a>*/}
                                    </div>
                                </header>
                                
                                <section className={'overflow-auto'}>
                                    { 
                                        this.state.isLoaded ? 
                                            this._renderList()
                                        :
                                            this._renderLoader()

                                    }
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coachTools: storeApp.coachTools,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged()),
        getCoachTools: () => dispatch(getCoachTools()),
        createTool: (tool, name, type) => dispatch(createTool(tool, name, type)),
        deleteTool: (tool_id) => dispatch(deleteTool(tool_id))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ToolList));