import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { isLogged } from '../actions/admin-users.js';

import logo from '../assets/img/logo-black.svg'
import logoMobile from '../assets/img/logo-mini.svg'
import menuIcon from '../assets/img/icon-menu.svg'
import Gear from '../components/icons/gear'
import Account from "../components/icons/account"
import Info from "../components/icons/info"
import Exit from "../components/icons/exit"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class Menu extends React.Component {
    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)

        this.state = {
            isLoaded: false,
            menuItems: [],
            mobileMenu: false,
        }
    }

    componentDidMount() {
        const { t, i18n } = this.props;
        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') != -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') != -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') != -1){ i18n.changeLanguage('es') }

        const contactURL = i18n.language == 'en' ? 'https://exekutivecoaching.com/en/contact' : 'https://exekutivecoaching.com/contacto';

        Promise.resolve(this.props.isLogged()).then((response) => {
          if (this.props.isLoggedUser == true) {
            if (this.props.user.role.code === 0) { // Admin
              // User is admin
              this.setState({
                menuItems: [
                  {
                    title: t('Panel de control'),
                    icon: () => <Gear active={ this._isCurrentLink("/") } />,
                    link: "/",
                    isActive: this._isCurrentLink("/"),
                    options: [
                        {
                          title: t('Gestión solicitudes MMP'),
                          link: "/solicitud/listado",
                          isActive: this._isCurrentLink("/solicitud/"),
                        },
                        {
                          title: t('Gestión de Coaches'),
                          link: "/coach/listado",
                          isActive: this._isCurrentLink("/coach/"),
                        },
                        {
                          title: t('Gestión de Coachees'),
                          link: "/coachee/listado",
                          isActive: this._isCurrentLink("/coachee/listado"),
                        },
                        {
                          title: t('Gestión de Empresas'),
                          link: "/empresa/listado",
                          isActive: this._isCurrentLink("/empresa/listado"),
                        },
                        {
                          title: t('Gestión de Herramientas'),
                          link: "/herramienta/listado",
                          isActive: this._isCurrentLink("/herramienta/listado"),
                        }
                    ],
                  },
                  {
                    title: t('Mi cuenta'),
                    icon: () => <Account active={ this._isCurrentLink("/mi-cuenta") } />,
                    link: "/mi-cuenta",
                    isActive: this._isCurrentLink("/mi-cuenta"),
                  },
                  {
                    title: t('Contacto'),
                    icon: () => <Info />,
                    link: contactURL,
                    isActive: false,
                  },
                  {
                    title: t('Cerrar sesión'),
                    icon: () => <Exit />,
                    link: "/cerrar-sesion",
                    isActive: false,
                  },
                ],
              })
            } else if (this.props.user.role.code === 1){ // Coach
              this.setState({
                menuItems: [
                  {
                    title: t('Panel de control'),
                    icon: () => <Gear active={ this._isCurrentLink("/coach/panel") } />,
                    link: "/",
                    isActive: this._isCurrentLink("/coach/panel"),
                  },
                  {
                    title: t('Mi cuenta'),
                    icon: () => <Account active={ this._isCurrentLink("/mi-cuenta") } />,
                    link: "/mi-cuenta",
                    isActive: this._isCurrentLink("/mi-cuenta"),
                  },
                  {
                    title: t('Contacto'),
                    icon: () => <Info />,
                    link: contactURL,
                    isActive: false,
                  },
                  {
                    title: t('Cerrar sesión'),
                    icon: () => <Exit />,
                    link: "/cerrar-sesion",
                    isActive: false,
                  },
                ],
              })
            } else if (this.props.user.role.code === 2){ // Coachee
              this.setState({
                menuItems: [
                  {
                    title: t('Panel de control'),
                    icon: () => <Gear active={ this._isCurrentLink("/coachee/panel") } />,
                    link: "/",
                    isActive: this._isCurrentLink("/coachee/panel"),
                  },
                  {
                    title: t('Mi cuenta'),
                    icon: () => <Account active={ this._isCurrentLink("/mi-cuenta") } />,
                    link: "/mi-cuenta",
                    isActive: this._isCurrentLink("/mi-cuenta"),
                  },
                  {
                    title: t('Contacto'),
                    icon: () => <Info />,
                    link: contactURL,
                    isActive: false,
                  },
                  {
                    title: t('Cerrar sesión'),
                    icon: () => <Exit />,
                    link: "/cerrar-sesion",
                    isActive: false,
                  },
                ],
              })
            } else if (this.props.user.role.code === 3){ // Company
              this.setState({
                menuItems: [
                  {
                    title: t('Panel de control'),
                    icon: () => <Gear active={true} />,
                    link: "/empresa/facturas",
                    isActive: this._isCurrentLink("/empresa/facturas"),
                  },
                  {
                    title: t('Mi cuenta'),
                    icon: () => <Account active={ this._isCurrentLink("/mi-cuenta") } />,
                    link: "/mi-cuenta",
                    isActive: this._isCurrentLink("/mi-cuenta"),
                  },
                  {
                    title: t('Contacto'),
                    icon: () => <Info />,
                    link: contactURL,
                    isActive: false,
                  },
                  {
                    title: t('Cerrar sesión'),
                    icon: () => <Exit />,
                    link: "/cerrar-sesion",
                    isActive: false,
                  },
                ],
              })
            }

            this.setState({ isLoaded: true })
          }
        })
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.setState({ 'mobileMenu': false })
        }
    }

    _isCurrentLink(path) {
        if (window.location.pathname === path) {
          return true
        }

        if (path != '/' && path.length > 0 && window.location.pathname.indexOf(path) != -1) {
          return true
        }

        return false
    }

    _renderMenuItems() {
        const { menuItems } = this.state
        const { t, i18n } = this.props
        const current_lang = i18n.language

        return (
          <React.Fragment>
            {/* Greetings */}
            <div className={"row pt-5 mb-10 menu-greetings"}>
              <div className={"col-12"}>
                <p className={"name"}>{t('Hola')} {this.props.user.first_name},</p>
                <p>
                  {t('Este es tu espacio personal en la plataforma de EXEKUTIVE Coaching')}
                </p>
              </div>
            </div>

            {/* Menu */}
            <div className={"row menu-items"}>
              <div className={"col-lg-12"}>
                <ul>
                  {menuItems
                    ? menuItems.map((menuItem, key) => {
                        return (
                          <React.Fragment key={key}>
                            <li
                              className={menuItem.isActive ? "active" : ""}
                              key={key}
                            >
                              <a href={menuItem.link}>
                                <menuItem.icon />
                                {menuItem.title}
                              </a>
                            </li>

                            {menuItem.options &&
                              menuItem.options.map((option, key) => {
                                return (
                                  <p className={"fs-08 ml-5"} key={key}>
                                    <a
                                      href={option.link}
                                      className={option.isActive ? "orange" : ""}
                                    >
                                      {option.title}
                                    </a>
                                  </p>
                                )
                              })}
                          </React.Fragment>
                        )
                      })
                    : null}

                    <li className={'mt-5'}>
                        <a 
                            onClick={() => { i18n.changeLanguage('es'); window.location.reload()}} 
                            className={(current_lang === "es") ? "text-dark mr-3" : "mr-3"}
                        >
                            ES
                        </a>

                        <a 
                            onClick={() => { i18n.changeLanguage('en'); window.location.reload()}}
                            className={(current_lang === "en") ? "text-dark mr-3" : "mr-3"}
                        >
                            EN
                        </a>
                    </li>
                </ul>
              </div>
            </div>

            {/* Menu Footer */}

            <div className={"row pt-5 mb-10 menu-footer"}>
              <div className={"col-12"}>
                <p className={"name"}>
                  {this.props.user.first_name}{this.props.user.company ? ' - ' + this.props.user.company.name : ''}
                </p>
                <p>{this.props.user.email}</p>
              </div>
            </div>
          </React.Fragment>
        )
    }

    _renderLoader() {
        return (
          <div className={"row pt-5 mb-10"}>
            <div className={"col-12 text-center"}>
              <Loader type="ThreeDots" color="#FF866B" height={60} width={60} />
            </div>
          </div>
        )
    }

    render() {
        const { menuItems } = this.state

        return (
          <React.Fragment>
            <div className={`menu-container ${this.state.mobileMenu ? "active" : ""}`}>
              <div className={"menu-mobile"}>
                <img
                  src={menuIcon}
                  className={"menu-icon"}
                  alt="menu icon"
                  onClick={() => {
                    this.setState({ mobileMenu: !this.state.mobileMenu })
                  }}
                />
                <img
                  src={logoMobile}
                  className={"logo-mobile"}
                  alt="Logo Exekutive Coaching"
                />
              </div>
              <div
                ref={this.wrapperRef}
                className={`menu-content ${this.state.mobileMenu ? "active" : ""}`}
              >
                {/* Logo */}
                <a href="/">
                  <img
                    src={logo}
                    className={"logo"}
                    alt="Logo Exekutive Coaching"
                  />
                </a>

                <div className={"row mb-md-5"}>
                  <div className={"col-12"}>
                    <p className={"logo-text"}>The mobile coaching company</p>
                  </div>
                </div>

                {this.state.isLoaded
                  ? this._renderMenuItems()
                  : this._renderLoader()}
              </div>
            </div>
          </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
        coach: storeApp.coach,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        isLogged: redirectTo => dispatch(isLogged())
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Menu));



