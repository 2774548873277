
import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import Header from '../../components/header';
import Steps from '../../components/steps';
import InputNumber from '../../components/input-number';
import TestimonialsMMP from '../../components/testimonials-mmp';

import {inputFieldChanged, 
		saveProgram,
		getSessions } from '../../actions/app.js';

import { QuestionCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';

import { withTranslation } from 'react-i18next';

class SesionesEdit extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
        	sesiones: [
        	]
        };
    }

    componentDidMount(){
    	let proposal_language = 'es';
    	if(JSON.stringify(this.props.proposal.programs).indexOf("Tool Devolution") != -1){
    		proposal_language = 'en';
    	}

    	// Obtener las Sessions
    	Promise.resolve(this.props.getSessions(proposal_language))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            let sesiones = [];

			    /* Si el array de sesiones en el state esta vacio, las cargamos desde
				** la API de Wordpress, sino cargamos las que hay en redux */ 
		    	if (typeof this.props.sesiones === 'undefined' || this.props.sesiones === null || this.props.sesiones.length === 0) {  
					this.props.sessions.map((session, key) => {
		            	sesiones.push({
							'sesion' : session.title,
							'cantidadPorCoachee' : 0,
							'descripcion' : session.description
						});
		        	})

		        	this.setState({
			            sesiones: sesiones
			        })

			        this.props.inputFieldChanged('sesiones', sesiones);
				}else{ 
					this.setState({
			            sesiones: this.props.sesiones
			        })
				}
	        }
	    });
    }

    _handleInputSessionChange(key, field, value) {
  		const {sesiones} = (this.props.sesiones == 'undefined' || this.props.sesiones == null) ? this.state : this.props;

  		sesiones[key].sesion = field;
  		sesiones[key].cantidadPorCoachee = value;

	  	this.setState({
            sesiones: sesiones
        });

        this.props.inputFieldChanged('sesiones', sesiones);
	}

	_saveData(){
    	const {programName, coacheesNumber, coachCategory, sesiones, herramientas, programs, isEditing, editingKey} = this.props;
    	
    	Promise.resolve(this.props.saveProgram(programName, coacheesNumber, coachCategory, sesiones, herramientas, programs, isEditing, editingKey))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.props.history.push('/solicitud/resumen');
	        }
	    });
    }

    render() {
    	const { t } = this.props;
    	
		const {sesiones} = typeof this.props.sesiones == 'undefined' || this.props.sesiones === null ? this.state : this.props; 
		const herramientasNumber = typeof this.props.herramientas == 'undefined' || this.props.herramientas === null ? 0 : this.props.herramientas.length; 

        return (
       		<React.Fragment>
			    <TestimonialsMMP proposal={this.props.proposal} />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<Steps 
			      				currentStep={"4"}
			      				history={this.props.history} />

			      			<div className={'row pt-3 mb-5'}>
			      				<div className={'col-12 text-left mb-3'}>
			      					<p className={'bold'}>{t('Dale forma a tu programa')}</p>
			      				</div>

			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>
			      						<h2 className={'dark-gray mb-5'}>
			      							{t('Mis sesiones (por Coachee)')}
			      						</h2>

			      						{ sesiones != null ?  
				      						sesiones.map((sesion, key) => {

				      							let descripcion = '';

												if(
													(sesion.sesion.indexOf('Devoluci') != -1 && sesion.sesion.indexOf('Herram') != -1) || 
													(sesion.sesion.indexOf('Tool Devolution') != -1)
												){
													descripcion = (
														<React.Fragment>
															<p>{sesion.descripcion}</p>
															<p><b>{t('No necesitas seleccionar este tipo de sesiones si has seleccionado previamente Herramientas y su número por coachee, porque ya están incluidas')}.</b></p>
														</React.Fragment>
													);
												}else{
													descripcion = (<p>{sesion.descripcion}</p>);
												}

				      							let disabled = (sesion.sesion.indexOf('Devoluci') != -1 && sesion.sesion.indexOf('Herram') != -1) || (sesion.sesion.indexOf('Tool Devolution') != -1) ? true : false;
				      							let readonly = disabled;
				      							let numDevoluciones = 0;

				      							if(disabled === true && typeof this.props.herramientas != 'undefined'){
				      								numDevoluciones = this.props.herramientas.length;
				      							}

							                    return(

							                    	<div className={'row my-4 ' + (disabled === true && herramientasNumber == 0 ? 'opacity-05' : 'opacity-1')} key={key}>
						      							<div className={'col-2 col-md-1 my-auto'}>
						      								<Popover placement="left" content={descripcion} trigger="hover">
														        <QuestionCircleFilled style={{ color: '#FF866B', fontSize: '1.1em' }} />
														    </Popover>
						      							</div>

						      							<div className={'col-5 col-md-8 my-auto'}>
						      								<p className={'mb-0'}>
						      									{ sesion.sesion }
						      								</p>
						      							</div>
										        		
										        		<div className={'col-5 col-md-3 my-auto'}>
															<InputNumber 
										        				id={sesion.sesion}
										        				name={sesion.sesion}
										        				placeholder={'0'}
										        				value={disabled === false ? sesion.cantidadPorCoachee : numDevoluciones}
										        				disabled={disabled}
										        				readonly={readonly}
										        				onChange={this._handleInputSessionChange.bind(this, key)} />
										        		</div>
										        	</div>
										        );
							                })
				      					: null}
				      					

		      							{ typeof this.props.dataErrorMessage !== 'undefined' ?

		      								Object.keys(this.props.dataErrorMessage).map((error, key) => {

							                    return(
							                    	<div className='row mt-4'>
		      											<div className="col-12">
							                    			<p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage[error] }</p>
							                    		</div>
		      										</div>
										        );
							                
							                })

		      							: null }
				      						
			      					</div>
			      				</div>

			      				<div className={'col-6 offset-6 col-md-4 offset-md-8 text-right'}>
			      					<a className={'btn-submit mt-4 w-100'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Finalizar programa')}</span></a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
    	proposal : storeApp.proposal,
    	programName : storeApp.programName,
    	coacheesNumber : storeApp.coacheesNumber,
    	coachCategory : storeApp.coachCategory,
        sessions : storeApp.sessions,
        sesiones : storeApp.sesiones,
        herramientas : storeApp.herramientas,
        programs : storeApp.programs,
        isEditing : storeApp.isEditing,
        editingKey : storeApp.editingKey,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getSessions: () => dispatch(getSessions()),
        saveProgram: (programName, coacheesNumber, coachCategory, sesiones, herramientas, programs, isEditing, editingKey) => dispatch(saveProgram(programName, coacheesNumber, coachCategory, sesiones, herramientas, programs, isEditing, editingKey))
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SesionesEdit));
