export const $$initialState = {
    isLoaded : false,
    loggedUser : '',
    isEditing : false,
    editingKey : null,
    dataError : false,
    dataErrorMessage : '',
    programs : [],
    totalPropuesta : 0,
    invoice : ''
};


export default function appReducer($$state = $$initialState, action = null) {
    switch (action.type) {
        case 'APP_INPUT_FIELD_CHANGED':
            return Object.assign({}, $$state, {
                [action.payload.field]: action.payload.value,
                dataErrorMessage: { [action.payload.field] : '' }
            });

        case 'SAVE_MY_DATA':
            return Object.assign({}, $$state, {
                fullname: action.payload.fullname,
                email: action.payload.email,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SAVE_MY_DATA_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_PROGRAM_NAME':
            return Object.assign({}, $$state, {
                programName: action.payload.programName, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SAVE_PROGRAM_NAME_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'GET_COACHES_CATEGORIES':
            return Object.assign({}, $$state, {
                coachCategories: action.payload, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_COACHES_CATEGORIES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                coachCategories: null,
            });

        case 'GET_COACHES':
            return Object.assign({}, $$state, {
                coaches: action.payload, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_COACHES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                coaches: null,
            });

        case 'GET_COACHEES':
            return Object.assign({}, $$state, {
                coachees: action.payload.coachees, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_COACHEES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                coachees: null,
            });

        case 'GET_TOOLS':
            return Object.assign({}, $$state, {
                tools: action.payload, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_TOOLS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                coachCategories: null,
            });

        case 'GET_SESSIONS':
            return Object.assign({}, $$state, {
                sessions: action.payload, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_SESSIONS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                sessions: null,
            });

        case 'SAVE_PARTICIPANTS':
            return Object.assign({}, $$state, {
                coachCategory: action.payload.coachCategory,
                coacheesNumber: action.payload.coacheesNumber, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SAVE_PARTICIPANTS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_TOOLS':
            return Object.assign({}, $$state, {
                herramientas: action.payload.herramientas,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SAVE_TOOLS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_PROGRAM':
            return Object.assign({}, $$state, {
                programName : action.payload.programName, 
                coacheesNumber : action.payload.coacheesNumber, 
                coachCategory : action.payload.coachCategory, 
                sesiones : action.payload.sesiones, 
                herramientas : action.payload.herramientas,
                programs : action.payload.programs,
                isEditing : action.payload.isEditing,
                editingKey : action.payload.editingKey,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SAVE_PROGRAM_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_RESUMEN':
            return Object.assign({}, $$state, {
                programs : action.payload.programas,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SAVE_RESUMEN_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'DO_CHECKOUT':
            return Object.assign({}, $$initialState, {
                proposal : action.payload.proposal,
                programName : action.payload.programName, 
                coacheesNumber : action.payload.coacheesNumber, 
                sesiones : action.payload.sesiones, 
                herramientas : action.payload.herramientas,
                programs : action.payload.programs,
                isEditing : action.payload.isEditing,
                editingKey : action.payload.editingKey,
                dataError: false,
            });

        case 'DO_CHECKOUT_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'GET_PROPOSAL':
            return Object.assign({}, $$state, {
                proposal: action.payload.proposal, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_PROPOSAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                proposal: null,
            });

        case 'EDIT_PROPOSAL':
            return Object.assign({}, $$state, {
                proposal : action.payload.proposal,
                fullname : action.payload.fullname,
                email : action.payload.email,
                programName : action.payload.programName, 
                coacheesNumber : action.payload.coacheesNumber, 
                coachCategory : action.payload.coachCategory, 
                sesiones : action.payload.sesiones, 
                herramientas : action.payload.herramientas,
                programs : action.payload.programs,
                isEditing : action.payload.isEditing,
                editingKey : action.payload.editingKey,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'EDIT_PROPOSAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                proposal: null,
            });

        case 'SAVE_PROPOSAL':
            return Object.assign({}, $$state, {
                proposal : action.payload.proposal,
                isEditing : action.payload.isEditing,
                editingKey : action.payload.editingKey,
                dataError: false,
            });

        case 'SAVE_PROPOSAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'SAVE_FISCAL':
            return Object.assign({}, $$state, {
                proposal : action.payload.proposal,
                dataError: false,
            });

        case 'SAVE_FISCAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'DELETE_PROPOSAL':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_PROPOSAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message
            });

        case 'GET_PROPOSALS':
            return Object.assign({}, $$state, {
                proposals: action.payload.proposals, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_PROPOSALS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                proposals: null,
            });

        case 'GET_CLIENT_INVOICES':
            return Object.assign({}, $$state, {
                invoices: action.payload.invoices, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_CLIENT_INVOICES_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                invoices: [],
            });

        case 'SEND_PROPOSAL_OFFICIAL':
            return Object.assign({}, $$state, {
                proposal: action.payload.proposal, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'SEND_PROPOSAL_OFFICIAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'CLOSE_PROPOSAL':
            return Object.assign({}, $$state, {
                proposal: action.payload.proposal, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'CLOSE_PROPOSAL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'CHANGE_COUPON_STATUS':
            return Object.assign({}, $$state, {
                proposal: { ...$$state.proposal, coupon: action.payload.coupon }, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'CHANGE_COUPON_STATUS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'VALIDATE_COUPON':
            return Object.assign({}, $$state, {
                couponValidated: action.payload.coupon, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'VALIDATE_COUPON_ERROR':
            return Object.assign({}, $$state, {
                couponValidated: null,
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'UPDATE_INVOICE':
            return Object.assign({}, $$state, {
                proposal: { ...$$state.proposal, invoice: action.payload.invoice },
                dataError: false,
                dataErrorMessage: ''
            });

        case 'UPDATE_INVOICE_ERROR':
            return Object.assign({}, $$state, {
                proposal: { ...$$state.proposal, invoice: '' },
                dataError: true,
                dataErrorMessage: action.payload.message,
            }); 

        case 'ADD_COACHEE':
            return Object.assign({}, $$state, {
                proposal: { ...$$state.proposal, coachees: action.payload.proposal.coachees },
                coachee_first_name : '', 
                coachee_last_name : '',
                coachee_email : '', 
                coachee_department : '', 
                coachee_position : '', 
                coachee_phone : '', 
                coachee_mobile : '', 
                coachee_city : '', 
                coachee_country : '',
                dataError: false,
                dataErrorMessage: ''
            });

        case 'ADD_COACHEE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'DELETE_COACHEE':
            return Object.assign({}, $$state, {
                proposal: { ...$$state.proposal, coachees: action.payload.proposal.coachees },
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_COACHEE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'DELETE_USER_COACHEE':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_USER_COACHEE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'COACHEE_RATE_EMAIL':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'COACHEE_RATE_EMAIL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'PROPOSAL_TO_RATE':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: '',
                coachToRate: action.payload.coach 
            });

        case 'PROPOSAL_TO_RATE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'SAVE_PROPOSAL_RATE':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: '',
            });

        case 'SAVE_PROPOSAL_RATE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'UPDATE_COACHEE_PROFILE':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'UPDATE_COACHEE_PROFILE_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'UPDATE_COACHEE_DASHBOARD':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'UPDATE_COACHEE_DASHBOARD_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'UPDATE_COACHEE_WELCOME':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'UPDATE_COACHEE_WELCOME_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'DELETE_COMPANY':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_COMPANY_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'GET_COACH':
            return Object.assign({}, $$state, {
                coach: action.payload.coach, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_COACH_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                coach: [],
            });

        case 'GET_COACH_TOOLS':
            return Object.assign({}, $$state, {
                coachTools: action.payload.tools, 
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_COACH_TOOLS_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
                coachTools: [],
            });

        case 'ADD_COACH_TOOL':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'ADD_COACH_TOOL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'DELETE_COACH_TOOL':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_COACH_TOOL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'ADD_COACHEE_TOOL':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'ADD_COACHEE_TOOL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'DELETE_COACHEE_TOOL':
            return Object.assign({}, $$state, {
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_COACHEE_TOOL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'CREATE_TOOL':
            return Object.assign({}, $$state, {
                coachTools: action.payload.tools,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'CREATE_TOOL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'DELETE_TOOL':
            return Object.assign({}, $$state, {
                coachTools: action.payload.tools,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'DELETE_TOOL_ERROR':
            return Object.assign({}, $$state, {
                dataError: true,
                dataErrorMessage: action.payload.message,
            });

        case 'GET_COACH_COACHEES':
            return Object.assign({}, $$state, {
                coachees: action.payload.coachees,
                dataError: false,
                dataErrorMessage: ''
            });

        case 'GET_COACH_COACHEES_ERROR':
            return Object.assign({}, $$state, {
                coachees: [],
                dataError: true,
                dataErrorMessage: action.payload.message,
            });     

        case 'IS_LOGGED_SUCCESS':
            return Object.assign({}, $$state, {
                isLoaded: true,
                loggedUser: action.payload.user,
                redirectTo: action.payload.redirect
            });

        case 'IS_LOGGED_ERROR':
            return Object.assign({}, $$state, {
                isLoaded: true,
                errorLoggedUser: false,
                loggedUser: false,
                redirectTo: '/login'
            });

        case 'LOAD_HOME':
            return Object.assign({}, $$state, {
                  loggedUser: action.payload
              });

        default: {
            return $$state;
        }
    }
}
