import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import 'moment/locale/es';
import logo from '../../assets/img/logo-black.svg'

import { types } from '../../constants/constants';

import Header from '../../components/header';
import TestimonialsMMP from "../../components/testimonials-mmp"

import {getProposalOfficial,
			getTools,
			getSessions,
			getCoachesCategories } from '../../actions/app.js';

import { isLogged } from '../../actions/admin-users.js';

import { Checkbox } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';
import { MailOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Modal, Button, Space } from 'antd';

import ReactToPdf from "react-to-pdf";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

moment.locale('es');

const { confirm } = Modal;

class ProposalViewClient extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        	isLoaded : false,
        	documentHeight : 980
        };
    }

    componentDidMount(){
    	
    	this._getDataFromAPI()

    }

    _getDataFromAPI(){

    	const { t, i18n } = this.props;

        let lang = typeof this.props.match.params.lang != 'undefined' ? this.props.match.params.lang : null;

        if(lang){ 
            i18n.changeLanguage(lang) 
        }
    	
    	// Obtener las Tools
    	Promise.resolve(this.props.getTools())
	    .then((response)=> {
	        if(this.props.dataError === false){
	            
	            // Obtener las Sessions
	            Promise.resolve(this.props.getSessions())
			    .then((response)=> {
			    	if(this.props.dataError === false){

				    	// Obtener las Coach categories
		            	Promise.resolve(this.props.getCoachesCategories())
				    	.then((response)=> {
				        
					        if(this.props.dataError === false){
					        	
					        	let token = typeof this.props.match.params.token != 'undefined' ? this.props.match.params.token : null;
					        	let token_decoded = jwt.decode(token);

					        	if (typeof token_decoded === 'undefined' || typeof token_decoded.proposal_id === 'undefined') {
				                    Modal.error({
									    title: t('Esta propuesta no está disponible'),
									    content: t('Por favor pongase en contacto con nosotros.'),
									});
				                } else {
				                 	Promise.resolve(this.props.getProposalOfficial(token_decoded.proposal_id, token))
								    .then((response)=> {
								        if(this.props.dataError == false){
										    if(typeof this.props.proposal === 'undefined' || this.props.proposal === null){
									    		this.props.history.push('/')
									    	}else{

									    		// Calculamos la altura que debe tener el documento PDF a imprimir
									    		if(typeof this.props.proposal.programs != 'undefined'){
									    			let numberPrograms = JSON.parse(this.props.proposal.programs).length;
									    			let numberItems = 0;

									    			this.setState({ documentHeight : (this.state.documentHeight + ( numberPrograms * 85 )) })

									    			JSON.parse(this.props.proposal.programs).map((program, key) =>{
									    				if(typeof program.herramientas != 'undefined'){
									    					if(program.herramientas.length > 0){
									    						numberItems = numberItems + program.herramientas.length;
									    					}else{
									    						numberItems = numberItems + 1;
									    					}
									    				}else{
									    					numberItems = numberItems + 1;
									    				}

									    				if(typeof program.sesiones != 'undefined'){
									    					program.sesiones.map((sesion, key) => {
																if(sesion.cantidadPorCoachee > 0){
									    							numberItems = numberItems + 1
									    						}
									    					})
									    				}else{
									    					numberItems = numberItems + 1;
									    				}
									    				
									    				this.setState({ 
									    					documentHeight : (this.state.documentHeight + ( numberItems * 40 )),
									    					'isLoaded' : true
									    				})
									    			})
									    		}	
									    	}
								        }
								    });   
				                }
						    }

					    })

					}

			    })

	        }
	    });

    }

    _getNextCategory(category){
    	
    	let new_category = '';

    	switch (category) {
			case 'PRO-BONO':
				return 'D';
			    break;
			case 'D':
			    return 'C';
			    break;
			case 'C':
			    return 'B';
			    break;
			case 'B':
			    return 'A';
			    break;
			case 'A':
			    return 'PLUS';
			    break;
			case 'PLUS':
			    return 'PREMIUM';
			    break;
			case 'PREMIUM':
			    return 'TOP';
			    break;
			default:
			    return 'PRO-BONO';
		}

		return new_category;

    }

    _calculateDiscount(proposal){

		let totalDescuento = 0;
    	
    	JSON.parse(proposal.programs).map((program, key) => {
    		if (typeof program.sesiones != 'undefined' && program.sesiones.length > 0){
    			program.sesiones.map((sesion, key) => {
	    			if((sesion.sesion.indexOf('Devoluci') == -1 && sesion.sesion.indexOf('Herram') == -1) || (sesion.sesion.indexOf('Tool Devolution') == -1)){
	    				if( sesion.categoryCoach === 'A' || sesion.categoryCoach === 'B' || sesion.categoryCoach === 'C' || sesion.categoryCoach === 'D' ){
	    					totalDescuento = totalDescuento + sesion.totalSesion;
	    				}
	    			}
    			})
    		}
    	})

    	return totalDescuento;

    }

	_renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

	_renderProposal(){

		const { t, i18n } = this.props
        const current_lang = i18n.language
		
		const proposal = this.props.proposal;

		let totalPropuesta = 0;

		var formatter = new Intl.NumberFormat('de-DE', {
		  	style: 'currency',
		  	currency: 'EUR',
		});

		let proposalNumber = 'prop-' + moment(proposal.created_at).format('YYYY') + 
								'-' + proposal.proposalId.toString().padStart(5, '0');

		let totalDescuento = parseInt(this._calculateDiscount(proposal));

		const ref = React.createRef();

		let proposalCreatedDate = moment(this.props.proposal.created_at)

		if(current_lang.indexOf('en-GB') != -1){ proposalCreatedDate.locale('en') }
        if(current_lang.indexOf('en-US') != -1){ proposalCreatedDate.locale('en') }
        if(current_lang.indexOf('es-ES') != -1){ proposalCreatedDate.locale('es') }
        if(i18n.language == 'en'){ proposalCreatedDate.locale('en') }

        let linkCondiciones = types.API_BLOG_URL;
    	if(current_lang.indexOf('en') != -1){ 
    		linkCondiciones = linkCondiciones + 'en/contract-conditions' 
    	}else if(current_lang.indexOf('es') != -1){ 
    		linkCondiciones = linkCondiciones + 'condiciones-de-contratacion' 
    	}else{
    		linkCondiciones = linkCondiciones + 'condiciones-de-contratacion' 
    	}

		//console.log(this.state.documentHeight)

		const options = {
		  	format: [580, this.state.documentHeight]
		};

		return(

			<React.Fragment>
				<div className={'row'}>
					<ReactToPdf targetRef={ref} filename={proposalNumber + '.pdf'} options={options} x={10} y={10}>
				        {({toPdf}) => (
				            <div className={'col-6 offset-6 col-sm-5 offset-sm-7 col-md-4 offset-md-8 col-lg-4 offset-lg-8 text-right mb-5'}>
		      					<a className={'btn-submit mt-4 w-100'} onClick={toPdf}>
		      						<span className={'circle'} aria-hidden="true">
		      							<span className={'icon arrow'}></span>
		      						</span>
		      						<span className={'button-text'}>{t('Descarga en PDF')}</span></a>
		      				</div>
				        )}
				    </ReactToPdf>
				</div>

			    <div ref={ref}>

				<div className={'row mt-3 mb-5 pt-2'}>
      				<div className={'col-12 col-md-6 text-left align-self-top'}>
      					<img
		                  src={logo}
		                  className={"logo"}
		                  alt="Logo Exekutive Coaching"
		                />
      					<h1 className={'orange fs-12 mt-2'}>
      						{t('MI MEJOR PROPUESTA')}
      					</h1>
      				</div>

      				<div className={'col-12 col-md-6 text-right align-self-top'}>
      					<p className={'mb-1'}>{t('Nº de propuesta')}: { proposalNumber }</p>
      					<p className={'capitalize'}>
      						{ proposalCreatedDate.format('DD MMMM YYYY') }
      					</p>
      				</div>
      			</div>

				<div className={'row'}>
					<div className={'col-md-12'}>
						<h2 className={'mb-4'}>EXEKUTIVE Coaching </h2>
					</div>
				</div>

				<div className={'row mb-4'}>
					<div className={'col-12'}>
						<p>Kuality Coaching S.L</p>
						<p>B88460613</p>
						<p>C/ Bodas de Fígaro 10</p>
						<p>Majadahonda</p>
						<p>MADRID</p>
						<p>28222</p>
						<p>España</p>
					</div>
				</div>

				<div className={'row'}>
					<div className={'col-md-12'}>
						<h2 className={'mb-4'}>{t('Datos de contacto')}</h2>
					</div>
				</div>

				<div className={'row'}>
					<div className={'col-md-6'}>
						<p><b>{t('Nombre')}: </b> { proposal.fullname != 'undefined' ? proposal.fullname : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Email')}: </b> { proposal.email != 'undefined' ? proposal.email : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Teléfono')}: </b> { proposal.phone !== 'undefined' ? proposal.phone : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Móvil')}: </b> { proposal.mobile != 'undefined' ? proposal.mobile : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Ciudad')}: </b> { proposal.city != 'undefined' ? proposal.city : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('País')}: </b> { proposal.country != 'undefined' ? proposal.country : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Empresa')}: </b> { proposal.company != 'undefined' ? proposal.company : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Departamento')}: </b> { proposal.department != 'undefined' ? proposal.department : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Posicion')}: </b> { proposal.position != 'undefined' ? proposal.position : '-'} </p>
					</div>
				</div>

				<div className={'row mt-4'}>
					<div className={'col-md-12'}>
						<h2 className={'mb-4'}>{t('Desglose de la propuesta')}</h2>
					</div>
				</div>

				{
					JSON.parse(proposal.programs).map((program, key) => {

						return(
							<div className={'row checkout-row'} key={ key }>

								<div className={'col-12'}>
									
									<p className={'fs-12'}><b>{t('Programa')}: </b><span className={'orange'}>{ program.programName }</span></p>
								
								</div>
								
								<div className={'col-sm-6'}>

									<p><b>{t('Nº Coachees')}: </b><span>{ program.coacheesNumber }</span></p>

								</div>

								<div className={'col-sm-6'}>

									<p><b>{t('Categoría del Coach')}: </b><span>{ program.coachCategory }</span></p>

								</div>

								<div className={'col-12 pb-4 pt-3'}>

									<p className={'fs-12 mb-1'}><b>{t('Herramientas')}</b></p>

	  							{
	  								program.herramientas ? 
										
										program.herramientas.map((herramienta, key) => {

											return(

												<React.Fragment>

												<div className={'row'}>

													<div className={'col-5 col-sm-6 py-2 fs-09'}>{ herramienta.herramienta }</div>

													<div className={'col-1 col-sm-1 py-2 fs-09'}>x{ herramienta.numero }</div>

													<div className={'col-1 col-sm-3 py-2 fs-09'}>{ herramienta.categoryCoach }</div>

													<div className={'col-4 col-sm-2 py-2 fs-09 text-right'}> { formatter.format(herramienta.totalHerramienta) }</div>

												</div>

												</React.Fragment>
											);
										})

										:  <p>{t('No hay herramientas contratadas')}</p>

	            				}

								</div>

								<div className={'col-12 mb-3'}>

									<p className={'fs-12 mb-1'}><b>{t('Sesiones')}</b></p>

	  							{
										program.sesiones.map((sesion, key) => {
											if(sesion.cantidadPorCoachee > 0){

		  										if((sesion.sesion.indexOf('Devoluci') == -1 && sesion.sesion.indexOf('Herram') == -1) && (sesion.sesion.indexOf('Tool Devolution') == -1)){
			  										return(

			  											<React.Fragment key={key}>

			  											<div className={'row'}>

			  												<div className={'col-5 col-sm-6 py-2 fs-09'}>{ sesion.sesion }</div>

			  												<div className={'col-1 col-sm-1 py-2 fs-09'}>x{ sesion.cantidadPorCoachee }</div>

			  												<div className={'col-1 col-sm-3 py-2 fs-09'}> { sesion.categoryCoach }</div>

			  												<div className={'col-4 col-sm-2 py-2 fs-09 text-right'}> { formatter.format(sesion.totalSesion) }</div>

			  											</div>

			  											</React.Fragment>
			  										);
			  									}else{
			  										let numSesionesDevolucion = 0;

			  										if(program.herramientas){ 
										
														program.herramientas.map((herramienta, key) => {
															numSesionesDevolucion = parseInt(numSesionesDevolucion) + parseInt(herramienta.numero);
														})

														return(

				  											<React.Fragment key={key}>

				  											<div className={'row'}>

				  												<div className={'col-5 col-sm-6 py-2 fs-09'}>{ sesion.sesion }</div>

				  												<div className={'col-1 col-sm-1 py-2 fs-09'}>x{ numSesionesDevolucion }</div>

				  												<div className={'col-1 col-sm-3 py-2 fs-09'}> { sesion.categoryCoach }</div>

				  												<div className={'col-4 col-sm-2 py-2 fs-09 text-right'}> { formatter.format(sesion.totalSesion) }</div>

				  											</div>

				  											</React.Fragment>
				  										);

													}
			  									}
		  									}
										})
	            				}

								</div>

								{/** }<div className={'col-md-12 fs-12 text-right py-1'}><b>Total programa:</b> { formatter.format(program.totalPrograma) }</div> { **/}

							</div>
						);
					})
	        	}

	        	{ typeof proposal.couponValidated != 'undefined' && (

	        		<React.Fragment>
			        	<div className={'row mt-4'}>
							<div className={'col-md-12'}>
								<h2 className={'mb-4'}>{t('Cheque descuento aplicado')}</h2>
							</div>
						</div>

						<div className={'row checkout-row'}>

							<div className={'col-12 col-md-6'}>
								
								<p><b>{t('Código')}: </b><span>{ proposal.couponValidated.code }</span></p>
							
							</div>

							<div className={ 'col-12 col-md-6 text-right' }>
								- { formatter.format(proposal.couponValidated.value) }
							</div>
						</div>
					</React.Fragment>
					)
				}

		        <div className={'row mt-5'}>

		        	<div className={'col-9 text-right fs-11 bold py-1'}>Subtotal</div>

		        	<div className={'col-3 text-right fs-11 py-1'}>{ formatter.format(proposal.totalPropuesta) }</div>

		        	<div className={'col-9 price-gray text-right fs-11 bold py-1'}>+ {t('IVA')} (21%)</div>

		        	<div className={'col-3 price-gray text-right fs-11 py-1'}>+ { formatter.format(proposal.totalPropuesta * 0.21) }</div>

		        	<div className={'col-9 text-right fs-12 bold py-1'}>TOTAL</div>

		        	<div className={'col-3 text-right fs-12 bold py-1'}>{ formatter.format(proposal.totalPropuesta * 1.21) }</div>

		        </div>

		        <div className={'row mt-2 mb-4'}>
		        	<div className={'col-12 text-right'}>
		        		<p className={'fs-08'}>{t('Revisa las')} {' '}
		        			<a href={ linkCondiciones } target="_blank" className={'orange'}>
		        				{t('Condiciones de Contratación')}</a>
		        		</p>
		        	</div>
		        </div>

		        <div className={'row mt-2 mb-3'}>
		        	<div className={'col-12 text-left'}>
		        		<p>{ t('Realizar el pago mediante transferencia a') } {':'} <br /> { t('KUALITY COACHING, S.L.  ES82 0049 3672 88 2614032576') }</p>
		        	</div>
		        </div>

		        <div className={'row'}>
		        	<div className={'col-12 text-left'}>
		        		<p><b>{t('Contacto')}</b></p>
		        	</div>

		        	<div className={'col-12 col-md-6 text-left'}>
		        		<p>Lorena Ruiz<br />Remote Office Mgr.</p>
		        	</div>

		        	<div className={'col-12 col-md-6 text-right'}>
		        		<p>(+34) 648 126 538 Office<br />lorena@exekutivecoaching.com</p>
		        	</div>
		        </div>

		        </div>
		    </React.Fragment>
			      					
		);

	}

    render() {

    	const { t } = this.props;

    	let token = typeof this.props.match.params.token != 'undefined' ? this.props.match.params.token : null;

        return(
        	<React.Fragment>
	                
	            	<TestimonialsMMP proposal={ this.props.proposal } />

	                <div className={"container"}>
	                    <div className={"row"}>
	                        <div className={"col-12 col-md-8 offset-md-4 align-self-center pt-5"}>
	                            <section>
	                            	<div className={'box-with-shadow'}>
		                                { 
		                                    this.state.isLoaded ? 
		                                        this._renderProposal()
		                                    :
		                                        this._renderLoader()
		                                }
	                                </div>

		                            <div className={'col-6 offset-6 col-sm-5 offset-sm-7 col-md-4 offset-md-8 col-lg-4 offset-lg-8 text-right mb-5'}>
				      					<a className={'btn-submit mt-4 w-100'} onClick={() => this.props.history.push('/solicitud/confirmar/' + token)}>
				      						<span className={'circle'} aria-hidden="true">
				      							<span className={'icon arrow'}></span>
				      						</span>
				      						<span className={'button-text'}>{t('Confirmar')}</span></a>
				      				</div>
	                            </section>
	                        </div>
	                    </div>
	                </div>
	        </React.Fragment>
        );

    }

}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
    	proposal : storeApp.proposal,
    	tools : storeApp.tools,
    	sessions : storeApp.sessions,
    	coachCategories: storeApp.coachCategories,
    	dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage,
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        getProposalOfficial: (id, token) => dispatch(getProposalOfficial(id, token)),
        getTools: () => dispatch(getTools()),
        getSessions: () => dispatch(getSessions()),
        getCoachesCategories: () => dispatch(getCoachesCategories()),
        isLogged: () => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProposalViewClient));
