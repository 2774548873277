import { types } from '../constants/constants';
import { push } from 'react-router-redux';
import { browserHistory } from 'react-router';
import i18next from 'i18next';

/** Método que almacena en el store de Redux los inputs que
    el usuario va rellenando, para garantizar la persistencia
    de los datos.
    @param string $field el nombre del input
    @param string $value el valor del input
**/
export function inputFieldChanged(field, value) {
    return function(dispatch) {
        dispatch({ type: "APP_INPUT_FIELD_CHANGED", payload: {field : field, value : value} });
    }
}

/** Método que almacena el nombre y el email de la persona
    que crea la solicitud de propuesta.
    @param string $fullname el nombre del usuario
    @param string $email el email del usuario
**/
export function saveMyData(fullname, email) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

        	if(typeof fullname === 'undefined' || fullname === ''){
        		error = true;
        		errorMessage.fullname = i18next.t('El nombre completo es obligatorio');
        	}

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.email = i18next.t('El email es obligatorio');
            }else{
                var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(!email.match(mailformat))
                {
                    error = true;
                    errorMessage.email = i18next.t('El email no es válido');
                }
            }

            if(error === false){ // No error in data

                // Comprobamos si el usuario ya tiene perfil en la plataforma y no es empresa
                var formBody = [];
                
                var details={ 
                    'email' : email
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;
                const token = localStorage.getItem('token');

                return fetch(types.API_URL + 'user/get-by-email', {
                    method: 'post',
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Content-Language": i18next.language,
                        "token": token  
                    },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        if( responseJson.user.role.indexOf('5ee5ff775b622f72ef2b5421') != -1 ){ // Existe el usuario y es empresa
                            dispatch({ type: "SAVE_MY_DATA", payload: {fullname : fullname, email : email} });
                        }else{ // Existe el usuario y no es empresa, no puede crear propuestas
                            errorMessage.email = i18next.t('Este email ya existe como usuario de la plataforma y no tiene perfil de empresa') + '. ' + i18next.t('Por lo tanto no puede crear propuestas');
                            dispatch({ type: "SAVE_MY_DATA_ERROR", payload: { message : errorMessage } });
                        }

                    }else{ // No existe usuario
                        dispatch({ type: "SAVE_MY_DATA", payload: {fullname : fullname, email : email} });
                    }
                })
                .catch((error) => {
                    errorMessage.message = 'catch: ' + error;
                    dispatch({ type: "SAVE_MY_DATA_ERROR", payload: { message : errorMessage } });
                });

            }else{
                dispatch({ type: "SAVE_MY_DATA_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que almacena el nombre del programa que se esta
    creando.
    @param string $programName el nombre del programa
**/
export function saveProgramName(programName) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

        	if(typeof programName === 'undefined' || programName === ''){
        		error = true;
        		errorMessage.programName = i18next.t('El nombre del programa es obligatorio');
        	}

            if(error === false){ // No error in data
            	console.log(error);
                dispatch({ type: "SAVE_PROGRAM_NAME", payload: {programName : programName} });
            }else{
                dispatch({ type: "SAVE_PROGRAM_NAME_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que almacena el numero de Coachees y la categoria
    del Coach seleccionado para el programa.
    @param integer $coacheesNumber el numero de Coachees del programa
    @param string $coachCategory la categoria del Coach del programa
**/
export function saveParticipants(coacheesNumber, coachCategory) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

        	if(typeof coacheesNumber === 'undefined' || coacheesNumber === 0){
        		error = true;
        		errorMessage.coacheesNumber = i18next.t('Debe haber al menos 1 coachee');
        	}

        	if(typeof coachCategory === 'undefined' || coachCategory === ''){
        		error = true;
        		errorMessage.coachCategory = i18next.t('Debes seleccionar una categoría');
        	}

            if(error === false){ // No error in data
            	console.log(error);
                dispatch({ type: "SAVE_PARTICIPANTS", payload: {coacheesNumber : coacheesNumber, coachCategory : coachCategory} });
            }else{
                dispatch({ type: "SAVE_PARTICIPANTS_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que almacena las herramientas seleccionada por el usuario
    para formar parte del programa.
    @param JSON object $herramientas las herramientas seleccionadas
    La estructura del array herramientas es del tipo:
    herramientas : [
        label_herramienta : string
        herramienta : string
        label_numero : string
        numero : integer
    ]
**/
export function saveTools(herramientas) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

	        if(typeof herramientas != 'undefined' && herramientas.length > 0){
	        	herramientas.map((herramienta, key) => {
	        		if(typeof herramienta.herramienta === 'undefined' || herramienta.herramienta === ''){
		        		error = true;
		        		errorMessage.tools = i18next.t('Si no deseas seleccionar Herramienta Diagnóstica haz clic en el icono (-) que aparece a la derecha');
		        	} else {

    		        	if(typeof herramienta.numero === 'undefined' || herramienta.numero === null){
    		        		error = true;
    		        		errorMessage.toolsNumber = i18next.t('Debe seleccionar el número de herramientas');
    		        	}
                    }
	        	});
	        }

            if(error === false){ // No error in data
            	console.log(error);
                dispatch({ type: "SAVE_TOOLS", payload: {herramientas : herramientas} });
            }else{
                dispatch({ type: "SAVE_TOOLS_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que almacena un programa y limpia los valores de la herramienta
    para que se pueda proceder a crear el siguiente. También gestiona la 
    edición de un programa.
    @param string $programName el nombre del programa
    @param integer $coacheesNumber el numero de Coachees del programa
    @param string $coachCategory la categoria del Coach del programa
    @param JSON object $sesiones las sesiones seleccionadas
    @param JSON object $herramientas las herramientas seleccionadas
    @param JSON object $programs las herramientas seleccionadas
    @param boolean $isEditing gestiona si el programa se esta editando
    @param integer $editingKey el index del programa que se esta editando
    La estructura del array sesiones es del tipo:
    sesiones : [
        sesion : string
        cantidadPorCoachee : integer
        descripcion : string
        categoryCoach : string
        totalSesion : float
    ]
    La estructura del array programs es del tipo:
    programs : [
        programName: string
        coacheesNumber: number
        coachCategory: string
        sesiones: [ sesiones ]
        herramientas: [ herramientas ]
    ]
**/
export function saveProgram(programName, coacheesNumber, coachCategory, sesiones, herramientas, programs, isEditing, editingKey) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

        	// Comporbamos que se ha asignado un nombre al programa
        	if(typeof programName === 'undefined' || programName === ''){
        		error = true;
        		errorMessage.programName = "El nombre del programa es obligatorio. Por favor vuelve al primer paso y rellenalo.";
        	}

        	// Comporbamos que se ha seleccionado el número de Coachees
        	if(typeof coacheesNumber === 'undefined' || coacheesNumber === 0){
        		error = true;
        		errorMessage.coacheesNumber = "Debe haber al menos 1 coachee. Por favor vuelve al segundo paso y elige un número.";
        	}

        	// Comprobamos que se ha seleccionado la categoría de Coach
        	if(typeof coachCategory === 'undefined' || coachCategory === ''){
        		error = true;
        		errorMessage.coachCategory = "Debes seleccionar una categoría. Por favor vuelve al segundo paso y elige una.";
        	}

        	// Comprobamos que si se han añadido herramientas, estén bien elegidas y el número por coachee también.
        	if(typeof herramientas !== 'undefined' && herramientas.length > 0){
	        	herramientas.map((herramienta, key) => {
	        		if(typeof herramienta.herramienta === 'undefined' || herramienta.herramienta === ''){
		        		error = true;
		        		errorMessage.tools = "Hay errores en la selección de herramientas. Por favor vuelve al tercer paso y compruebalas.";
		        	}

		        	if(typeof herramienta.numero === 'undefined' || herramienta.numero === null){
		        		error = true;
		        		errorMessage.toolsNumber = "Hay errores en el número de herramientas. Por favor vuelve al tercer paso y compruebalo.";
		        	}
	        	});
	        }

	        // Comprobamos que hay al menos una sesion. Y además que si hay herramientas se haya asignado una sesion de devolución.
        	if(typeof sesiones != 'undefined' && sesiones.length > 0){
        		let totalSesiones = 0;
        		let totalSesionesDevolucion = 0;

	        	// Recorremos las sesiones y vemos cuantas se han seleccionado en total y de ellas cuantas son de devolución de herramientas.
	        	sesiones.map((sesion, key) => {
	        		// Lo primero forzamos que las sesiones de devolución sean igual al número de herramientas
	        		if(typeof herramientas != 'undefined' && ((sesion.sesion.indexOf('Devoluci') != -1 && sesion.sesion.indexOf('Herram') != -1) || (sesion.sesion.indexOf('Tool Devolution') != -1))){
	        			console.log(typeof herramientas != 'undefined' && (sesion.sesion.indexOf('Devoluci') != -1 && sesion.sesion.indexOf('Herram') != -1) || (sesion.sesion.indexOf('Tool Devolution') != -1));
                        sesion.cantidadPorCoachee = herramientas.length; 
	        		}

	        		totalSesiones = totalSesiones + sesion.cantidadPorCoachee;

	        		if((sesion.sesion.indexOf('Devoluci') != -1 && sesion.sesion.indexOf('Herram') != -1) || (sesion.sesion.indexOf('Tool Devolution') != -1)){
	        			totalSesionesDevolucion = totalSesionesDevolucion + sesion.cantidadPorCoachee;
	        		}
	        	});

	        	// Si hay herramientas tiene que haber el mismo numero de sesiones de devolucion (requesito especifico del cliente)
	        	if(typeof herramientas != 'undefined' && herramientas.length > 0){
	        		if(totalSesionesDevolucion != herramientas.length){
	        			totalSesionesDevolucion = herramientas.length;
	        		}
	        	}

	        	// Compeobamos que se haya seleccionado al menos una sesion de coaching
	        	if(typeof totalSesiones != 'undefined' && totalSesiones <= 0){
	        		error = true;
		        	errorMessage.sessionsNumber = i18next.t('Por favor elige al menos una sesión');
	        	}
	        }else{
	        	error = true;
		        errorMessage.sessionsNumber = i18next.t('Por favor elige al menos una sesión');
	        }

            if(error === false){ // No error in data

            	if (isEditing === true) {
            		programs[editingKey].programName = programName;
            		programs[editingKey].coacheesNumber = coacheesNumber;
            		programs[editingKey].coachCategory = coachCategory;
            		programs[editingKey].sesiones = sesiones;
            		programs[editingKey].herramientas = herramientas;
            	} else {
	            	programs.push(
	            		{
	            			programName : programName,
	            			coacheesNumber : coacheesNumber, 
	                		coachCategory : coachCategory, 
	                		sesiones : sesiones, 
	                		herramientas : herramientas,
	            		}
	            	);
	            }

                dispatch({ 
                	type: "SAVE_PROGRAM", 
                	payload: {
                		programName : '', // Reseteamos el valor
                		coacheesNumber : 0, // Reseteamos el valor
                		coachCategory : undefined, // Reseteamos el valor
                		sesiones : null, // Reseteamos el valor
                		herramientas : undefined ,// Reseteamos el valor
						isEditing : false,// Reseteamos el valor
                		editingKey : null,// Reseteamos el valor
                		programs : programs,
                	} 
                });
            }else{
                dispatch({ type: "SAVE_PROGRAM_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
            console.log(error.stack);
            console.trace(error);
        }
    }
}

/** Método que almacena toda la propuesta en el store para poder
    proceder al checkout.
    @param JSON object $programas contiene todos los programas creados por el usuario
**/
export function saveResumen(programas) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

	        if(typeof programas === 'undefined' && programas.length === 0){
		       	error = true;
		       	errorMessage.resumen = i18next.t('No puedes pasar al siguiente paso si no tienes ningún programa creado');
	        }

            if(error === false){ // No error in data
            	console.log(error);
                dispatch({ type: "SAVE_RESUMEN", payload: {programas : programas} });
            }else{
                dispatch({ type: "SAVE_RESUMEN_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que almacena los datos de checkout de la propuesta y los manda a la API
    para guardalos en la base de datos.
    @param string $fullname el nombre del usuario
    @param string $email el email del usuario
    @param string $phone el numero fijo del usuario
    @param string $mobile el numero de movil del usuario
    @param string $city la ciudad del usuario
    @param string $country el país del usuario
    @param string $company la empresa del usuario
    @param string $department el departamento del usuario dentro de la empresa
    @param string $position el cargo del usuario dentro de la empresa
    @param boolean $lopd nos dice si el usuario ha aceptado la LOPD
    @param JSON object $programs contiene todos los programas creados por el usuario
    @param float $totalPropuesta coste total de la propuesta
**/
export function doCheckout(fullname, email, phone, mobile, city, country, company, department, position, lopd, programs, totalPropuesta, couponValidated) {
	return async function(dispatch) {
        try
        {
        	let error = false;
        	let errorMessage = {};

	        if(typeof programs === 'undefined' || programs.length === 0){
		       	error = true;
		       	errorMessage.resumen = i18next.t('No puedes completar la propuesta sin que hayas incluido programas en ella');
	        }

	        if(typeof fullname === 'undefined' || fullname === ''){
        		error = true;
        		errorMessage.fullname = i18next.t('El nombre completo es obligatorio');
        	}

        	if(typeof email === 'undefined' || email === ''){
        		error = true;
        		errorMessage.email = i18next.t('El email es obligatorio');
        	}

        	if(typeof lopd === 'undefined' || lopd.target.checked === false){
        		error = true;
        		errorMessage.lopd = i18next.t('Tienes que aceptar la politica de privacidad');
        	}

            if(error === false){ // No error in data
            	var formBody = [];
            
	            var details={
	            	'fullname' : fullname, 
	            	'email' : email, 
	            	'phone' : phone, 
	            	'mobile' : mobile, 
	            	'city' : city, 
	            	'country' : country, 
	            	'company' : company, 
	            	'department' : department, 
	            	'position' : position, 
	            	'programs' : JSON.stringify(programs),
                    'totalPropuesta' : totalPropuesta,
                    'couponValidated' : JSON.stringify(couponValidated)
	            };

	            for (var property in details) {
	                  var encodedKey = encodeURIComponent(property);
	                  var encodedValue = encodeURIComponent(details[property]);
	                  formBody.push(encodedKey + "=" + encodedValue);
	            }

	            return fetch(types.API_URL + 'proposal/create', {
	                method: 'post',
	                headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept-Language": i18next.language,
                        "Content-Language": i18next.language 
                    },
	                body: formBody.join("&"),
	            })
	            .then((response) => response.json())
	            .then((responseJson) => {
	                if(responseJson.result == 1){ // If creation success
                		dispatch({ type: "DO_CHECKOUT", payload: { 
                			message : responseJson.message, 
                			proposal : responseJson.proposal,
                			programName : '', // Reseteamos el valor
	                		coacheesNumber : 0, // Reseteamos el valor
	                		coachCategory : 'delete', // Reseteamos el valor
	                		sesiones : null, // Reseteamos el valor
	                		herramientas : [
								        		{
								        			'label_herramienta' : 'Selecciona herramienta',
								        			'herramienta' : '',
								        			'label_numero' : 'Cantidad por coachee',
								        			'numero' : null
								        		}
								        	],// Reseteamos el valor
							isEditing : false,// Reseteamos el valor
	                		editingKey : null,// Reseteamos el valor
	                		programs : [],
                            totalPropuesta : 0,
                            couponValidated : null
                		} });
	                }else{
	                    dispatch({ type: "DO_CHECKOUT_ERROR", payload: { result : 0, message : responseJson.message } });
	                }
	            })
	            .catch((error) => {
	            	dispatch({ type: "DO_CHECKOUT_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud, error:' + error } });
	            });
            }else{
                dispatch({ type: "DO_CHECKOUT_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
            dispatch({ type: "DO_CHECKOUT_ERROR", payload: { message : error } });
        }
    }
}

/** Método que recuperara los Coaches desde la 
    API de Wordpress.
**/
export function getCoaches(lang) {
    return async function(dispatch) {        
        try
        {
            let lang_code = typeof lang == 'undefined' || lang == '' ? '' : lang + '/';

            if(lang_code.indexOf('en-GB') != -1){ lang_code = 'en/' }

            if(lang_code.indexOf('es') != -1){ lang_code = '' }

            return fetch(types.API_BLOG_URL + lang_code + 'wp-json/exco/v2/get-coaches', {
                method: 'get',
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_COACHES", payload: responseJson });
                }else{
                    dispatch({ type: "GET_COACHES_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_COACHES_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite activar o desactivar un Coach en MVP, a través
    de la API.
**/
export function changeCoachStatus(firstname, email, picture, city, country, newStatus) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'first_name' : firstname,
                'picture' : picture,
                'city' : city,
                'country' : country,
                'email' : email,
                'new_status' : newStatus
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/change-coach-status', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "CHANGE_COACH_STATUS", 
                        payload: { 
                            proposal : responseJson.proposal
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "CHANGE_COACH_STATUS_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "CHANGE_COACH_STATUS_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recuperara las categorias de Coach y sus precios desde la 
    API de Wordpress.
    La respuesta esperada es del tipo:
    [
        {
            "term_id" : integer
            "name" : string
            "slug" : string
            "term_group" : integer
            "term_taxonomy_id" : integer
            "taxonomy" : string
            "description" : string
            "parent" : integer
            "count" : integer
            "filter" : string
            "term_order" : integer
            "precio_30" : float
            "precio_60" : float
            "precio_90" : float
        },
    ]
**/
export function getCoachesCategories() {
    return async function(dispatch) {        
        try
        {
            return fetch(types.API_BLOG_URL + 'wp-json/exco/v2/get-coaches-categories', {
                method: 'get',
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_COACHES_CATEGORIES", payload: responseJson });
                }else{
                    dispatch({ type: "GET_COACHES_CATEGORIES_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_COACHES_CATEGORIES_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recuperara los Coachees desde la 
    API de Wordpress.
**/
export function getCoachees() {
    return async function(dispatch) {        
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/get-coachees', {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_COACHEES_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_COACHEES", payload: { coachees : responseJson.users } });
                }else{
                    dispatch({ type: "GET_COACHEES_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_COACHEES_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera los tipos de herramientas y sus precios desde la 
    API de Wordpress.
    La respuesta esperada es del tipo:
    [
        {
            "label" : string
            "title" : string
            "description" : string
            "precio_base" : float
            "precio_pro_bono" : float
            "precio_d" : float
            "precio_c" : float
            "precio_b" : float
            "precio_a" : float
            "precio_plus" : float
            "precio_premium" : float
            "precio_top" : float
        },
    ]
**/
export function getTools(lang) {
    return async function(dispatch) {        
        try
        {
            let lang_code = typeof lang == 'undefined' || lang == '' ? '' : lang + '/';

            if(lang_code.indexOf('en-GB') != -1){ lang_code = 'en/' }

            if(lang_code.indexOf('es') != -1){ lang_code = '' }

            return fetch(types.API_BLOG_URL + lang_code + 'wp-json/exco/v2/get-tools', {
                method: 'get',
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_TOOLS", payload: responseJson });
                }else{
                    dispatch({ type: "GET_TOOLS_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_TOOLS_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera los tipos de sesiones la API de Wordpress.
    La respuesta esperada es del tipo:
    [
        {
            "label" : string
            "title" : string
            "description" : string
            "precio_base" : float
            "precio_pro_bono" : float
            "precio_d" : float
            "precio_c" : float
            "precio_b" : float
            "precio_a" : float
            "precio_plus" : float
            "precio_premium" : float
            "precio_top" : float
        },
    ]
**/
export function getSessions(lang) {
    return async function(dispatch) {        
        try
        {
            console.log("lang: " + lang);
            
            let lang_code = typeof lang == 'undefined' || lang == '' ? '' : lang + '/';

            if(lang_code.indexOf('en-GB') != -1){ lang_code = 'en/' }

            if(lang_code.indexOf('es') != -1){ lang_code = '' }

            return fetch(types.API_BLOG_URL + lang_code + 'wp-json/exco/v2/get-sessions', {
                method: 'get',
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_SESSIONS", payload: responseJson });
                }else{
                    dispatch({ type: "GET_SESSIONS_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_SESSIONS_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera una propuesta desde la API Rest de Exekutive
    y prepara Redux para editar dicha propuesta.
    La respuesta esperada es del tipo:
    "proposal" : {
        "_id" : ObjectId
        "created_at" : ISODate
        "updated_at" : ISODate
        "totalPropuesta" : float
        "programs" : [ programs ]
        "position" : string
        "department" : string
        "company" : string
        "country" : string
        "city" : string
        "mobile" : string
        "phone" : string
        "email" : string
        "fullname" : string
    }
**/
export function editProposal(id) {
    return async function(dispatch) {        
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/' + id, {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "EDIT_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    let programs = JSON.parse(responseJson.proposal.programs);

                    let programName = programs[0].programName;
                    let coacheesNumber = programs[0].coacheesNumber; 
                    let coachCategory = programs[0].coachCategory; 
                    let sesiones = programs[0].sesiones; 
                    let herramientas = programs[0].herramientas;
                    let isEditing = true;
                    let editingKey = 0;

                    dispatch({ type: "EDIT_PROPOSAL", payload: { 
                        proposal : responseJson.proposal,
                        fullname : responseJson.proposal.fullname,
                        email : responseJson.proposal.email,
                        programName : programName,
                        coacheesNumber : coacheesNumber,
                        coachCategory : coachCategory,
                        sesiones : sesiones,
                        herramientas : herramientas,
                        programs : programs,
                        isEditing : isEditing,
                        editingKey : editingKey
                    } });
                }else{
                    dispatch({ type: "EDIT_PROPOSAL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "EDIT_PROPOSAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que almacena los datos de checkout de la propuesta y los manda a la API
    para guardalos en la base de datos.
    @param string $fullname el nombre del usuario
    @param string $email el email del usuario
    @param string $phone el numero fijo del usuario
    @param string $mobile el numero de movil del usuario
    @param string $city la ciudad del usuario
    @param string $country el país del usuario
    @param string $company la empresa del usuario
    @param string $department el departamento del usuario dentro de la empresa
    @param string $position el cargo del usuario dentro de la empresa
    @param boolean $lopd nos dice si el usuario ha aceptado la LOPD
    @param JSON object $programs contiene todos los programas creados por el usuario
    @param float $totalPropuesta coste total de la propuesta
**/
export function saveProposal(proposal_id, fullname, email, programs, totalPropuesta) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof programs === 'undefined' || programs.length === 0){
                error = true;
                errorMessage = i18next.t('No puedes completar la propuesta sin que hayas incluido programas en ella');
            }

            if(typeof fullname === 'undefined' || fullname === ''){
                error = true;
                errorMessage.fullname = i18next.t('El nombre completo es obligatorio');
            }

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.email = i18next.t('El email es obligatorio');
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={
                    'id' : proposal_id,
                    'fullname' : fullname, 
                    'email' : email, 
                    'programs' : JSON.stringify(programs),
                    'totalPropuesta' : totalPropuesta
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                const token = localStorage.getItem('token');

                return fetch(types.API_URL + 'proposal/save', {
                    method: 'post',
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                        'token': token 
                    },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    if (response.status === 404 || response.status === 200) {
                        return response.json()
                    } else {
                        if(response.status === 401){  // If unauthorized
                            window.location.href = '/login'; 
                        }else{
                            dispatch({ type: "SAVE_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                        }
                    }
                })
                .then((responseJson) => {
                    if(responseJson !== null){ // If creation success
                        dispatch({ type: "SAVE_PROPOSAL", payload: { 
                            message : responseJson.message, 
                            proposal : responseJson.proposal,
                            programName : '', // Reseteamos el valor
                            coacheesNumber : 0, // Reseteamos el valor
                            coachCategory : '', // Reseteamos el valor
                            sesiones : null, // Reseteamos el valor
                            herramientas : [
                                                {
                                                    'label_herramienta' : i18next.t('Selecciona herramienta'),
                                                    'herramienta' : '',
                                                    'label_numero' : i18next.t('Cantidad por coachee'),
                                                    'numero' : null
                                                }
                                            ],// Reseteamos el valor
                            isEditing : false,// Reseteamos el valor
                            editingKey : null,// Reseteamos el valor
                            programs : [],
                            totalPropuesta : 0
                        } });
                    }else{
                        dispatch({ type: "SAVE_PROPOSAL_ERROR", payload: { result : 0, message : responseJson.message } });
                    }
                })
                .catch((error) => {
                    dispatch({ type: "SAVE_PROPOSAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud, error:' + error } });
                });
            }else{
                dispatch({ type: "SAVE_PROPOSAL_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
            dispatch({ type: "SAVE_PROPOSAL_ERROR", payload: { message : error } });
        }
    }
}

/** Método que almacena los datos de checkout de la propuesta y los manda a la API
    para guardalos en la base de datos.
    @param string $fullname el nombre del usuario
    @param string $email el email del usuario
    @param string $phone el numero fijo del usuario
    @param string $mobile el numero de movil del usuario
    @param string $city la ciudad del usuario
    @param string $country el país del usuario
    @param string $company la empresa del usuario
    @param string $department el departamento del usuario dentro de la empresa
    @param string $position el cargo del usuario dentro de la empresa
    @param boolean $lopd nos dice si el usuario ha aceptado la LOPD
    @param JSON object $programs contiene todos los programas creados por el usuario
    @param float $totalPropuesta coste total de la propuesta
**/
export function saveFiscal(proposal_id, razonFiscal, cif, direccionFacturacion, provinciaFacturacion, ciudadFacturacion, telefonoFacturacion, codigopostalFacturacion, lopdFacturacion, pago, token) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof razonFiscal === 'undefined' || razonFiscal === ''){
                error = true;
                errorMessage.razonFiscal = i18next.t('La razón fiscal es obligatoria');
            }

            if(typeof cif === 'undefined' || cif === ''){
                error = true;
                errorMessage.cif = i18next.t('El CIF/NIF es obligatorio');
            }

            if(typeof direccionFacturacion === 'undefined' || direccionFacturacion === ''){
                error = true;
                errorMessage.direccionFacturacion = i18next.t('La dirección de facturación es obligatoria');
            }

            if(typeof provinciaFacturacion === 'undefined' || provinciaFacturacion === ''){
                error = true;
                errorMessage.provinciaFacturacion = i18next.t('La provincia de facturación es obligatoria');
            }

            if(typeof ciudadFacturacion === 'undefined' || ciudadFacturacion === ''){
                error = true;
                errorMessage.ciudadFacturacion = i18next.t('La ciudad de facturación es obligatoria');
            }

            if(typeof telefonoFacturacion === 'undefined' || telefonoFacturacion === ''){
                error = true;
                errorMessage.telefonoFacturacion = i18next.t('El teléfono es obligatorio');
            }

            if(typeof codigopostalFacturacion === 'undefined' || codigopostalFacturacion === ''){
                error = true;
                errorMessage.codigopostalFacturacion = i18next.t('El código postal es obligatorio');
            }

            if(typeof lopdFacturacion === 'undefined' || lopdFacturacion.target.checked === false){
                error = true;
                errorMessage.lopdFacturacion = i18next.t('Tienes que aceptar la politica de privacidad');
            }

            if(typeof pago === 'undefined' || pago.target.checked === false){
                error = true;
                errorMessage.pago = i18next.t('Debes elegir una forma de pago');
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={
                    'id' : proposal_id,
                    'razonFiscal' : razonFiscal,
                    'cif' : cif,
                    'direccionFacturacion' : direccionFacturacion,
                    'provinciaFacturacion' : provinciaFacturacion,
                    'ciudadFacturacion' : ciudadFacturacion,
                    'codigopostalFacturacion' : codigopostalFacturacion,
                    'telefonoFacturacion' : telefonoFacturacion,
                    'lopdFacturacion' : lopdFacturacion.target.checked,
                    'pago' : pago.target.value
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                return fetch(types.API_URL + 'proposal/save-fiscal', {
                    method: 'post',
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Content-Language": i18next.language,
                        'token': token 
                    },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    if (response.status === 404 || response.status === 200) {
                        return response.json()
                    } else {
                        if(response.status === 401){  // If unauthorized
                            window.location.href = '/login'; 
                        }else{
                            dispatch({ type: "SAVE_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                        }
                    }
                })
                .then((responseJson) => {
                    if(responseJson !== null){ // If creation success
                        dispatch({ type: "SAVE_FISCAL", payload: { 
                            proposal : responseJson.proposal,
                        } });
                    }else{
                        dispatch({ type: "SAVE_FISCAL_ERROR", payload: { result : 0, message : responseJson.message } });
                    }
                })
                .catch((error) => {
                    dispatch({ type: "SAVE_FISCAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud, error:' + error } });
                });
            }else{
                dispatch({ type: "SAVE_FISCAL_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
            dispatch({ type: "SAVE_FISCAL_ERROR", payload: { message : error } });
        }
    }
}

/** Método que elimina una propuesta a través de la API y lo borra de
    la base de datos.
**/
export function deleteProposal(proposal_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={
                'id' : proposal_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "DELETE_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If success
                    dispatch({ type: "DELETE_PROPOSAL", payload: {} });
                }else{
                    dispatch({ type: "DELETE_PROPOSAL_ERROR", payload: { result : 0, message : responseJson.message } });
                }
            })
            .catch((error) => {
                dispatch({ type: "DELETE_PROPOSAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud, error:' + error } });
            });
        }
        catch(error)
        {
            alert(error);
            dispatch({ type: "DELETE_PROPOSAL_ERROR", payload: { message : error } });
        }
    }
}

/** Método que recupera una propuesta desde la API Rest de Exekutive
    La respuesta esperada es del tipo:
    "proposal" : {
        "_id" : ObjectId
        "created_at" : ISODate
        "updated_at" : ISODate
        "totalPropuesta" : float
        "programs" : [ programs ]
        "position" : string
        "department" : string
        "company" : string
        "country" : string
        "city" : string
        "mobile" : string
        "phone" : string
        "email" : string
        "fullname" : string
    }
**/
export function getProposal(id) {
    return async function(dispatch) {        
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/' + id, {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_PROPOSAL", payload: { proposal : responseJson.proposal } });
                }else{
                    dispatch({ type: "GET_PROPOSAL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_PROPOSAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera una propuesta desde la API Rest de Exekutive con el
    token generado para el cliente en el email.
    La respuesta esperada es del tipo:
    "proposal" : {
        "_id" : ObjectId
        "created_at" : ISODate
        "updated_at" : ISODate
        "totalPropuesta" : float
        "programs" : [ programs ]
        "position" : string
        "department" : string
        "company" : string
        "country" : string
        "city" : string
        "mobile" : string
        "phone" : string
        "email" : string
        "fullname" : string
    }
**/
export function getProposalOfficial(id, token) {
    return async function(dispatch) {        
        try
        {
            return fetch(types.API_URL + 'proposal/' + id, {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_PROPOSAL", payload: { proposal : responseJson.proposal } });
                }else{
                    dispatch({ type: "GET_PROPOSAL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_PROPOSAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera una propuesta desde la API Rest de Exekutive
    La respuesta esperada es del tipo:
    "proposal" : {
        "_id" : ObjectId
        "created_at" : ISODate
        "updated_at" : ISODate
        "totalPropuesta" : float
        "programs" : [ programs ]
        "position" : string
        "department" : string
        "company" : string
        "country" : string
        "city" : string
        "mobile" : string
        "phone" : string
        "email" : string
        "fullname" : string
    }
**/
export function getProposals() {
    return async function(dispatch) {  
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/list', {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_PROPOSALS_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_PROPOSALS", payload: { proposals : responseJson.proposals } });
                }else{
                    dispatch({ type: "GET_PROPOSALS_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_PROPOSALS_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite enviar la copia oficial de la propuesta al cliente,
    esto lo hace a través de la API.
**/
export function sendProposalOfficial(proposal_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : proposal_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/send-official-mail', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "SEND_PROPOSAL_OFFICIAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "SEND_PROPOSAL_OFFICIAL", payload: { proposal : responseJson.proposal } });
                }else{
                    dispatch({ type: "SEND_PROPOSAL_OFFICIAL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "SEND_PROPOSAL_OFFICIAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite cerrar la propuesta y enviar al cliente el email de pago,
    con el documento de coachees. Esto lo hace a través de la API.
**/
export function closeProposal(proposal_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : proposal_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/close', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                console.log(response.status);
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "CLOSE_PROPOSAL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "CLOSE_PROPOSAL", payload: { proposal : responseJson.proposal } });
                }else{
                    dispatch({ type: "CLOSE_PROPOSAL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "CLOSE_PROPOSAL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite cerrar la propuesta y enviar al cliente el email de pago,
    con el documento de coachees. Esto lo hace a través de la API.
**/
export function changeCouponStatus(coupon_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : coupon_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/change-coupon-status', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "CHANGE_COUPON_STATUS_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "CHANGE_COUPON_STATUS", payload: { coupon : responseJson.coupon } });
                }else{
                    dispatch({ type: "CHANGE_COUPON_STATUS_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "CHANGE_COUPON_STATUS_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite actualizar la url de la factura adjunta. 
    Esto lo hace a través de la API.
**/
export function updateInvoice(proposal_id, invoice) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : proposal_id,
                'invoice' : invoice,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/save-invoice', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "UPDATE_INVOICE_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "UPDATE_INVOICE", payload: { invoice : responseJson } });
                }else{
                    dispatch({ type: "UPDATE_INVOICE_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "UPDATE_INVOICE_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite añadir un coachee a una propuesta. 
    Esto lo hace a través de la API.
**/
export function addCoachee(proposal_id, first_name, last_name, email, company, department, position, phone, mobile, city, country, program_key, coachCategory) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            if(typeof first_name === 'undefined' || first_name === ''){
                error = true;
                errorMessage.coachee_first_name = "El nombre es obligatorio";
            }

            if(typeof last_name === 'undefined' || last_name === ''){
                error = true;
                errorMessage.coachee_last_name = "Los apellidos son obligatorios";
            }

            if(typeof email === 'undefined' || email === ''){
                error = true;
                errorMessage.coachee_email = "El email es obligatorio";
            }

            if(error === false){ // No error in data

                var formBody = [];
            
                var details={ 
                    'proposal_id' : proposal_id,
                    'first_name' : first_name,
                    'last_name' : last_name,
                    'email' : email,
                    'company' : company,
                    'department' : department,
                    'position' : position,
                    'phone' : phone,
                    'mobile' : mobile,
                    'city' : city,
                    'country' : country,
                    'program_key' : program_key,
                    'coachCategory' : coachCategory
                };

                for (var property in details) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(details[property]);
                      formBody.push(encodedKey + "=" + encodedValue);
                }

                var status = null;
                const token = localStorage.getItem('token');

                return fetch(types.API_URL + 'proposal/add-coachee', {
                    method: 'post',
                    headers: { 
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Content-Language": i18next.language,
                        "token": token  
                    },
                    body: formBody.join("&"),
                })
                .then(function(response) {
                    status = response.status; // Get HTTP status code
                    return response.json();
                })
                .then((responseJson, response) => {
                    if(status === 200){ // If success
                        
                        dispatch({ 
                            type: "ADD_COACHEE", 
                            payload: { 
                                proposal : responseJson.proposal,
                                successMessage : 'Datos guardados correctamente'
                            }
                        });

                    }else{
                        errorMessage.message = responseJson;
                        dispatch({ type: "ADD_COACHEE_ERROR", payload: { message : errorMessage } });
                    }
                })
                .catch((error) => {
                    errorMessage.message = 'catch: ' + error;
                    dispatch({ type: "ADD_COACHEE_ERROR", payload: { message : errorMessage } });
                });

            }else{
                dispatch({ type: "ADD_COACHEE_ERROR", payload: { message : errorMessage } });
            }
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite eliminar un coachee de una propuesta. 
    Esto lo hace a través de la API.
**/
export function deleteCoachee(proposal_id, coachee_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'proposal_id' : proposal_id,
                'coachee_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/delete-coachee', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "DELETE_COACHEE", 
                        payload: { 
                            proposal : responseJson.proposal
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "DELETE_COACHEE_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "DELETE_COACHEE_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite enviar el email de valoración al Coachee. 
    Esto lo hace a través de la API.
**/
export function coacheeRateEmail(proposal_id, coachee_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'proposal_id' : proposal_id,
                'coachee_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/coachee-rate-email', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "COACHEE_RATE_EMAIL", 
                        payload: { 
                            proposal : responseJson.proposal,
                            successMessage : 'Datos guardados correctamente'
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "COACHEE_RATE_EMAIL_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "COACHEE_RATE_EMAIL_ERROR", payload: { message : errorMessage } });
            });


        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite enviar el email de valoración al Coachee. 
    Esto lo hace a través de la API.
**/
export function proposalToRate(proposal_id, coachee_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'proposal_id' : proposal_id,
                'coachee_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/proposal-to-rate', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "PROPOSAL_TO_RATE", 
                        payload: { 
                            coach : responseJson.coach,
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "PROPOSAL_TO_RATE_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "PROPOSAL_TO_RATE_ERROR", payload: { message : errorMessage } });
            });


        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite enviar el email de valoración al Coachee. 
    Esto lo hace a través de la API.
**/
export function saveProposalRate(proposal_id, coachee_id, value) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'proposal_id' : proposal_id,
                'coachee_id' : coachee_id,
                'value'    : value
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/save-proposal-rate', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "SAVE_PROPOSAL_RATE", 
                        payload: { 
                            coach : responseJson.coach,
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "SAVE_PROPOSAL_RATE_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "SAVE_PROPOSAL_RATE_ERROR", payload: { message : errorMessage } });
            });


        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite eliminar una empresa. 
    Esto lo hace a través de la API.
**/
export function deleteCompany(company_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                '_id' : company_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "DELETE_COMPANY", 
                        payload: {}
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "DELETE_COMPANY_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "DELETE_COMPANY_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite eliminar un Coachee de la bbdd. 
    Esto lo hace a través de la API.
**/
export function deleteUserCoachee(coachee_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                '_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "DELETE_USER_COACHEE", 
                        payload: {}
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "DELETE_USER_COACHEE_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "DELETE_USER_COACHEE_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite validar un cheque descuento introducido por el cliente en la 
    herramienta MMP. Esto lo hace a través de la API.
**/
export function validateCoupon(coupon_code) {
    return async function(dispatch) {        
        try
        {
            console.log(coupon_code);
            let error = false;
            let errorMessage = {};

            var formBody = [];
            
            var details={
                'code' : coupon_code
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            return fetch(types.API_URL + 'proposal/validate-coupon', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        errorMessage.couponCode = i18next.t('Este cheque descuento no es válido');

                        dispatch({ type: "VALIDATE_COUPON_ERROR", payload: { result : 0, message : i18next.t('Este cheque descuento no es válido') } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "VALIDATE_COUPON", payload: { coupon : responseJson.coupon } });
                }else{
                    dispatch({ type: "VALIDATE_COUPON_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                if(typeof errorMessage.couponCode != 'undefined'){
                    dispatch({ type: "DO_CHECKOUT_ERROR", payload: { message : errorMessage } });
                }else{
                    dispatch({ type: "VALIDATE_COUPON_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
                }
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera el listado de facturas de un cliente desde la API Rest de Exekutive
**/
export function getClientInvoices() {
    return async function(dispatch) {  
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'proposal/get-client-invoices', {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_CLIENT_INVOICES_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_CLIENT_INVOICES", payload: { invoices : responseJson.invoices } });
                }else{
                    dispatch({ type: "GET_CLIENT_INVOICES_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_CLIENT_INVOICES_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera el coach desde la API Rest de Exekutive
**/
export function getCoach(id) {
    return async function(dispatch) {  
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/' + id, {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_COACH_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_COACH", payload: { coach : responseJson.user } });
                }else{
                    dispatch({ type: "GET_COACH_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_COACH_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que recupera el listado de herramientas dadas de alta desde la API Rest de Exekutive
**/
export function getCoachTools() {
    return async function(dispatch) {  
        try
        {
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'tool/list', {
                method: 'get',
                headers: new Headers({
                    'token': token
                })
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "GET_COACH_TOOLS_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "GET_COACH_TOOLS", payload: { tools : responseJson.tools } });
                }else{
                    dispatch({ type: "GET_COACH_TOOLS_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "GET_COACH_TOOLS_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud', error: error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite actualizar la url de una herramienta. 
    Esto lo hace a través de la API.
**/
export function createTool(tool, name, type) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'tool' : tool,
                'name' : name,
                'type' : type
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'tool/save', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "CREATE_TOOL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "CREATE_TOOL", payload: { tool : responseJson.tool } });
                }else{
                    dispatch({ type: "CREATE_TOOL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "CREATE_TOOL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite eliminar una herramienta y el documento. 
    Esto lo hace a través de la API.
**/
export function deleteTool(tool_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'tool_id' : tool_id,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'tool/delete', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "DELETE_TOOL", 
                        payload: { 
                            proposal : responseJson.proposal
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "DELETE_TOOL_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "DELETE_TOOL_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite asignar un Coach a un Coachee y propuesta concretos
**/
export function selectCoach(proposal_id, coach_id, coachee_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'proposal_id' : proposal_id,
                'coach_id' : coach_id,
                'coachee_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/select-coach', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "SELECT_COACH_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "SELECT_COACH", payload: { user : responseJson.user } });
                }else{
                    dispatch({ type: "SELECT_COACH_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "SELECT_COACH_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite asignar una herramienta a un Coach
**/
export function addCoachTool(tool_id, coach_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'tool_id' : tool_id,
                'coach_id' : coach_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/add-coach-tool', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "ADD_COACH_TOOL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "ADD_COACH_TOOL", payload: { user : responseJson.user } });
                }else{
                    dispatch({ type: "ADD_COACH_TOOL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "ADD_COACH_TOOL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite borrar una herramienta a un Coach
**/
export function deleteCoachTool(tool_id, coach_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'tool_id' : tool_id,
                'coach_id' : coach_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/delete-coach-tool', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "DELETE_COACH_TOOL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "DELETE_COACH_TOOL", payload: { user : responseJson.user } });
                }else{
                    dispatch({ type: "DELETE_COACH_TOOL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "DELETE_COACH_TOOL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite obtener los coachees de un coach
    Esto lo hace a través de la API.
**/
export function getCoachCoachees(coach_id) {
    return async function(dispatch) {
        try
        {
            let error = false;
            let errorMessage = {};

            var formBody = [];
        
            var details={ 
                'coach_id' : coach_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            var status = null;
            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/get-coach-coachees', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "token": token  
                },
                body: formBody.join("&"),
            })
            .then(function(response) {
                status = response.status; // Get HTTP status code
                return response.json();
            })
            .then((responseJson, response) => {
                if(status === 200){ // If success
                    
                    dispatch({ 
                        type: "GET_COACH_COACHEES", 
                        payload: { 
                            coachees : responseJson.coachees
                        }
                    });

                }else{
                    errorMessage.message = responseJson;
                    dispatch({ type: "GET_COACH_COACHEES_ERROR", payload: { message : errorMessage } });
                }
            })
            .catch((error) => {
                errorMessage.message = 'catch: ' + error;
                dispatch({ type: "GET_COACH_COACHEES_ERROR", payload: { message : errorMessage } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite asignar una herramienta a un Coachee
**/
export function addCoacheeTool(tool_id, coach_id, coachee_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'tool_id' : tool_id,
                'coach_id' : coach_id,
                'coachee_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/add-coachee-tool', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "ADD_COACHEE_TOOL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "ADD_COACHEE_TOOL", payload: { user : responseJson.user } });
                }else{
                    dispatch({ type: "ADD_COACHEE_TOOL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "ADD_COACHEE_TOOL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite retirar una herramienta a un Coachee
**/
export function deleteCoacheeTool(tool_id, coach_id, coachee_id) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'tool_id' : tool_id,
                'coach_id' : coach_id,
                'coachee_id' : coachee_id
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/delete-coachee-tool', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "DELETE_COACHEE_TOOL_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "DELETE_COACHEE_TOOL", payload: { user : responseJson.user } });
                }else{
                    dispatch({ type: "DELETE_COACHEE_TOOL_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "DELETE_COACHEE_TOOL_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite actualizar la url del perfil del coachee. 
    Esto lo hace a través de la API.
**/
export function updateCoacheeProfile(coachee_id, profile) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : coachee_id,
                'profile' : profile,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/save-coachee-profile', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "UPDATE_COACHEE_PROFILE_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "UPDATE_COACHEE_PROFILE", payload: { profile : responseJson } });
                }else{
                    dispatch({ type: "UPDATE_COACHEE_PROFILE_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "UPDATE_COACHEE_PROFILE_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite actualizar la url del perfil del coachee. 
    Esto lo hace a través de la API.
**/
export function updateCoacheeDashboard(coachee_id, dashboard) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : coachee_id,
                'dashboard' : dashboard,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/save-coachee-dashboard', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "UPDATE_COACHEE_DASHBOARD_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "UPDATE_COACHEE_DASHBOARD", payload: { profile : responseJson } });
                }else{
                    dispatch({ type: "UPDATE_COACHEE_DASHBOARD_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "UPDATE_COACHEE_DASHBOARD_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}

/** Método que permite actualizar la url del documento de bienvenida del coachee. 
    Esto lo hace a través de la API.
**/
export function updateCoacheeWelcome(coachee_id, welcome) {
    return async function(dispatch) {        
        try
        {
            var formBody = [];
            
            var details={
                'id' : coachee_id,
                'welcome' : welcome,
            };

            for (var property in details) {
                  var encodedKey = encodeURIComponent(property);
                  var encodedValue = encodeURIComponent(details[property]);
                  formBody.push(encodedKey + "=" + encodedValue);
            }

            const token = localStorage.getItem('token');

            return fetch(types.API_URL + 'user/save-coachee-welcome', {
                method: 'post',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Content-Language": i18next.language,
                    'token': token 
                },
                body: formBody.join("&")
            })
            .then(function(response) {
                if (response.status === 404 || response.status === 200) {
                    return response.json()
                } else {
                    if(response.status === 401){  // If unauthorized
                        window.location.href = '/login'; 
                    }else{
                        dispatch({ type: "UPDATE_COACHEE_WELCOME_ERROR", payload: { result : 0, message : 'Error en la solicitud' } });
                    }
                }
            })
            .then((responseJson) => {
                if(responseJson !== null){ // If creation success
                    dispatch({ type: "UPDATE_COACHEE_WELCOME", payload: { profile : responseJson } });
                }else{
                    dispatch({ type: "UPDATE_COACHEE_WELCOME_ERROR", payload: { result : 0, message : i18next.t('No hay resultados') } });
                }
            })
            .catch((error) => {
                dispatch({ type: "UPDATE_COACHEE_WELCOME_ERROR", payload: { result : 0, message : 'Hubo un error procesando tu solicitud. Error: ' + error } });
            });
        }
        catch(error)
        {
            alert(error);
        }
    }
}


