
import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { types } from '../constants/constants';

import Header from '../components/header';
import InputText from '../components/input';
import TestimonialsMMP from '../components/testimonials-mmp';

import {inputFieldChanged,
			doCheckout 
		} from '../actions/app.js';

import { Checkbox } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

class Checkout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        	showForm: true,
            showCheckoutOk: false,
            showCheckoutKo: false,
            isLoading: false,
        };
    }

    componentDidMount(){
    	if(typeof this.props.programs === 'undefined' || this.props.programs.length === 0){
    		this.props.history.push('/MMP/')
    	}
    }

	_saveData(){
		this.setState({
			'isLoading' : true
		});

		const { fullname, email, phone, mobile, city, country, company, department, position, lopd, programs, totalPropuesta, couponValidated } = this.props;
    	
    	Promise.resolve(this.props.doCheckout(fullname, email, phone, mobile, city, country, company, department, position, lopd, programs, totalPropuesta, couponValidated))
	    .then((response)=> {
	        if(this.props.dataError === false){
	            this.setState({
		            showForm: false,
		            showCheckoutOk: true,
		            showCheckoutKo: false,
		        });
	        }
	        this.setState({
				'isLoading' : false
			});
	    });
	}

	_renderLoader() {
    	return(
    		<div className="col-12 text-center">
			  	<Loader
			     	type="ThreeDots"
			     	color="#FF866B"
			     	height={60}
			     	width={60}
			  	/>
			</div>
		);
    }

	_renderFormCheckout(){
		const { t, i18n } = this.props;

        const current_lang = i18n.language

        if(current_lang.indexOf('en-GB') != -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('en-US') != -1){ i18n.changeLanguage('en') }
        if(current_lang.indexOf('es-ES') != -1){ i18n.changeLanguage('es') }

       	const privacyURL = i18n.language == 'en' ? types.BLOG_URL + 'en/privacy-policy' : types.BLOG_URL + 'politica-privacidad';

		return (
       		<React.Fragment>
			    <TestimonialsMMP />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<div className={'row mt-5 mb-5'}>
			      				<div className={'col-12 text-left align-self-center'}>
			      					<h1 className={'orange fs-12'}>
			      						{t('MI MEJOR PROPUESTA')}
			      						<span className={'gray fs-08 pl-md-3 mt-2 d-block d-md-inline align-self-center'}>Checkout</span>
			      					</h1>
			      				</div>
			      			</div>

			      			<div className={'row pt-3 mb-5'}>
			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>

			      						{this.state.isLoading && this._renderLoader()}

			      						{this.state.isLoading === false &&
			      							<React.Fragment>
					      						<p className={'bold fs-12'}>{t('Tus datos de contacto para remitirte la propuesta oficial')}</p>

					      						<div className={'row'}>
					      							<div className={'col-12 mb-4'}>
					      								<InputText 
						        							id={'fullname'}
						        							name={'fullname'}
						        							placeholder={t('Nombre completo')}
						        							classes={'w-100'}
						        							label={t('Nombre completo')}
						        							value={this.props.fullname}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.fullname} />
					      							</div>
					      						</div>

					      						<div className={'row'}>	
					      							<div className={'col-12 mb-4'}>
					      								<InputText 
						        							id={'email'}
						        							name={'email'}
						        							placeholder={t('Mail corporativo')}
						        							classes={'w-100'}
						        							label={t('Mail corporativo')}
						        							value={this.props.email}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.email} />
					      							</div>
					      						</div>

					      						<div className={'row'}>
					      							<div className={'col-12 col-lg-6 mb-4'}>
					      								<InputText 
						        							id={'phone'}
						        							name={'phone'}
						        							placeholder={t('Teléfono')}
						        							classes={'w-100'}
						        							label={t('Teléfono')}
						        							value={this.props.phone}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.phone} />
					      							</div>

					      							<div className={'col-12 col-lg-6 mb-4'}>
					      								<InputText 
						        							id={'mobile'}
						        							name={'mobile'}
						        							placeholder={t('Móvil')}
						        							classes={'w-100'}
						        							label={t('Móvil')}
						        							value={this.props.mobile}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.mobile} />
					      							</div>
					      						</div>
												
												<div className={'row'}>
					      							<div className={'col-12 col-lg-6 mb-4'}>
					      								<InputText 
						        							id={'city'}
						        							name={'city'}
						        							placeholder={t('Ciudad')}
						        							classes={'w-100'}
						        							label={t('Ciudad')}
						        							value={this.props.city}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.city} />
					      							</div>

					      							<div className={'col-12 col-lg-6 mb-4'}>
					      								<InputText 
						        							id={'country'}
						        							name={'country'}
						        							placeholder={t('País')}
						        							classes={'w-100'}
						        							label={t('País')}
						        							value={this.props.country}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.country} />
					      							</div>
					      						</div>

					      						<div className={'row'}>
					      							<div className={'col-12 col-lg-4 mb-4'}>
					      								<InputText 
						        							id={'company'}
						        							name={'company'}
						        							placeholder={t('Empresa')}
						        							classes={'w-100'}
						        							label={t('Empresa')}
						        							value={this.props.company}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.company} />
					      							</div>

					      							<div className={'col-12 col-lg-4 mb-4'}>
					      								<InputText 
						        							id={'department'}
						        							name={'department'}
						        							placeholder={t('Departamento')}
						        							classes={'w-100'}
						        							label={t('Departamento')}
						        							value={this.props.department}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.department} />
					      							</div>

					      							<div className={'col-12 col-lg-4 mb-4'}>
					      								<InputText 
						        							id={'position'}
						        							name={'position'}
						        							placeholder={t('Posición')}
						        							classes={'w-100'}
						        							label={t('Posición')}
						        							value={this.props.position}
						        							onChange={this.props.inputFieldChanged.bind(this)}
						        							error={this.props.dataErrorMessage.position} />
					      							</div>
					      						</div>

					      						<div className={'row mt-2'}>
					      							<div className={'col-12'}>
					      								<Checkbox 
					      									id={'lopd'}
					      									name={'lopd'}
					      									checked={ typeof this.props.lopd !== 'undefined' ? this.props.lopd.target.checked : false }
					      									onChange={this.props.inputFieldChanged.bind(this, 'lopd')}
					      									>{t('He leído y acepto las')}{' '} <a href={ privacyURL } target='_blank'>{t('condiciones de privacidad')}</a></Checkbox>
					      							</div>
												</div>

												{ typeof this.props.dataErrorMessage != 'undefined' && this.props.dataErrorMessage.lopd !== '' ?

							                    	<div className='row mt-4'>
		      											<div className="col-12">
							                    			<p className={'red fs-09 mt-1'}>{ this.props.dataErrorMessage.lopd }</p>
							                    		</div>
		      										</div>

				      							: null }

				      						</React.Fragment>
			      						}

			      					</div>
			      				</div>
			      			</div>

			      			<div className={'row mb-4'}>
			      				<div className={'col-12 text-right'}>
			      					<button className={'btn-submit mt-4'} onClick={this._saveData.bind(this)}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Finalizar')}</span>
			      					</button>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
	    );
	}

	_renderCheckoutOk(){
		const { t, i18n } = this.props;

		let current_lang = i18n.language == 'en' ? 'en' : '';

		return(
			<React.Fragment>
			    <TestimonialsMMP />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<div className={'row mt-5 mb-5'}>
			      				<div className={'col-12 text-left align-self-center'}>
			      					<h1 className={'orange fs-12'}>
			      						{t('MI MEJOR PROPUESTA')}
			      						<span className={'gray fs-08 pl-md-3 mt-2 d-block d-md-inline align-self-center'}>Checkout</span>
			      					</h1>
			      				</div>
			      			</div>

			      			<div className={'row pt-3 mb-5'}>
			      				<div className={'col-12 mb-2'}>
									<p className={'gray bold'}>
										<CheckCircleOutlined className={'orange fs-25 mr-4'} />
										{t('¡Tu propuesta se ha enviado correctamente!')}
									</p>
			      				</div>
			      				
			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>
			      						<p className={'bold fs-13'}>
			      							{t('¡GRACIAS!')}
			      							<span className={'fs-08 ml-2'}>{t('En menos de 24 horas recibirás')}:</span>
			      						</p>

			      						<div className={'row'}>
			      							<div className={'col-12'}>
			      								<ul className={'item-list gray'}>
			      									<li>{t('Copia oficial de MiMejorPropuesta')}</li>
			      									<li>{t('Condiciones de contratación')}</li>
			      									<li>{t('Programa de Fidelización de EXEKUTIVE Coaching')}</li>
			      								</ul>
			      							</div>
			      						</div>
			      					</div>
			      				</div>
			      			</div>

			      			<div className={'row mb-4'}>
			      				<div className={'col-7 offset-5 col-lg-5 offset-lg-7 text-right'}>
			      					<a href={ types.BLOG_URL + current_lang } className={'btn-submit w-100 mt-4'}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Volver a EXEKUTIVE Coaching')}</span>
			      					</a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
		);
	}

	_renderCheckoutKo(){
		const { t } = this.props;
		return(
			<React.Fragment>
			    <TestimonialsMMP />

	      		<div className={'container'}>
	      			<div className={'row'}>
		        		<div className={'col-12 col-md-8 offset-md-4 align-self-center pt-5'}>
			      			<Header />

			      			<div className={'row mt-5 mb-5'}>
			      				<div className={'col-12 text-left align-self-center'}>
			      					<h1 className={'orange fs-12'}>
			      						{t('MI MEJOR PROPUESTA')}
			      						<span className={'gray fs-08 pl-md-3 mt-2 d-block d-md-inline align-self-center'}>Checkout</span>
			      					</h1>
			      				</div>
			      			</div>

			      			<div className={'row pt-3 mb-5'}>
			      				<div className={'col-12'}>

			      				</div>
			      				
			      				<div className={'col-12'}>
			      					<div className={'box-with-shadow'}>
			      						
			      					</div>
			      				</div>
			      			</div>

			      			<div className={'row'}>
			      				<div className={'col-12 text-right'}>
			      					<a href="home" className={'btn-submit mt-4'}>
			      						<span className={'circle'} aria-hidden="true">
			      							<span className={'icon arrow'}></span>
			      						</span>
			      						<span className={'button-text'}>{t('Finalizar')}</span></a>
			      				</div>
			      			</div>
		        		</div>
		        	</div>
		        </div>
	      	</React.Fragment>
		);
	}

    render() {
        if(this.state.showForm)
        	return (this._renderFormCheckout());

		if(this.state.showCheckoutOk)
        	return (this._renderCheckoutOk());

        if(this.state.showCheckoutKo)
        	return (this._renderCheckoutKo());
    }

}

function mapStateToProps(state) {
    const storeApp = state.app;

    return {
    	fullname : storeApp.fullname,
    	email : storeApp.email,
    	phone : storeApp.phone,
    	mobile : storeApp.mobile,
    	city : storeApp.city,
    	country : storeApp.country,
    	company : storeApp.company,
    	department : storeApp.department,
    	position : storeApp.position,
    	lopd : storeApp.lopd,
        programs : storeApp.programs,
        totalPropuesta : storeApp.totalPropuesta,
        couponValidated : storeApp.couponValidated,
        dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        doCheckout: (fullname, email, phone, mobile, city, country, company, department, position, lopd, programs, totalPropuesta, couponValidated) => dispatch(doCheckout(fullname, email, phone, mobile, city, country, company, department, position, lopd, programs, totalPropuesta, couponValidated)),
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Checkout));