import React from 'react';
import { useDrop } from 'react-dnd'
import { types } from '../constants/constants';

import { Tool } from "./tool"

export const CoachTools = ({ coach, deleteTool, t }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: types.TOOL,
        drop: () => ({ name: coach.first_name, id: coach._id }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    
    const isActive = canDrop && isOver

    coach.tools.sort(function(a,b)
    {
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;
        return 0;
    });

    return (
        <div ref={drop} className={'coach-tools bg-box py-3 px-4'}>
            { coach.tools.length ?
                coach.tools.map((tool, key) => (

                    <div onClick={ deleteTool.bind(this, tool._id, coach._id) }>
                    <Tool
                        key={ key }
                        disabled={true}
                        deleteable={true}
                        tool={ tool } />
                        </div>

                ))
            :
                <p className={'m-0'}>{t('No tienen ninguna herramienta')}</p>
            }
        </div>
    );
}
